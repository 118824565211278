import { useEffect } from 'react';

const listenerCallbacks = new WeakMap();

let observerMap = new WeakMap();

const handleInterseptions = root => entries => {
  entries.forEach(entry => {
    if (listenerCallbacks.has(entry.target)) {
      if (entry.isIntersecting || entry.intersectionRatio > 0) {
        let cb = listenerCallbacks.get(entry.target);
        const observer = observerMap.get(root);

        observer.unobserve(entry.target);
        listenerCallbacks.delete(entry.target);
        cb();
      }
    }
  });
};

const getIntersectionObserver = root => {
  if (root && !observerMap.has(root)) {
    const observer = new IntersectionObserver(handleInterseptions(root), {
      threshold: 0.2,
      root,
    });
    observerMap.set(root, observer);
  }

  return observerMap.get(root);
};

export const useIntersection = (element, callback, rootRef) => {
  useEffect(() => {
    let target = element.current;
    let root = rootRef.current.rootElement;
    let observer = getIntersectionObserver(root);
    listenerCallbacks.set(target, callback);
    observer.observe(target);

    return () => {
      listenerCallbacks.delete(target);
      observer.unobserve(target);
      observerMap.delete(root);
    };
  }, [rootRef]);
};
