import React, { useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import FilterActions from 'actions/filter.actions';
import statusIcon from 'assets/svgs/statusIcon.svg';
import FilterWrapper from 'components/FilterWrapper';
import { FilterStatus } from 'constants/filter.constants';

const useStyles = makeStyles(() => ({
  body: {
    display: 'grid',
    gridTemplateColumns: '108px 108px',
    gridGap: '8px',
  },
  formControl: {
    width: '108px',
    minHeight: '33px',
    boxSizing: 'border-box',
    borderRadius: 100,
    cursor: 'pointer',
    margin: '0 !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: '700',
    color: '#121223',
    backgroundColor: '#FFF',
    textAlign: 'center',
    opacity: '0.6',

    '&:hover': {
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.4)',
    },
  },
  selected: {
    backgroundColor: '#7F56D9',
    color: '#FFF',
    fontWeight: 700,
    border: 0,
    opacity: '1',
  },
}));

const ExploreStatus = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    return dispatch(FilterActions.resetStatusFilter());
  }, []);
  const classes = useStyles();

  const { status } = useSelector(state => state.Filter);

  const handleStatusChange = field => {
    dispatch(FilterActions.updateStatusFilter(field));
  };

  const isDashboard = location.pathname.includes('dashboard');

  return (
    <FilterWrapper
      title="Status"
      icon={statusIcon}
      classes={{ body: classes.body }}
    >
      {!isDashboard ? (
        <>
          <div
            className={cx(
              classes.formControl,
              status === FilterStatus.BUY_NOW ? classes.selected : null
            )}
            onClick={() => handleStatusChange(FilterStatus.BUY_NOW)}
          >
            Buy Now
          </div>
          <div
            className={cx(
              classes.formControl,
              status === FilterStatus.HAS_BIDS ? classes.selected : null
            )}
            onClick={() => handleStatusChange(FilterStatus.HAS_BIDS)}
          >
            Has Bids
          </div>
          <div
            className={cx(
              classes.formControl,
              status === FilterStatus.HAS_OFFERS ? classes.selected : null
            )}
            onClick={() => handleStatusChange(FilterStatus.HAS_OFFERS)}
          >
            Has Offers
          </div>
          <div
            className={cx(
              classes.formControl,
              status === FilterStatus.ON_AUCTION ? classes.selected : null
            )}
            onClick={() => handleStatusChange(FilterStatus.ON_AUCTION)}
          >
            On Auction
          </div>
        </>
      ) : (
        <>
          <div
            className={cx(
              classes.formControl,
              status === FilterStatus.PENDING_APPROVAL ? classes.selected : null
            )}
            onClick={() => handleStatusChange(FilterStatus.PENDING_APPROVAL)}
          >
            Pending approval
          </div>
          <div
            className={cx(
              classes.formControl,
              status === FilterStatus.MINTED ? classes.selected : null
            )}
            onClick={() => handleStatusChange(FilterStatus.MINTED)}
          >
            Minted
          </div>
          <div
            className={cx(
              classes.formControl,
              status === FilterStatus.APPROVED_BY_AGENT_AND_ADMIN
                ? classes.selected
                : null
            )}
            onClick={() =>
              handleStatusChange(FilterStatus.APPROVED_BY_AGENT_AND_ADMIN)
            }
          >
            Approved by Agent and Admin
          </div>
          <div
            className={cx(
              classes.formControl,
              status === FilterStatus.APPROVED_BY_AGENT_AND_DENIED_BY_ADMIN
                ? classes.selected
                : null
            )}
            onClick={() =>
              handleStatusChange(
                FilterStatus.APPROVED_BY_AGENT_AND_DENIED_BY_ADMIN
              )
            }
          >
            Approved by Agent and denied by Admin
          </div>
          <div
            className={cx(
              classes.formControl,
              status === FilterStatus.APPROVED_BY_AGENT
                ? classes.selected
                : null
            )}
            onClick={() => handleStatusChange(FilterStatus.APPROVED_BY_AGENT)}
          >
            Approved by Agent
          </div>
          <div
            className={cx(
              classes.formControl,
              status === FilterStatus.DENIED_BY_AGENT ? classes.selected : null
            )}
            onClick={() => handleStatusChange(FilterStatus.DENIED_BY_AGENT)}
          >
            Denied by Agent
          </div>
          <div
            className={cx(
              classes.formControl,
              status === FilterStatus.PRE_MINT ? classes.selected : null
            )}
            onClick={() => handleStatusChange(FilterStatus.PRE_MINT)}
          >
            Premint
          </div>
          <div
            className={cx(
              classes.formControl,
              status === FilterStatus.MINING_PENDING ? classes.selected : null
            )}
            onClick={() => handleStatusChange(FilterStatus.MINING_PENDING)}
          >
            Pending Mint
          </div>
          <div
            className={cx(
              classes.formControl,
              status === FilterStatus.PAYING_FEE_PENDING
                ? classes.selected
                : null
            )}
            onClick={() => handleStatusChange(FilterStatus.PAYING_FEE_PENDING)}
          >
            Paying Fee Pending
          </div>
          <div
            className={cx(
              classes.formControl,
              status === FilterStatus.APPROVE_MINTING_PENDING
                ? classes.selected
                : null
            )}
            onClick={() =>
              handleStatusChange(FilterStatus.APPROVE_MINTING_PENDING)
            }
          >
            Approve Minting Pending
          </div>
          <div
            className={cx(
              classes.formControl,
              status === FilterStatus.BURNED ? classes.selected : null
            )}
            onClick={() => handleStatusChange(FilterStatus.BURNED)}
          >
            Burned
          </div>
          <div
            className={cx(
              classes.formControl,
              status === FilterStatus.ERROR_MINTING_TX ? classes.selected : null
            )}
            onClick={() => handleStatusChange(FilterStatus.ERROR_MINTING_TX)}
          >
            Processing error
          </div>
        </>
      )}
    </FilterWrapper>
  );
};

export default ExploreStatus;
