import React from 'react';
import Modal from '../Modal';
import styles from './styles.module.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';
import cx from 'classnames';
import showToast from '../../utils/toast';

const ReferralLinkModal = ({ visible, onClose, code }) => {
  const getReferralLink = () => {
    return `${window.location.origin + window.location.pathname}#${code}`;
  };

  const onCopy = () => {
    showToast('success', 'Copied referral code to clipboard');
    onClose();
  };

  return (
    <Modal
      visible={visible}
      title="Share Link"
      onClose={onClose}
      submitDisabled={true}
      showTitle={false}
    >
      <div className={styles.title}>Share Link</div>
      <div className={styles.subtitle}>
        We appreciate our community!🦄🚀
        <br />
        When someone buys an NFT that you shared, you get added to the
        LiquidEarth VIP Community that receives early access to NFTs and
        exclusive promotions. Click&nbsp;
        <a
          href="https://docs.liquidearth.io/"
          className={cx(styles.linkUnderline, styles.docsLink)}
          rel="noopener noreferrer"
          target="_blank"
        >
          here
        </a>
        &nbsp;to learn more.
      </div>
      <div className={styles.formGroup}>
        <div className={styles.formInputCont}>
          <div className={styles.referralLink}>{getReferralLink()}</div>
        </div>
      </div>
      <div className={styles.footer}>
        <CopyToClipboard text={getReferralLink()} onCopy={onCopy}>
          <div className={styles.submitButton}>Copy to clipboard</div>
        </CopyToClipboard>
      </div>
    </Modal>
  );
};

export default ReferralLinkModal;
