import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { ClipLoader } from 'react-spinners';
import Skeleton from 'react-loading-skeleton';
// import { ChainId } from '@sushiswap/sdk';
import { withStyles } from '@material-ui/core/styles';
import { MenuItem, Select } from '@material-ui/core';
// import InfoIcon from '@material-ui/icons/Info';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import * as yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import HeaderActions from 'actions/header.actions';
import Header from 'components/header';
import Footer from 'components/Footer';
import BootstrapTooltip from 'components/BootstrapTooltip';
import PriceInput from 'components/PriceInput';
import showToast from 'utils/toast';
import { useApi } from 'api';
import InputPdfFile from 'components/InputPdfFile';
import PlacesAutocomplete, { ZERO_POINT } from 'components/PlacesAutocomplete';
import InputPhotos, { MAX_FILES } from 'components/InputPhotos';
import styles from './styles.module.scss';
import DocumentList from 'components/DocumentList';
import { LinearProgress, Box } from '@material-ui/core';
import { USER_ROLES } from '../../constants/userRoles.constants';
import FilterActions from '../../actions/filter.actions';
import ModalActions from '../../actions/modal.actions';
import { useWeb3React } from '@web3-react/core';
import { usePreMint } from 'hooks/usePreMint';
import { ArrowIcon } from '../icons/index';
import WalletUtils from '../../utils/wallet';
import IconExclamation from '../../assets/icons/iconExclamation';

const HightProgress = withStyles({
  root: {
    height: 10,
    borderRadius: 5,
    margin: 5,
  },
  bar: {
    borderRadius: 5,
  },
})(LinearProgress);

const stringField = message => yup.string().label(message);
const numberField = message =>
  yup
    .number()
    .integer()
    .min(0)
    .nullable(true)
    .transform(value => (isNaN(value) ? undefined : value))
    .label(message);
const requiredNumberField = message =>
  yup
    .number()
    .integer()
    .min(0)
    .max(99999999999999)
    .label(message);
const decimalField = message =>
  yup
    .number()
    .min(0)
    .label(message);
const geolField = message =>
  yup
    .object({
      lat: yup
        .number()
        .required()
        .label('Latitude'),
      lng: yup
        .number()
        .required()
        .label('Longitude'),
    })
    .label(message);
const linkField = message =>
  yup
    .string()
    .matches(
      // eslint-disable-next-line
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm,
      { excludeEmptyString: true }
    )
    .label(message);
const boolField = message => yup.boolean().label(message);
const assetIdField = message =>
  yup
    .array()
    .of(
      yup.object({
        url: yup
          .string()
          .required()
          .label('Link'),
        assetId: yup
          .number()
          .required()
          .min(0)
          .label('Incorrect assetId'),
        fullUrl: yup
          .string()
          .required()
          .label('FullLink'),
      })
    )
    .label(message);

const formObject = yup.object({
  frontCover: assetIdField('Front cover').length(1),
  photos: assetIdField('Photos').max(MAX_FILES),
  videoLink: linkField('Link to video')
    .min(1)
    .required(),
  collectionId: stringField('Collection ID')
    .min(2)
    .required(),
  name: stringField('Name')
    .min(2)
    .required(),
  geoPoint: geolField('Google map position'),
  description: stringField('Description')
    .min(2)
    .required(),
  annualPropertyTaxes: requiredNumberField('Annual property taxes').required(),
  monthlyHoa: requiredNumberField('Monthly HOA').required(),
  royalty: decimalField('Royalty').required(),
  monthlyPropertyInsurance: requiredNumberField(
    'Monthly property insurance'
  ).required(),
  parcelNumber: numberField('Parcel Number'),
  prepaidTitleInsurance: boolField('Prepaid title insurance').required(),
  specialAssessment: boolField('Special assessment').required(),
  inspectionReports: assetIdField('Inspection reports').min(1),
  propertyReport: assetIdField('Property disclosure report').min(1),
  deedDocuments: assetIdField('Deed and other documents').min(1),
  escrowAgreement: assetIdField('Trust or escrow agreement').min(1),
  attachments: assetIdField('Title Search Report and Additional Documents'),
  escrowFee: numberField('Escrow Fee'),
  escrowPeriod: numberField('Period'),
  remainBalance: decimalField('Remain Balance')
    .nullable(true)
    .transform(value => (isNaN(value) ? undefined : value)),
});

const GraySelect = withStyles({
  select: {
    '&:focus': {
      backgroundColor: 'inherit',
      padding: '0px',
    },
  },
  icon: {
    marginRight: '10px',
  },
})(Select);

const switchStates = [
  { value: false, label: 'No' },
  { value: true, label: 'Yes' },
];

const PaintBoard = () => {
  const dispatch = useDispatch();

  const {
    getCategories,
    registerNft,
    getUserIsKycVerified,
    deleteFile,
    fetchCreatedNft,
  } = useApi();
  const { account, chainId } = useWeb3React();
  const { preMint } = usePreMint();
  const history = useHistory();
  const { createdNftId } = useParams();
  const [selected, setSelected] = useState('0');
  const [collections, setCollections] = useState([]);

  const [videoLink, setVideoLink] = useState('');
  const [name, setName] = useState('');
  const [geoPoint, setGeoPoint] = useState();
  const [description, setDescription] = useState('');
  const [royalty, setRoyalty] = useState('');
  const [annualPropertyTaxes, setAnnualPropertyTaxes] = useState();
  const [monthlyHoa, setMonthlyHoa] = useState();
  const [monthlyPropertyInsurance, setMonthlyPropertyInsurance] = useState();
  const [parcelNumber, setParcelNumber] = useState();
  const [escrowFee, setEscrowFee] = useState();
  const [escrowPeriod, setEscrowPeriod] = useState(12);
  const [remainBalance, setRemainBalance] = useState();
  const [prepaidTitleInsurance, setPrepaidTitleInsurance] = useState(false);
  const [specialAssessment, setSpecialAssessment] = useState(false);
  const [inspectionReports, setInspectionReports] = useState([]);
  const [propertyReport, setPropertyReport] = useState([]);
  const [deedDocuments, setDeedDocuments] = useState([]);
  const [escrowAgreement, setEscrowAgreement] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [frontCover, setFrontCover] = useState([]);
  const [photoProgress, setPhotoProgress] = useState({});
  const [frontCoverProgress, setFrontCoverProgress] = useState({});
  const [validateError, setValidateError] = useState({});
  const [isKycVerified, setKycVerified] = useState(undefined);
  const [isRegistering, setIsRegistering] = useState(false);
  const refNftId = useRef(null);
  const [isReLoad, setIsReload] = useState(false);
  const { authToken, user } = useSelector(state => state.Auth);

  const getCollections = async () => {
    try {
      const data = await getCategories();
      if (data.length) {
        setCollections(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setRoyalty(0.2);
    refNftId.current = createdNftId;
    if (authToken) {
      getCollections();
      const fetchKyc = async () => {
        const userIsKycVerified = await getUserIsKycVerified(authToken);
        setKycVerified(userIsKycVerified);
      };
      fetchKyc();
    }
  }, [authToken, createdNftId]);

  useEffect(() => {
    dispatch(HeaderActions.toggleSearchbar(true));
    dispatch(FilterActions.updateCategoryFilter(null));
    dispatch(FilterActions.resetStatusFilter());
  }, []);

  const updateMode = useMemo(
    () =>
      !!createdNftId &&
      (user?.role_id === USER_ROLES.ADMIN ||
        user?.role_id === USER_ROLES.ESCROW_AGENT),
    [createdNftId, user]
  );

  const { isLoaded } = useSelector(state => state.Maps);

  useEffect(() => {
    if (!createdNftId && !!name) {
      history.go();
    }
    const assetTransform = gaiaAssetList => {
      return gaiaAssetList.map(el => {
        return {
          assetId: el?.asset_id || el?.assetId,
          url: el?.name || el?.url,
          fullUrl: el?.asset_id ? el?.url : el?.fullUrl,
        };
      });
    };
    const setupData = item => {
      try {
        try {
          assetIdField('Front cover')
            .max(1)
            .validateSync(assetTransform(item.frontCover));
          setFrontCover(assetTransform(item.frontCover));
        } catch (error) {
          showToast('error', error.message);
        }
        try {
          geolField('Google map position').validateSync(item.geo_position);
          setGeoPoint(item.geo_position);
        } catch (error) {
          setGeoPoint(ZERO_POINT);
        }
        try {
          assetIdField('Photos')
            .max(MAX_FILES)
            .validateSync(assetTransform(item.photos));
          setPhotos(assetTransform(item.photos));
        } catch (error) {
          showToast('error', error.message);
        }
        try {
          stringField('Name').validateSync(item.name);
          setName(item.name);
        } catch (error) {
          showToast('error', error.message);
        }
        try {
          stringField('Collection ID').validateSync(
            item.collection_id || item.category_id
          );
          setSelected(item.collection_id || item.category_id);
        } catch (error) {
          showToast('error', error.message);
        }
        try {
          stringField('Description').validateSync(item.description);
          setDescription(item.description);
        } catch (error) {
          showToast('error', error.message);
        }
        try {
          linkField('Link to video').validateSync(item.linkToVideo);
          setVideoLink(item.linkToVideo);
        } catch (error) {
          showToast('error', error.message);
        }
        try {
          requiredNumberField('Annual property taxes').validateSync(
            item.annualPropertyTaxes
          );
          setAnnualPropertyTaxes(item.annualPropertyTaxes);
        } catch (error) {
          showToast('error', error.message);
        }
        try {
          requiredNumberField('Monthly HOA').validateSync(item.monthlyHoa);
          setMonthlyHoa(item.monthlyHoa);
        } catch (error) {
          showToast('error', error.message);
        }
        try {
          numberField('Parcel Number').validateSync(item.parcelNumber);
          setParcelNumber(item.parcelNumber);
        } catch (error) {
          showToast('error', error.message);
        }
        try {
          boolField('Special assessment').validateSync(item.specialAssessment);
          setSpecialAssessment(item.specialAssessment);
        } catch (error) {
          showToast('error', error.message);
        }
        try {
          boolField('Prepaid title insurance').validateSync(
            item.prepaidTitleInsurance
          );
          setPrepaidTitleInsurance(item.prepaidTitleInsurance);
        } catch (error) {
          showToast('error', error.message);
        }
        try {
          requiredNumberField('Monthly property insurance').validateSync(
            item.monthlyPropertyInsurance
          );
          setMonthlyPropertyInsurance(item.monthlyPropertyInsurance);
        } catch (error) {
          showToast('error', error.message);
        }
        try {
          assetIdField('Inspection reports').validateSync(
            assetTransform(item.inspectionReports)
          );
          setInspectionReports(assetTransform(item.inspectionReports));
        } catch (error) {
          showToast('error', error.message);
        }
        try {
          assetIdField('Property disclosure report').validateSync(
            assetTransform(item.propertyReport)
          );
          setPropertyReport(assetTransform(item.propertyReport));
        } catch (error) {
          showToast('error', error.message);
        }
        try {
          assetIdField('Deed and other documents').validateSync(
            assetTransform(item.deedDocuments)
          );
          setDeedDocuments(assetTransform(item.deedDocuments));
        } catch (error) {
          showToast('error', error.message);
        }
        try {
          assetIdField('Trust or escrow agreement').validateSync(
            assetTransform(item.escrowAgreement)
          );
          setEscrowAgreement(assetTransform(item.escrowAgreement));
        } catch (error) {
          showToast('error', error.message);
        }
        try {
          assetIdField(
            'Title Search Report and Additional Documents'
          ).validateSync(assetTransform(item.assetAttachments));
          setAttachments(assetTransform(item.assetAttachments));
        } catch (error) {
          showToast('error', error.message);
        }
        if (updateMode) {
          try {
            numberField('Escrow Fee').validateSync(item.escrow_fee);
            setEscrowFee(item.escrow_fee);
          } catch (error) {
            showToast('error', error.message);
          }
          try {
            numberField('Period').validateSync(item.escrow_fee_period);
            setEscrowPeriod(item.escrow_fee_period || 12);
          } catch (error) {
            showToast('error', error.message);
          }
          try {
            decimalField('Remain Balance')
              .nullable(true)
              .transform(value => (isNaN(value) ? undefined : value))
              .validateSync(item.remain_balance);
            setRemainBalance(item.remain_balance);
          } catch (error) {
            showToast('error', error.message);
          }
        }
      } catch (err) {
        showToast('error', 'Please fill the fields again');
      }
    };

    const fetchNft = async () => {
      const { nft, error, status } = await fetchCreatedNft(
        authToken,
        createdNftId
      );
      if (error) {
        showToast('error', `${status} ${error}`);
        history.push(`/create`);
        return;
      }
      setupData(nft);
    };
    const savedItem = window.localStorage.getItem('nftItem');
    if (updateMode) {
      fetchNft();
    } else if (savedItem) {
      const savedNft = JSON.parse(savedItem);
      setupData(savedNft);
    }
    setIsReload(true);
  }, [authToken, updateMode]);

  const savingFields = JSON.stringify({
    name,
    geo_position: geoPoint,
    description,
    collection_id: selected,
    linkToVideo: videoLink,
    annualPropertyTaxes,
    monthlyHoa,
    monthlyPropertyInsurance,
    parcelNumber,
    prepaidTitleInsurance,
    specialAssessment,
    inspectionReports,
    propertyReport,
    deedDocuments,
    escrowAgreement,
    assetAttachments: attachments,
    photos,
    frontCover,
  });

  useEffect(() => {
    if (!updateMode && isReLoad) {
      window.localStorage.setItem('nftItem', savingFields);
    }
  }, [savingFields, updateMode, isReLoad]);

  const registerNftHandler = async () => {
    setIsRegistering(true);
    try {
      if (isRegistering) return;
      if (!updateMode) {
        if (!account || !chainId) {
          setIsRegistering(false);
          showToast('error', 'Please connect your wallet');
          dispatch(ModalActions.showConnectWalletModal());
          return;
        }
        if (chainId !== Number(process.env.REACT_APP_NETWORK_CHAIN_ID)) {
          setIsRegistering(false);
          await WalletUtils.checkNetworkChain(chainId);
          setIsRegistering(false);
          return;
        }
        if (!isKycVerified) {
          setIsRegistering(false);
          showToast('error', 'KYC is not verified');
          return;
        }
      }
      try {
        await formObject.validate(
          {
            frontCover,
            photos,
            collectionId: selected,
            name,
            geoPoint,
            description,
            videoLink,
            annualPropertyTaxes,
            monthlyHoa,
            royalty,
            monthlyPropertyInsurance,
            parcelNumber,
            prepaidTitleInsurance,
            specialAssessment,
            inspectionReports,
            propertyReport,
            deedDocuments,
            escrowAgreement,
            attachments,
            escrowFee,
            escrowPeriod,
            remainBalance,
          },
          { abortEarly: false }
        );
        setValidateError({});
      } catch (error) {
        let errorList = {};
        error.inner.forEach(el => (errorList[el.path] = true));
        setValidateError({ ...errorList });
        const element = error.inner[0].path;
        document
          .getElementById(element)
          .scrollIntoView({ block: 'center', behavior: 'smooth' });
        setIsRegistering(false);
        return;
      }

      let formData = new FormData();
      formData.append('annualPropertyTaxes', annualPropertyTaxes);
      formData.append('monthlyHoa', monthlyHoa);
      formData.append('monthlyPropertyInsurance', monthlyPropertyInsurance);
      formData.append('parcelNumber', parcelNumber);
      formData.append('royalty', royalty);
      formData.append('collectionId', selected);
      formData.append('description', description);
      formData.append('name', name);
      formData.append('geoPoint', JSON.stringify(geoPoint));
      formData.append('prepaidTitleInsurance', prepaidTitleInsurance);
      formData.append('specialAssessment', specialAssessment);
      formData.append('linkToVideo', videoLink);
      formData.append('updatedNftId', refNftId.current);

      formData.append('escrowFee', escrowFee);
      formData.append('escrowPeriod', escrowPeriod);
      formData.append('remainBalance', remainBalance);

      const takingAssetId = asset => {
        return asset.map(el => el.assetId);
      };
      formData.append(
        'attachments',
        JSON.stringify(takingAssetId(attachments))
      );
      formData.append(
        'escrowAgreement',
        JSON.stringify(takingAssetId(escrowAgreement))
      );
      formData.append(
        'deedDocuments',
        JSON.stringify(takingAssetId(deedDocuments))
      );
      formData.append(
        'propertyReport',
        JSON.stringify(takingAssetId(propertyReport))
      );
      formData.append(
        'inspectionReports',
        JSON.stringify(takingAssetId(inspectionReports))
      );
      formData.append('photos', JSON.stringify(takingAssetId(photos)));
      formData.append('frontCover', JSON.stringify(takingAssetId(frontCover)));

      const data = await registerNft(authToken, formData);
      const nftId = data?.nftId;
      if (data?.error) {
        showToast('error', data?.error);
      } else if (isNaN(nftId)) {
        showToast('error', 'Incorrect NFT ID!');
      } else {
        if (!updateMode) {
          refNftId.current = nftId;
          window.localStorage.removeItem('nftItem');
          await preMint({
            authToken,
            nftId: refNftId.current,
          });
          showToast('success', 'New NFT item registered!');
        } else {
          showToast('success', 'The NFT has been updated!');
        }
        window.localStorage.removeItem('nftItem');
        history.push(`/pending/${nftId}`);
      }
      setIsRegistering(false);
    } catch (error) {
      console.log(error);
      setIsRegistering(false);
    }
  };

  const cancelHandler = () => {
    window.localStorage.removeItem('nftItem');
    history.go(-1);
  };

  const waitingUploadPhotos = Object.keys(photoProgress).length !== 0;
  const waitingUploadFrontCover = Object.keys(frontCoverProgress).length !== 0;

  const isDocumentAttached =
    inspectionReports.length > 0 ||
    propertyReport.length > 0 ||
    deedDocuments.length > 0 ||
    escrowAgreement.length > 0 ||
    attachments.length > 0;

  const removeAssetPhoto = async id => {
    setPhotos(prevState => {
      const dublicate = [...prevState];
      const removingIndex = dublicate.findIndex(el => el.assetId === id);
      dublicate.splice(removingIndex, 1);
      return dublicate;
    });

    if (!updateMode) {
      await deleteFile(authToken, id);
    }
  };

  const removeAssetFrontCover = async id => {
    setFrontCover(prevState => {
      const dublicate = [...prevState];
      const removingIndex = dublicate.findIndex(el => el.assetId === id);
      dublicate.splice(removingIndex, 1);
      return dublicate;
    });

    if (!updateMode) {
      await deleteFile(authToken, id);
    }
  };

  const bars = Object.entries(frontCoverProgress).map(el => {
    return (
      !!el[1] && (
        <HightProgress variant="determinate" key={el[0]} value={el[1]} />
      )
    );
  });

  const photoBars = Object.entries(photoProgress).map(el => {
    return (
      !!el[1] && (
        <HightProgress variant="determinate" key={el[0]} value={el[1]} />
      )
    );
  });

  const showKYCWarning = () => {
    return isKycVerified !== undefined && !isKycVerified;
  };

  return (
    <div className={styles.container}>
      <Header border />
      {!updateMode && showKYCWarning() && (
        <div className={styles.warningContainer}>
          <div className={styles.kycWarning}>
            <IconExclamation />
            <span>
              <span className={styles.warningWord}>WARNING:</span> to create NFT
              you need to get KYC authentication.
              <br />
              You can get it in your&nbsp;
              <a href={`/profile`} className={styles.link}>
                profile
              </a>
              .
            </span>
          </div>
        </div>
      )}
      <div
        className={cx(styles.body, showKYCWarning() && styles.bodyWhenWarning)}
      >
        <div className={styles.panel}>
          <p className={styles.createLogo}>Create NFT</p>
          <h2 className={styles.createSubtitles}>
            Welcome to LiquidEarth 👋
            <br />
            We are the premier NFT platform for creating and trading your
            real-world assets.
            <br />
            Start creating and trading your NFTs on LiquidEarth today!
            <br />
            If you have questions on our minting process, please check out
            our&nbsp;
            <a
              href="https://docs.liquidearth.io/"
              className={cx(styles.linkUnderline, styles.docsLink)}
              rel="noopener noreferrer"
              target="_blank"
            >
              docs
            </a>
            , or ask a question on the intercom interface below.
          </h2>
          <div className={styles.panelInputs}>
            <div className={styles.inputGroup}>
              <p className={styles.additionalLabel}>Upload Cover Photo*</p>
              <p className={styles.smallLabel}>
                Drag or choose your file to upload
              </p>
              <div
                className={cx(
                  styles.board,
                  validateError.frontCover && styles.errorValidate
                )}
              >
                <InputPhotos
                  key={'FrontCover'}
                  changeFunction={setFrontCover}
                  assetType={'frontCover'}
                  multiple={false}
                  setProgressUpload={setFrontCoverProgress}
                  waitingUpload={waitingUploadFrontCover}
                  data={frontCover}
                  removeCb={removeAssetFrontCover}
                />
              </div>
            </div>
            <div id="photos" className={styles.inputGroup}>
              <p className={styles.additionalLabel}>Upload Additional Photos</p>
              <p className={styles.smallLabel}>
                Drag or choose your file to upload
              </p>
              <div
                className={cx(
                  styles.board,
                  validateError.photos && styles.errorValidate
                )}
              >
                <InputPhotos
                  key={'Photos'}
                  changeFunction={setPhotos}
                  assetType={'photos'}
                  multiple={true}
                  setProgressUpload={setPhotoProgress}
                  waitingUpload={waitingUploadPhotos}
                  data={photos}
                  removeCb={removeAssetPhoto}
                  photosLength={photos.length}
                />
                <div className={styles.fullBarLoader}>{photoBars}</div>
              </div>
            </div>
            {updateMode && (
              <div className={styles.inputGroup}>
                <p className={styles.additionalLabel}>Escrow Fee:</p>
                <div id="escrowFee" className={styles.formGroup}>
                  <p className={styles.formLabel}>Escrow Fee (in $USD)*</p>
                  <input
                    className={cx(
                      styles.formInput,
                      validateError.escrowFee && styles.errorValidate
                    )}
                    type="number"
                    min="0"
                    max="99999999999999"
                    placeholder="Enter escrow fee"
                    onChange={e => setEscrowFee(e.target.value)}
                    value={escrowFee}
                  />
                </div>
                <div id="escrowPeriod" className={styles.formGroup}>
                  <p className={styles.formLabel}>Period*</p>
                  <GraySelect
                    disableUnderline
                    value={escrowPeriod}
                    onChange={e => {
                      setEscrowPeriod(e.target.value);
                    }}
                    className={cx(
                      styles.select,
                      validateError.escrowPeriod && styles.errorValidate
                    )}
                    IconComponent={ArrowIcon}
                  >
                    <MenuItem value="0" disabled>
                      <p className={styles.menuLabel}>Choose period</p>
                    </MenuItem>
                    <MenuItem value="3">3 months</MenuItem>
                    <MenuItem value="6">6 months</MenuItem>
                    <MenuItem value="9">9 months</MenuItem>
                    <MenuItem value="12">12 months</MenuItem>
                  </GraySelect>
                </div>
                <div id="remainBalance" className={styles.formGroup}>
                  <p className={styles.formLabel}>Remain Balance*</p>
                  <input
                    className={cx(
                      styles.formInput,
                      validateError.remainBalance && styles.errorValidate
                    )}
                    type="number"
                    min="0"
                    max="99999999999999"
                    placeholder="Enter remain balance"
                    onChange={e => setRemainBalance(e.target.value)}
                    value={remainBalance}
                  />
                </div>
              </div>
            )}
            <div className={styles.inputGroup}>
              <p className={styles.additionalLabel}>About Property:</p>
              <div id="collectionId" className={styles.formGroup}>
                <p className={styles.formLabel}>Category*</p>
                <GraySelect
                  disableUnderline
                  value={selected}
                  onChange={e => {
                    setSelected(e.target.value);
                  }}
                  className={cx(
                    styles.select,
                    validateError.collectionId && styles.errorValidate
                  )}
                  IconComponent={ArrowIcon}
                >
                  <MenuItem value="0" disabled>
                    <p className={styles.menuLabel}>Choose category</p>
                  </MenuItem>
                  {collections.map(col => (
                    <MenuItem key={col?._id} value={col?._id}>
                      {col.label}
                    </MenuItem>
                  ))}
                </GraySelect>
              </div>
              <div id="name" className={styles.formGroup}>
                <p className={styles.formLabel}>
                  Address (Street, Apartment, City, State, Zip, Country)*
                </p>
                {isLoaded ? (
                  <PlacesAutocomplete
                    validateError={validateError}
                    setPointName={setName}
                    setGeoPoint={setGeoPoint}
                    initialPosition={geoPoint}
                    initialName={name}
                  />
                ) : (
                  <ClipLoader color="#FFF" size={16} />
                )}
              </div>
              <div className={styles.formGroup}>
                <p className={styles.formLabel}>Special assessment&nbsp;</p>
                <GraySelect
                  disableUnderline
                  value={specialAssessment}
                  onChange={e => {
                    setSpecialAssessment(e.target.value);
                  }}
                  className={cx(
                    styles.select,
                    validateError.specialAssessment && styles.errorValidate
                  )}
                  IconComponent={ArrowIcon}
                  placeholder="Choose an option"
                >
                  {switchStates.map(el => (
                    <MenuItem key={el?.label} value={el?.value}>
                      {el.label}
                    </MenuItem>
                  ))}
                </GraySelect>
              </div>
              <div id="description" className={styles.formGroup}>
                <p className={styles.formLabel}>
                  Description (#bedrooms, #baths, sqft - Amenities, General
                  Description)*
                </p>
                <textarea
                  className={cx(
                    styles.formInput,
                    styles.longInput,
                    validateError.description && styles.errorValidate
                  )}
                  placeholder="Type here"
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  disabled={false}
                />
                <div className={styles.lengthIndicator}></div>
              </div>
            </div>
            <div className={styles.inputGroup}>
              <p className={styles.additionalLabel}>3rd Party Info:</p>
              <div id="videoLink" className={styles.formGroup}>
                <p className={styles.formLabel}>Link to video*</p>
                <input
                  className={cx(
                    styles.formInput,
                    validateError.videoLink && styles.errorValidate
                  )}
                  type="url"
                  placeholder="Enter link"
                  onChange={e => setVideoLink(e.target.value)}
                  value={videoLink}
                />
              </div>
            </div>
            <div className={styles.inputGroup}>
              <p className={styles.additionalLabel}>Financial:</p>
              <div id="annualPropertyTaxes" className={styles.formGroup}>
                <p className={styles.formLabel}>Annual property taxes*</p>
                <input
                  className={cx(
                    styles.formInput,
                    validateError.annualPropertyTaxes && styles.errorValidate
                  )}
                  type="number"
                  min="0"
                  max="99999999999999"
                  placeholder="Enter taxes"
                  onChange={e => setAnnualPropertyTaxes(e.target.value)}
                  value={annualPropertyTaxes}
                />
              </div>
              <div id="monthlyHoa" className={styles.formGroup}>
                <p className={styles.formLabel}>Monthly HOA*</p>
                <input
                  className={cx(
                    styles.formInput,
                    validateError.monthlyHoa && styles.errorValidate
                  )}
                  type="number"
                  min="0"
                  max="99999999999999"
                  placeholder="Enter HOA"
                  onChange={e => setMonthlyHoa(e.target.value)}
                  value={monthlyHoa}
                />
              </div>
              <div
                className={cx(
                  styles.formGroup,
                  validateError.royalty && styles.errorValidate
                )}
              >
                <p className={styles.formLabel}>
                  Royalty* (%)&nbsp;
                  <BootstrapTooltip
                    title="You will get 0.2 percent of sales price each time an NFT is sold on our platform."
                    placement="top"
                  >
                    <HelpOutlineIcon />
                  </BootstrapTooltip>
                </p>
                <PriceInput
                  className={styles.formInput}
                  placeholder="Enter royalty"
                  decimals={2}
                  value={'' + royalty}
                  onChange={val =>
                    val[val.length - 1] === '.'
                      ? setRoyalty(val)
                      : setRoyalty(Math.min(100, +val))
                  }
                  disabled={true}
                />
              </div>
              <div className={styles.formGroup}>
                <p className={styles.formLabel}>
                  Prepaid title insurance&nbsp;
                </p>
                <GraySelect
                  disableUnderline
                  value={prepaidTitleInsurance}
                  onChange={e => {
                    setPrepaidTitleInsurance(e.target.value);
                  }}
                  className={cx(
                    styles.select,
                    validateError.prepaidTitleInsurance && styles.errorValidate
                  )}
                  IconComponent={ArrowIcon}
                  placeholder="Choose an option"
                >
                  {switchStates.map(el => (
                    <MenuItem key={el?.label} value={el?.value}>
                      {el.label}
                    </MenuItem>
                  ))}
                </GraySelect>
              </div>
              <div id="monthlyPropertyInsurance" className={styles.formGroup}>
                <p className={styles.formLabel}>Monthly property insurance*</p>
                <input
                  className={cx(
                    styles.formInput,
                    validateError.monthlyPropertyInsurance &&
                      styles.errorValidate
                  )}
                  type="number"
                  min="0"
                  max="99999999999999"
                  placeholder="Enter monthly property insurance"
                  onChange={e => setMonthlyPropertyInsurance(e.target.value)}
                  value={monthlyPropertyInsurance}
                />
              </div>
              <div id="parcelNumber" className={styles.formGroup}>
                <p className={styles.formLabel}>
                  Parcel Number or Property Record Number
                </p>
                <input
                  className={cx(
                    styles.formInput,
                    validateError.parcelNumber && styles.errorValidate
                  )}
                  type="number"
                  min="0"
                  max="99999999999999"
                  placeholder="Enter Parcel Number or Property Record Number"
                  onChange={e => setParcelNumber(e.target.value)}
                  value={parcelNumber}
                />
              </div>
              <p className={styles.infoLabel}>
                Coming Soon: NFT Properties with Existing Leverage. Contact Us
                for More Information
              </p>
            </div>
            <div className={styles.inputGroup}>
              <p className={styles.additionalLabel}>Attachments:</p>
              <p className={styles.formLabel}>
                These documents are part of the admin process to verify your
                property. If you do not have all of these, they will be added
                later in collaboration with our admin team.
              </p>
              <div className={styles.documentsPanel}>
                <div
                  className={cx(
                    styles.inputDocument,
                    validateError.inspectionReports && styles.errorValidate
                  )}
                >
                  <InputPdfFile
                    key={'InspectionReports'}
                    label="Inspection reports"
                    changeFunction={setInspectionReports}
                    assetType={'inspectionReports'}
                  />
                </div>
                <div
                  className={cx(
                    styles.inputDocument,
                    validateError.propertyReport && styles.errorValidate
                  )}
                >
                  <InputPdfFile
                    key={'PropertyDisclosureReport'}
                    label="Property disclosure report"
                    changeFunction={setPropertyReport}
                    multiple={false}
                    assetType={'propertyReport'}
                  />
                </div>
                <div
                  className={cx(
                    styles.inputDocument,
                    validateError.deedDocuments && styles.errorValidate
                  )}
                >
                  <InputPdfFile
                    key={'DeedAndOtherDocuments'}
                    label="Deed and other documents"
                    changeFunction={setDeedDocuments}
                    assetType={'deedDocuments'}
                  />
                </div>
                <div
                  className={cx(
                    styles.inputDocument,
                    validateError.escrowAgreement && styles.errorValidate
                  )}
                >
                  <InputPdfFile
                    key={'TrustOrEscrowAgreement'}
                    label="Trust or escrow agreement"
                    changeFunction={setEscrowAgreement}
                    assetType={'escrowAgreement'}
                  />
                </div>
                <div
                  className={cx(
                    styles.inputDocument,
                    validateError.attachments && styles.errorValidate
                  )}
                >
                  <InputPdfFile
                    key={'Attachments'}
                    label="Additional Documents"
                    changeFunction={setAttachments}
                    assetType={'attachments'}
                  />
                </div>
              </div>
              {isDocumentAttached && (
                <>
                  {inspectionReports.length > 0 && (
                    <div className={cx(styles.formGroup)}>
                      <p className={styles.formLabel}>Inspection reports</p>
                      <div className={styles.documentsList}>
                        <DocumentList
                          documents={inspectionReports}
                          setDocuments={setInspectionReports}
                          updateMode
                        />
                      </div>
                    </div>
                  )}
                  {propertyReport.length > 0 && (
                    <div className={cx(styles.formGroup)}>
                      <p className={styles.formLabel}>
                        Property disclosure report
                      </p>
                      <div className={styles.documentsList}>
                        <DocumentList
                          documents={propertyReport}
                          setDocuments={setPropertyReport}
                          updateMode
                        />
                      </div>
                    </div>
                  )}
                  {deedDocuments.length > 0 && (
                    <div className={cx(styles.formGroup)}>
                      <p className={styles.formLabel}>
                        Deed and other documents
                      </p>
                      <div className={styles.documentsList}>
                        <DocumentList
                          documents={deedDocuments}
                          setDocuments={setDeedDocuments}
                          updateMode
                        />
                      </div>
                    </div>
                  )}
                  {escrowAgreement.length > 0 && (
                    <div className={cx(styles.formGroup)}>
                      <p className={styles.formLabel}>
                        Trust or escrow agreement
                      </p>
                      <div className={styles.documentsList}>
                        <DocumentList
                          documents={escrowAgreement}
                          setDocuments={setEscrowAgreement}
                          updateMode
                        />
                      </div>
                    </div>
                  )}
                  {attachments.length > 0 && (
                    <div className={cx(styles.formGroup)}>
                      <p className={styles.formLabel}>
                        Add Title Search Report and Additional Documents
                      </p>
                      <div className={styles.documentsList}>
                        <DocumentList
                          documents={attachments}
                          setDocuments={setAttachments}
                          updateMode
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <h2 className={styles.createSubtitles}>
            Admin Fees at LiquidEarth are used to prevent fraud and ensure legal
            ownership of the asset.
            <br />
            <span className={styles.currentFeePrice}>
              The current admin fee for onboarding assets is $10.
            </span>
            <br />
            For more information please see our&nbsp;
            <a
              href="https://docs.liquidearth.io/"
              rel="noopener noreferrer"
              target="_blank"
            >
              pricing.
            </a>
          </h2>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <div className={cx(styles.cancelButton)} onClick={cancelHandler}>
              Cancel
            </div>
            <div
              className={cx(
                styles.button,
                Object.keys(validateError).length > 0 && styles.errorValidate
              )}
              onClick={registerNftHandler}
            >
              {updateMode ? (
                'Update'
              ) : isRegistering ? (
                <ClipLoader color="#FFF" size={16} />
              ) : (
                'Create'
              )}
            </div>
          </Box>
        </div>
        <div className={styles.imageColumn}>
          <div id="frontCover" className={styles.photoContainer}>
            <div
              className={cx(
                styles.imageHolder,
                validateError.frontCover && styles.errorValidate
              )}
            >
              <p className={styles.mediumLogo}>Preview</p>
              <div className={styles.photoSceleton}>
                {frontCover.length > 0 ? (
                  <img className={styles.photo} src={frontCover[0].fullUrl} />
                ) : (
                  <Skeleton delay={10} className={styles.imgSceleton} />
                )}
                <Skeleton
                  delay={10}
                  width={210}
                  height={15}
                  style={{
                    margin: '20px',
                    marginTop: '14px',
                    marginBottom: '11px',
                  }}
                />
                <div>
                  <Skeleton
                    delay={10}
                    width={56}
                    height={15}
                    style={{ marginRight: '74px', marginLeft: '20px' }}
                  />
                  <Skeleton delay={10} width={80} height={15} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.barLoader}>{bars}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PaintBoard;
