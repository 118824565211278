import { ethers } from 'ethers';
import showToast from './toast';

const networks = new Map([
  [
    '4002',
    {
      name: 'Fantom testnet',
      rpcUrl: 'https://rpc.testnet.fantom.network/',
      chainId: '0xfa2',
      symbol: 'FTM',
    },
  ],
  [
    '250',
    {
      name: 'Fantom',
      rpcUrl: 'https://rpc.ftm.tools/',
      chainId: '0xfa',
      symbol: 'FTM',
      blockExplorerUrl: 'https://ftmscan.com/',
    },
  ],
]);

const checkBalance = async address => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  let balance = await provider.getBalance(address);
  balance = ethers.utils.formatEther(balance);
  return balance;
};

const checkWalletValidation = (user, walletAddress) => {
  console.log('check', walletAddress, user);
  return (
    !!walletAddress &&
    !!user &&
    user.user_wallets &&
    user?.user_wallets.length &&
    user?.user_wallets[0]?.address?.toLowerCase() ===
      walletAddress?.toLowerCase()
  );
};

const checkNetworkChain = async chainId => {
  const correctChainId = process.env.REACT_APP_NETWORK_CHAIN_ID;
  const correctNetwork = networks.get(correctChainId);
  if (chainId !== Number(correctChainId)) {
    try {
      showToast(
        'error',
        `Please change current wallet network to ${correctNetwork.name}`
      );
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: correctNetwork.chainId,
          },
        ],
      });
    } catch (err) {
      // This error code indicates that the chain has not been added to MetaMask
      if (err.code === 4902) {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainName: correctNetwork.name,
              chainId: correctNetwork.chainId,
              nativeCurrency: {
                name: correctNetwork.symbol,
                decimals: 18,
                symbol: correctNetwork.symbol,
              },
              rpcUrls: [correctNetwork.rpcUrl],
            },
          ],
        });
      } else {
        console.log(err);
      }
    }
  }
};

const WalletUtils = {
  checkBalance,
  checkWalletValidation,
  checkNetworkChain,
};

export default WalletUtils;
