import { AuthConstants } from '../constants/auth.constants';

const AuthActions = {
  fetchStart,
  fetchSuccess,
  fetchFailed,
  signOut,
  signIn,
  updateBanner,
  set2FA,
};

function signIn(user, authToken) {
  return dispatch => {
    dispatch(_signIn(user, authToken));
  };
}

const _signIn = (user, authToken) => {
  return {
    type: AuthConstants.SIGN_IN,
    user,
    authToken,
  };
};

function fetchStart() {
  return dispatch => {
    dispatch(_fetchStart());
  };
}

const _fetchStart = () => {
  return {
    type: AuthConstants.PROFILE_GET_START,
  };
};

function fetchSuccess(user) {
  return dispatch => {
    dispatch(_fetchSuccess(user));
  };
}

const _fetchSuccess = user => {
  return {
    type: AuthConstants.PROFILE_GET_SUCCESS,
    payload: user,
  };
};

function fetchFailed() {
  return dispatch => {
    dispatch(_fetchFailed());
  };
}

const _fetchFailed = () => {
  return {
    type: AuthConstants.PROFILE_GET_FAILED,
  };
};

function signOut() {
  return dispatch => {
    dispatch(_signOut());
  };
}

const _signOut = () => {
  return {
    type: AuthConstants.SIGN_OUT,
  };
};

function updateBanner(banner) {
  return dispatch => {
    dispatch(_updateBanner(banner));
  };
}

const _updateBanner = banner => {
  return {
    type: AuthConstants.UPDATE_BANNER,
    payload: banner,
  };
};

function set2FA(authToken) {
  return dispatch => {
    dispatch(_set2FA(authToken));
  };
}

const _set2FA = authToken => {
  return {
    type: AuthConstants.SET_2FA,
    payload: authToken,
  };
};

export default AuthActions;
