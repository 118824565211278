import { WalletConnectConstants } from '../constants/walletconnect.constants';

const WalletConnectActions = {
  connectWallet,
  disconnectWallet,
  wrongWalletError,
  resetWalletError,
};

function connectWallet(authToken, isModerator) {
  return dispatch => {
    dispatch(_connectWallet(authToken, isModerator));
  };
}

const _connectWallet = (authToken, isModerator) => {
  return {
    type: WalletConnectConstants.WALLETCONNECTED,
    token: authToken,
    isModerator,
  };
};

function disconnectWallet() {
  return dispatch => {
    dispatch(_disconnectWallet());
  };
}

const _disconnectWallet = () => {
  return {
    type: WalletConnectConstants.WALLETDISCONNECTED,
  };
};

function wrongWalletError() {
  return dispatch => {
    dispatch(_wrongWalletError());
  };
}

const _wrongWalletError = () => {
  return {
    type: WalletConnectConstants.WRONG_WALLET,
  };
};

function resetWalletError() {
  return dispatch => {
    dispatch(_resetWalletError());
  };
}

const _resetWalletError = () => {
  return {
    type: WalletConnectConstants.RESET_WRONG_WALLET,
  };
};

export default WalletConnectActions;
