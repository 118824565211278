import React, { useEffect, useState } from 'react';
import Header from 'components/header';
import { useHistory, useLocation } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
import StatusFilter from 'components/StatusFilter';
import CategoriesFilter from 'components/CategoriesFilter';
import AdminDashboardItem from 'components/AdminDashboardItem';
import AdminReferralSystemItem from 'components/AdminRefferalSystemItem';

import styles from './styles.module.scss';
import Footer from 'components/Footer';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useApi } from '../../api';
import { ClipLoader } from 'react-spinners';
import ticker from '../../assets/ticker.svg';
import HeaderActions from '../../actions/header.actions';
import FilterActions from '../../actions/filter.actions';
import showToast from 'utils/toast';
import ExploreFilterHeader from '../explorepage/Body/FilterHeader';
import SellerFinanceFilter from '../../components/SellerFinanceFilter';
import CsvReportlModal from '../../components/CsvReportModal';
import ModalActions from 'actions/modal.actions';
import { USER_ROLES } from '../../constants/userRoles.constants';

const Dashboard = ({ limit = 20 }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [totalItemsCount, setTotalItemsCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [maxPage, setMaxPage] = useState(null);
  const [prevPath, setPrevPath] = useState(null);
  const {
    getNftsAsAdmin,
    getTradeHistoriesWithReferral,
    getReferralDataForTradeHistories,
  } = useApi();
  const { authToken, user } = useSelector(state => state.Auth);
  const { status, category } = useSelector(state => state.Filter);

  const getLocationPath = () => {
    const pathArray = location.pathname.split('/');
    return pathArray[pathArray.length - 1];
  };

  const formatDate = date => {
    return moment.utc(new Date(date)).format('MM/DD/YYYY hh:mm UTC');
  };

  const pageItem = props => {
    console.log(getLocationPath());
    const componentDictionary = {
      items: <AdminDashboardItem key={props.key} {...props} />,
      'referral-system': <AdminReferralSystemItem key={props.key} {...props} />,
    };

    return componentDictionary[getLocationPath()];
  };

  const handlePaginationChange = (event, value) => {
    const params = new URLSearchParams({ page: value });
    history.push({ pathname: location.pathname, search: params.toString() });
    setCurrentPage(value);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    dispatch(HeaderActions.toggleSearchbar(true));
    dispatch(FilterActions.updateCategoryFilter(null));
    dispatch(FilterActions.resetStatusFilter());
  }, []);

  useEffect(() => {
    const currentPath = getLocationPath();
    if (
      prevPath &&
      prevPath !== currentPath &&
      currentPage &&
      currentPage !== 1
    ) {
      handlePaginationChange(null, 1);
    } else if (currentPage) {
      setPrevPath(currentPath);
      if (currentPath === 'items') {
        const offset = (currentPage - 1) * limit;
        setItems(null);
        setTotalItemsCount(0);
        getNftsAsAdmin(authToken, offset, limit, status, category)
          .then(data => {
            if (data?.error) {
              throw new Error(data.error);
            }
            const [nftsDto, totalItemsCount] = data;
            if (nftsDto) {
              const nfts = nftsDto.map(nft => {
                return {
                  id: nft.nft_id,
                  nft_link: nft.nft_link,
                  title: nft?.name,
                  subtitle: nft?.description ? nft.description : '',
                  date_created: nft?.created_at
                    ? formatDate(nft.created_at)
                    : 'No date',
                  date_purchased: nft?.purchased_at
                    ? formatDate(nft.purchased_at)
                    : 'No date',
                  price: nft?.price ? nft.price : 'No price',
                  icon: nft?.imageURL || nft?.mainAttachment?.url,
                  status: nft?.nft_status?.name
                    ? nft.nft_status.name
                    : 'No status',
                  ticker,
                };
              });
              setItems(nfts);
              if (Number.isInteger(totalItemsCount) && totalItemsCount >= 0) {
                setTotalItemsCount(totalItemsCount);
                const newMaxPage = Math.ceil(totalItemsCount / limit) || 1;
                setMaxPage(newMaxPage);
                if (newMaxPage && currentPage && currentPage > newMaxPage) {
                  handlePaginationChange(null, newMaxPage);
                }
              }
            }
          })
          .catch(error => {
            console.log(error);
            setItems(null);
            showToast('error', "Couldn't load NFTs");
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else if (currentPath === 'referral-system') {
        const offset = (currentPage - 1) * limit;
        setItems(null);
        setTotalItemsCount(0);
        getTradeHistoriesWithReferral(authToken, offset, limit)
          .then(async data => {
            if (data?.error) {
              throw new Error(data.error);
            }
            const [tradeHistoriesWithReferral, totalItemsCount] = data;
            if (tradeHistoriesWithReferral) {
              let tradeHistoriesWithReferralData = await getReferralDataForTradeHistories(
                authToken,
                tradeHistoriesWithReferral
              );
              if (!tradeHistoriesWithReferralData) {
                throw new Error("Couldn't load data for referrals");
              }
              tradeHistoriesWithReferralData = tradeHistoriesWithReferralData.map(
                referral => ({ ...referral, date: formatDate(referral.date) })
              );
              setItems(tradeHistoriesWithReferralData);
              if (Number.isInteger(totalItemsCount) && totalItemsCount >= 0) {
                setTotalItemsCount(totalItemsCount);
                const newMaxPage = Math.ceil(totalItemsCount / limit) || 1;
                setMaxPage(newMaxPage);
                if (newMaxPage && currentPage && currentPage > newMaxPage) {
                  handlePaginationChange(null, newMaxPage);
                }
              }
            }
          })
          .catch(error => {
            console.log(error);
            setItems(null);
            showToast('error', "Couldn't load referrals");
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [authToken, currentPage, limit, status, category, getLocationPath()]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryPage = Number(queryParams.get('page'));
    if (queryPage && queryPage > 0) {
      setCurrentPage(queryPage);
    } else {
      const params = new URLSearchParams({ page: 1 });
      history.replace({
        pathname: location.pathname,
        search: params.toString(),
      });
      setCurrentPage(1);
    }
  }, [location.search]);

  const isAdmin = user?.role_id === USER_ROLES.ADMIN;

  return (
    <div className={styles.Dashboard}>
      <Header border />
      <div className={styles.content}>
        <div className={styles.sidebar}>
          <div className={styles.sidebarTitle}>Filters</div>
          <div className={styles.filterList}>
            <StatusFilter />
            <CategoriesFilter />
            <SellerFinanceFilter />
          </div>
        </div>
        <div className={styles.list}>
          {getLocationPath() === 'referral-system' ? (
            <div className={styles.title}>Referral System</div>
          ) : (
            ''
          )}
          <div className={styles.filterHeader}>
            <ExploreFilterHeader loading={!items} showSortBy={false} />
            {isAdmin && (
              <div
                className={styles.csvReport}
                onClick={() => dispatch(ModalActions.showCsvReportModal())}
              >
                Download .csv Report
              </div>
            )}
          </div>
          {!isLoading && items ? (
            items.map((item, index) => pageItem({ ...item, key: index }))
          ) : (
            <div className="m-5" align="center">
              <ClipLoader color="#000" size={64} />
            </div>
          )}

          {!isLoading && totalItemsCount > limit ? (
            <div className={styles.paginationContainer}>
              <Pagination
                page={currentPage}
                count={maxPage}
                variant="outlined"
                shape="rounded"
                onChange={handlePaginationChange}
              />
            </div>
          ) : null}
        </div>
      </div>
      <Footer />
      <CsvReportlModal token={authToken} />
    </div>
  );
};

export default Dashboard;
