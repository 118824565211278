import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {
  SortByOptions,
  FilterStatusFullNameMap,
} from 'constants/filter.constants';
import FilterActions from 'actions/filter.actions';
import './styles.css';
import { ArrowIcon } from '../../../../components/icons';
import closeIcon from '../../../../assets/svgs/close_icon.svg';

const ExploreFilterHeader = ({ showSortBy = true }) => {
  const dispatch = useDispatch();

  const { sortBy, status, category } = useSelector(state => state.Filter);

  const handleSortByChange = e => {
    const newSortBy = e.target.value;
    dispatch(FilterActions.updateSortByFilter(newSortBy));
  };

  const handleClearCategoryFilter = () => {
    dispatch(FilterActions.updateCategoryFilter(null));
  };

  const handleClearStatusFilter = () => {
    dispatch(FilterActions.resetStatusFilter());
  };

  const handleClearAllFilters = () => {
    handleClearCategoryFilter();
    handleClearStatusFilter();
  };

  const formatStatus = status => {
    if (FilterStatusFullNameMap.has(status)) {
      return FilterStatusFullNameMap.get(status);
    }
    return status;
  };

  return (
    <div className="filterHeaderRoot">
      <div className="filterHeaderLeft">
        {status ? (
          <div className="filterCollectionItem">
            <span className="filterCollectionItemName">
              {formatStatus(status)}
            </span>
            <img
              src={closeIcon}
              alt="close"
              className="filterCollectionRemoveItem"
              onClick={() => handleClearStatusFilter()}
            />
          </div>
        ) : null}
        {category ? (
          <div className="filterCollectionItem">
            <span className="filterCollectionItemName">{category?.label}</span>
            <img
              src={closeIcon}
              alt="close"
              className="filterCollectionRemoveItem"
              onClick={() => handleClearCategoryFilter()}
            />
          </div>
        ) : null}
        {status || category ? (
          <div
            className="filterClearAllFiltersLabel"
            onClick={() => handleClearAllFilters()}
          >
            Clear All Filters
          </div>
        ) : null}
      </div>
      {showSortBy ? (
        <div className="filterSelectGroup">
          <div className="filterSelectGroupLabel">Sort by:</div>
          <FormControl className="filterHeaderFormControl">
            <Select
              value={sortBy}
              onChange={handleSortByChange}
              className="selectBox"
              classes={{
                select: 'selectInner',
                selectMenu: 'selectMenu',
                icon: 'selectIcon',
                iconOpen: 'selectIconOpen',
              }}
              MenuProps={{
                classes: {
                  paper: 'menuPropsPaper',
                  list: 'menuItemList',
                },
              }}
              IconComponent={ArrowIcon}
            >
              {SortByOptions.map((option, idx) => {
                return (
                  <MenuItem
                    value={option.id}
                    key={idx}
                    className="menuItem"
                    classes={{ selected: 'menuItemSelected ' }}
                  >
                    {option.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      ) : null}
    </div>
  );
};

export default ExploreFilterHeader;
