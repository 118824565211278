import React, { useEffect, useState, useCallback } from 'react';
import { ClipLoader } from 'react-spinners';

import { useApi } from 'api';

import Modal from '../Modal';
import styles from '../Modal/common.module.scss';
import showToast from '../../utils/toast';
import { useSelector } from 'react-redux';

const DenyNFTModal = ({ visible, onClose, address, tokenID, onDeny }) => {
  const { rejectNft, rejectNftGaia } = useApi();
  const { authToken } = useSelector(state => state.Auth);

  const [sendingRequest, setSendingRequest] = useState(false);
  const [denyReason, setDenyReason] = useState('');
  const rejectCallback = useCallback(() => {
    if (address) {
      return rejectNft;
    } else {
      return rejectNftGaia;
    }
  }, [address]);
  useEffect(() => {
    if (visible) {
      setSendingRequest(false);
    }
  }, [visible]);

  const handleRejectNFT = async () => {
    if (!denyReason) {
      showToast('error', 'Please enter deny reason');
    } else {
      setSendingRequest(true);
      try {
        const rejectFunction = rejectCallback();
        const { request: response, error } = await rejectFunction(
          authToken,
          address,
          tokenID,
          denyReason
        );
        if (error) {
          showToast('error', error);
        } else if (response) {
          const parsedResponse = JSON.parse(response.response);
          onDeny();
          onClose(parsedResponse);
          showToast('success', 'NFT status changed!');
        }
      } catch (e) {
        console.log(e);
      }
      setSendingRequest(false);
    }
  };

  return (
    <Modal
      visible={visible}
      title="Deny NFT"
      onClose={onClose}
      submitDisabled={sendingRequest}
      submitLabel={
        sendingRequest ? <ClipLoader color="#FFF" size={16} /> : 'Deny'
      }
      onSubmit={!sendingRequest ? () => handleRejectNFT() : null}
      isSubmitDeny={true}
    >
      <div className={styles.formGroup}>
        <div className={styles.formLabel}>Deny Reason</div>
        <div className={styles.formInputCont}>
          <input
            className={styles.formInput}
            placeholder="Deny Reason"
            value={denyReason}
            onChange={e => setDenyReason(e.target.value)}
            disabled={sendingRequest}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DenyNFTModal;
