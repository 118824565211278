import React from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import pdfPreviewIcon from 'assets/svgs/prfPreviewIcon.svg';
import { useApi } from 'api';
import { DeleteOutline } from '@material-ui/icons';

const DocumentList = ({ documents, setDocuments, updateMode }) => {
  const { authToken } = useSelector(state => state.Auth);
  const { deleteFile } = useApi();
  const removeDocument = async id => {
    setDocuments(prevState => {
      const dublicate = [...prevState];
      const removingIndex = dublicate.findIndex(el => el.assetId === id);
      dublicate.splice(removingIndex, 1);
      return dublicate;
    });
    if (!updateMode) {
      await deleteFile(authToken, id);
    }
  };
  return documents.map(doc => {
    return (
      <div className={styles.registryLabel} key={doc.assetId}>
        <div className={styles.deleteIcon}>
          <DeleteOutline fontSize="large" color="secondary" />
        </div>
        <img
          src={pdfPreviewIcon}
          className={styles.documentIcon}
          onClick={() => removeDocument(doc.assetId)}
        />
        <p className={styles.smallLabel}>{doc.url}</p>
      </div>
    );
  });
};

export default DocumentList;
