import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { ClipLoader } from 'react-spinners';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import HeaderActions from 'actions/header.actions';
import Header from 'components/header';
import { useApi } from 'api';
import toast from 'utils/toast';

import styles from './styles.module.scss';
import FilterActions from '../../actions/filter.actions';

const selfSettings = [
  {
    value: 'sNftAuctionPrice',
    title: 'Bid price updated',
    description: 'When someone beat your bid price.',
  },
  {
    value: 'sNftBidToAuction',
    title: 'A bid to your NFT auction',
    description: 'You get a bid to your auction.',
  },
  {
    value: 'sAuctionWin',
    title: 'Auction Won',
    description: 'When you win an NFT auction.',
  },
  {
    value: 'sNftSell',
    title: 'NFT Sold',
    description: 'Your NFT is sold.',
  },
  {
    value: 'sNftBuy',
    title: 'NFT Purchased',
    description: 'You purchased a new NFT.',
  },
  {
    value: 'sNftOffer',
    title: 'Offer Received',
    description: 'When you receive an offer for your NFT.',
  },
  {
    value: 'sNftOfferCancel',
    title: 'Offer Cancelled',
    description: 'When an offer you receive is canceled.',
  },
];

const followerSettings = [
  {
    value: 'fNftAuctionPrice',
    title: 'NFT Auction Price update by follower',
    description: 'Updated auction price.',
  },
  {
    value: 'fNftList',
    title: 'NFT Listing by follower',
    description: 'Listed an NFT for sale.',
  },
  {
    value: 'fNftAuction',
    title: 'New NFT Auction',
    description: 'Started a new auction.',
  },
  {
    value: 'fNftPrice',
    title: 'NFT Price Update by follower',
    description: 'Updated NFT price on sale.',
  },
];

const DEFAULT_SETTINGS = {
  sNotification: false,
  fNotification: false,
};
const addNotificationValue = (arr, obj) => {
  arr.forEach(el => (obj[el.value] = true));
};
addNotificationValue(followerSettings, DEFAULT_SETTINGS);
addNotificationValue(selfSettings, DEFAULT_SETTINGS);

const CustomCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#7F56D9',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const SettingOption = ({
  name,
  title,
  description,
  checked,
  disabled,
  onChange,
}) => (
  <FormControlLabel
    classes={{ root: styles.optionPanel }}
    control={
      <CustomCheckbox
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        name={name}
      />
    }
    label={
      <div className={styles.option}>
        <div className={styles.optionTitle}>{title}</div>
        <div className={styles.optionDesc}>{description}</div>
      </div>
    }
  />
);

const NotificationSetting = () => {
  const dispatch = useDispatch();

  const { getNotificationSettings, updateNotificationSettings } = useApi();
  const { authToken } = useSelector(state => state.Auth);

  const [saving, setSaving] = useState(false);
  const [settings, setSettings] = useState({});

  const getSettings = async () => {
    const notificationSettings = await getNotificationSettings(authToken);
    const data = notificationSettings?.data;
    if (data) {
      setSettings(data);
    } else {
      setSettings(DEFAULT_SETTINGS);
    }
  };

  useEffect(() => {
    dispatch(HeaderActions.toggleSearchbar(true));
    dispatch(FilterActions.updateCategoryFilter(null));
    dispatch(FilterActions.resetStatusFilter());
  }, []);

  useEffect(() => {
    if (authToken) {
      getSettings();
    }
  }, [authToken]);

  const handleChange = e => {
    const key = e.target.name;
    const newSettings = { ...settings };
    newSettings[key] = !settings[key];
    setSettings(newSettings);
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      const { status } = await updateNotificationSettings(settings, authToken);
      if (status === 'success') {
        toast('success', 'Notification settings updated!');
      }
    } catch (err) {
      console.log(err);
    }
    setSaving(false);
  };

  const getSetting = key =>
    settings[key] === undefined ? true : settings[key];

  return (
    <div className={styles.container}>
      <Header border />
      <div className={styles.inner}>
        <div className={styles.title}>Notification Settings</div>

        <div className={styles.body}>
          <div className={styles.group}>
            <FormControlLabel
              className={cx(styles.formControl, styles.selected)}
              classes={{ label: styles.groupTitle }}
              control={
                <CustomCheckbox
                  checked={getSetting('sNotification')}
                  onChange={handleChange}
                  name="sNotification"
                />
              }
              label="Your Activity Notifications"
            />
            <div className={styles.groupOptions}>
              {selfSettings.map((option, idx) => (
                <SettingOption
                  key={idx}
                  name={option.value}
                  title={option.title}
                  description={option.description}
                  disabled={!getSetting('sNotification')}
                  checked={
                    getSetting('sNotification') && getSetting(option.value)
                  }
                  onChange={handleChange}
                />
              ))}
            </div>
          </div>

          <div className={styles.group}>
            <FormControlLabel
              className={cx(styles.formControl, styles.selected)}
              classes={{ label: styles.groupTitle }}
              control={
                <CustomCheckbox
                  checked={getSetting('fNotification')}
                  onChange={handleChange}
                  name="fNotification"
                />
              }
              label="Follower Activity Notifications"
            />
            <div className={styles.groupOptions}>
              {followerSettings.map((option, idx) => (
                <SettingOption
                  key={idx}
                  name={option.value}
                  title={option.title}
                  description={option.description}
                  disabled={!getSetting('fNotification')}
                  checked={
                    getSetting('fNotification') && getSetting(option.value)
                  }
                  onChange={handleChange}
                />
              ))}
            </div>
          </div>
        </div>

        <div className={styles.buttonsWrapper}>
          <div
            className={cx(styles.createButton, saving && styles.disabled)}
            onClick={!saving ? handleSave : null}
          >
            {saving ? <ClipLoader color="#FFF" size={16} /> : 'Save Settings'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationSetting;
