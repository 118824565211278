import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';

import persistStore from './persistStore';
import persistReducer from './persistReducer';

const loggerMiddleware = createLogger();

export default (initialState = {}) => {
  const store = createStore(
    persistReducer(),
    initialState,
    compose(applyMiddleware(thunkMiddleware, loggerMiddleware))
  );

  store.asyncReducers = {};

  const persistor = persistStore(store);

  return { store, persistor };
};
