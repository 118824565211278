import React, { useState, useEffect, useRef } from 'react';
import Header from 'components/header';
import styles from './../styles.module.scss';
import cx from 'classnames';
import { ClipLoader } from 'react-spinners';
import { useHistory } from 'react-router';
import { useApi } from 'api';
import { useDispatch } from 'react-redux';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import closeIcon from 'assets/svgs/v2/close.svg';
import AuthActions from 'actions/auth.actions';
import { useWeb3React } from '@web3-react/core';
import WalletConnectActions from 'actions/walletconnect.actions';
import toast from 'utils/toast';
import Background from '../../../components/Background';
import Footer from '../../../components/Footer';
import IconEye from '../../../assets/svgs/eye_icon.svg';
import IconCloseEye from '../../../assets/svgs/eye_close_icon.svg';
import { EMAIL_REGEX } from '../../../constants/regex.constants';
import { MIN_PASSWORD_LENGTH } from '../../../constants/auth.constants';

const SignIn = ({ isRegister }) => {
  const { postLogin, check2faCode } = useApi();
  const dispatch = useDispatch();

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const passwordRef = useRef(null);
  const [loginError, setLoginError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [signingIn, setSigningIn] = useState(false);
  const [showPassword, setShowPassword] = useState(undefined);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const loginButton = useRef(null);
  const confirmButton = useRef(null);
  const [isCheck2fa, setIsCheck2fa] = useState(false);
  const [twoFACode, setwoFACode] = useState('');
  const authToken = useRef('');
  const userStorage = useRef('');

  const onClose = () => {
    const queryParams = new URLSearchParams(location.search);
    const redirectToURL = queryParams.get('redirect_to');
    if (redirectToURL) {
      window.scrollTo(0, 0);
      history.replace(redirectToURL);
    } else {
      window.scrollTo(0, 0);
      history.replace('/');
    }
  };

  const validateLogin = login => {
    if (login.length === 0) {
      setLoginError("Email field can't be blank");
    } else if (!EMAIL_REGEX.test(login)) {
      setLoginError('Please enter a valid email');
    } else {
      setLoginError(null);
    }
  };

  useEffect(() => {
    validateLogin(login);
  }, [login]);

  const validatePassword = password => {
    if (password.length === 0) {
      setPasswordError("Password field can't be blank");
    } else if (password.length < MIN_PASSWORD_LENGTH) {
      setPasswordError(
        `Password should have ${MIN_PASSWORD_LENGTH} characters at least`
      );
    } else {
      setPasswordError(null);
    }
  };

  useEffect(() => {
    validatePassword(password);
  }, [password]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (showPassword !== undefined) {
      passwordRef.current.focus();
      passwordRef.current.selectionStart = passwordRef.current.value.length;
      passwordRef.current.selectionEnd = passwordRef.current.value.length;
    }
  }, [showPassword]);

  const handleMouseDownPassword = () => {
    event.preventDefault();
  };

  const redirectNext = () => {
    const queryParams = new URLSearchParams(location.search);
    const redirectToURL = queryParams.get('redirect_to');
    if (redirectToURL) {
      window.scrollTo(0, 0);
      history.replace(redirectToURL);
    } else {
      window.scrollTo(0, 0);
      history.replace('/');
    }
  };

  const isValid = !!login && !loginError && !!password && !passwordError;

  const handleSignIn = async () => {
    if (!isValid) return;
    if (signingIn) return;
    try {
      const { message, token, user } = await postLogin(login, password);
      authToken.current = token;
      userStorage.current = user;
      if (message == 'OK') {
        window.scrollTo(0, 0);
        console.log('Success: ', token, user);
        if (
          account &&
          user.user_wallets.length &&
          account.toLocaleLowerCase() !== user.user_wallets[0].address
        ) {
          dispatch(WalletConnectActions.wrongWalletError());
        }
      }
      if (!user.is_2fa) {
        dispatch(AuthActions.signIn(user, token));
        history.replace('/profile');
      } else {
        setIsCheck2fa(true);
      }
    } catch (error) {
      toast('error', error?.message || 'Failed to login');
    }
    setSigningIn(false);
  };

  const handleCheck2fa = async () => {
    const workToken = await check2faCode(authToken.current, twoFACode);
    if (workToken.error) {
      toast('error', workToken.error);
      return;
    } else {
      dispatch(AuthActions.signIn(userStorage.current, workToken));
    }
    redirectNext();
  };

  const handleForgotPassword = () => {
    window.scrollTo(0, 0);
    history.replace('/auth/reset-password');
  };
  const handleSignUp = () => {
    window.scrollTo(0, 0);
    history.replace('/auth/sign-up');
  };
  const { account } = useWeb3React();

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 0);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isRegister) {
      window.scrollTo(0, 0);
      history.replace('/');
    }
    setLogin('');
    setPassword('');
    setLoginError(null);
    setPasswordError(null);
  }, [isRegister]);

  useEffect(() => {
    const listener = event => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        if (isCheck2fa) {
          confirmButton.current.click();
        } else {
          loginButton.current.click();
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [isCheck2fa]);

  return (
    <div className={styles.outerContainer}>
      <div className={styles.authContainer}>
        <Background />
        <Header border />
        <div className={cx(styles.inner, styles.inner600)}>
          <form className={styles.authForm}>
            <div className={styles.closeButton} onClick={onClose}>
              <img src={closeIcon} />
            </div>
            {isCheck2fa ? (
              <>
                <div className={styles.title}>2FA authentication</div>
                <p className={styles.subTitle}>
                  Thanks for keeping your account secure.
                  <br />
                  Enter you 2FA code
                </p>
                <div className={styles.codeInputContainer}>
                  <input
                    className={cx(styles.twoFAInput)}
                    type="number"
                    min="0"
                    max="999999"
                    placeholder="000000"
                    onChange={e => {
                      if (e.target.value?.length <= 6)
                        setwoFACode(e.target.value);
                    }}
                    value={twoFACode}
                  />
                </div>
                <div className={styles.buttonsWrapper}>
                  <div
                    className={cx(styles.submitButton)}
                    onClick={handleCheck2fa}
                    ref={confirmButton}
                  >
                    Confirm
                  </div>
                  <div
                    className={cx(styles.labelLink, styles.cancelLink)}
                    onClick={onClose}
                  >
                    Cancel
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={styles.title}>Log In</div>
                <div className={styles.inputGroup}>
                  <div className={styles.inputWrapper}>
                    <OutlinedInput
                      className={cx(
                        styles.input,
                        loginError && styles.hasError
                      )}
                      placeholder="Email"
                      value={login}
                      onChange={e => setLogin(e.target.value)}
                    />
                    {loginError && (
                      <div className={styles.error}>{loginError}</div>
                    )}
                  </div>
                </div>
                <div className={styles.inputGroup}>
                  <div className={styles.inputWrapper}>
                    <OutlinedInput
                      className={cx(
                        styles.input,
                        styles.password,
                        passwordError && styles.hasError
                      )}
                      placeholder="Password"
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      inputRef={passwordRef}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <img src={IconCloseEye} alt={'Hide password'} />
                            ) : (
                              <img src={IconEye} alt={'Show password'} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {passwordError && (
                      <div className={styles.error}>{passwordError}</div>
                    )}
                  </div>
                </div>
                <div
                  className={styles.forgotPasswordLink}
                  onClick={handleForgotPassword}
                >
                  Forgot password?
                </div>
                <div className={styles.buttonsWrapper}>
                  <div
                    className={cx(
                      styles.submitButton,
                      !isValid && styles.disabled
                    )}
                    onClick={handleSignIn}
                    ref={loginButton}
                  >
                    {signingIn ? (
                      <ClipLoader color="#FFF" size={16} />
                    ) : (
                      'Log In'
                    )}
                  </div>
                  <div className={styles.formLabelRow}>
                    <div className={styles.label}>
                      Don&apos;t have an account?
                    </div>
                    <div className={styles.labelLink} onClick={handleSignUp}>
                      Sign Up
                    </div>
                  </div>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default SignIn;
