import React, { useState } from 'react';

import styles from './styles.module.scss';
import logo from 'assets/svgs/logo.svg';
import cx from 'classnames';
import IconTwitter from '../../assets/icons/iconTwitter';
import { EMAIL_REGEX } from '../../constants/regex.constants';
import toast from '../../utils/toast';
import { useApi } from '../../api';
import { ClipLoader } from 'react-spinners';

const validateEmail = email => {
  if (email.length === 0) {
    return "Email field can't be blank";
  } else if (!EMAIL_REGEX.test(email)) {
    return 'Please enter a valid email';
  }

  return null;
};

const Footer = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [isSubscribing, setIsSubscribing] = useState(false);

  const { subscribeByEmail } = useApi();

  const handleSubscribe = async () => {
    if (isSubscribing) return;

    setIsSubscribing(true);
    try {
      const error = validateEmail(email);
      if (error) {
        setEmailError(error);
        return;
      }

      const subscribeResponse = await subscribeByEmail(email);
      if (subscribeResponse?.error) {
        throw new Error(subscribeResponse.error);
      }

      setEmail('');
      toast('success', 'Thanks for subscribing!');
    } catch (error) {
      toast('error', error?.message || 'Failed to subscribe');
    } finally {
      setIsSubscribing(false);
    }
  };

  const onEmailChange = event => {
    setEmail(event.target.value);
    setEmailError(null);
  };

  return (
    <div className={styles.footer}>
      <div className={styles.footerContainer}>
        <div>
          <div className={styles.footerItem}>
            <a
              href="https://www.liquidearth.io/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.logoLink}
            >
              <img src={logo} alt="logo" className={styles.logo} />
            </a>
          </div>
          <div className={styles.footerItem}>
            LiquidEarth US, LLC <br /> 8690 Biscayne Blvd <br /> Miami, FL 30331
          </div>
          <div className={styles.footerItem}>
            Valid Tradable Real Estate NFTs.
          </div>
        </div>
        <div>
          <div className={styles.formGroup}>
            <div className={styles.formLabel}>Stay up to date</div>
            <div className={styles.formInputCont}>
              <input
                className={cx(
                  styles.formInput,
                  !!emailError && styles.formInputError
                )}
                placeholder="Enter your email"
                value={email}
                onChange={onEmailChange}
              />
              <div
                className={cx(
                  styles.subscribeButton,
                  isSubscribing && styles.subscribeButtonDisabled
                )}
                onClick={handleSubscribe}
              >
                {isSubscribing ? (
                  <ClipLoader color="FFFFFF" size={16} />
                ) : (
                  'Subscribe'
                )}
              </div>
            </div>
            {emailError && <div className={styles.error}>{emailError}</div>}
          </div>
          <div className={cx(styles.footerItem, styles.rightCornerWrapper)}>
            <a
              href="https://www.liquidearth.io/terms"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.textLink}
            >
              Terms of Use
            </a>
            <a
              href="https://www.liquidearth.io/privacy"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.textLink}
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
      <div className={styles.footerLine} />
      <div className={styles.footerContainer}>
        <div>© 2023 Gaia Labs LTD. All rights reserved.</div>
        <div>
          <a
            href="https://twitter.com/liquidearthnft"
            target="_blank"
            rel="noreferrer"
            className={cx(styles.socialIcon, styles.socialIconTwitter)}
          >
            <IconTwitter />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
