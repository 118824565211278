import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getGeocode, getLatLng } from 'use-places-autocomplete';

import showToast from 'utils/toast';
import { USER_ROLES } from 'constants/userRoles.constants';
import { useApi } from 'api';

export const useMaps = ({ geoPosition, nftAdress, nftUserId, nftId }) => {
  const { isLoaded } = useSelector(state => state.Maps);
  const { user, authToken } = useSelector(state => state.Auth);

  const [markerPosition, setMarkerPosition] = useState({
    lat: 48.354314,
    lng: -99.997963,
  });

  useEffect(() => {
    const { updateGeoData } = useApi();
    const fetchPosition = async (description, updatedNftId) => {
      try {
        const results = await getGeocode({ address: description });
        const { lat, lng } = getLatLng(results[0]);
        const resp = await updateGeoData(authToken, updatedNftId, { lat, lng });
        if (!resp.error) {
          setMarkerPosition({ lat: resp?.lat, lng: resp?.lng });
        } else {
          showToast('error', resp.error);
        }
      } catch (error) {
        showToast('error', error.message || error);
      }
    };

    const isAbleUser =
      user?.role_id === USER_ROLES.ADMIN ||
      user?.role_id === USER_ROLES.ESCROW_AGENT ||
      nftUserId === user.user_id;

    if (isLoaded && !geoPosition && !!nftAdress & isAbleUser) {
      fetchPosition(nftAdress, nftId);
    } else if (geoPosition) {
      setMarkerPosition({
        lat: geoPosition?.lat || 43.45,
        lng: geoPosition?.lng || -80.49,
      });
    }
  }, [isLoaded, nftAdress, geoPosition, nftUserId, user.user_id, nftId]);

  return { markerPosition, isLoaded };
};
