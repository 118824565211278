import React from 'react';
import toast from 'react-hot-toast';

import iconInfo from 'assets/svgs/info.svg';
import iconSuccess from 'assets/svgs/success.svg';
import iconError from 'assets/svgs/error.svg';
import iconWarning from 'assets/svgs/warning.svg';

import styles from './styles.module.scss';
import closeIcon from '../../assets/svgs/v2/close.svg';
import cx from 'classnames';

const icons = {
  info: iconInfo,
  success: iconSuccess,
  error: iconError,
  warning: iconWarning,
};

export default (
  type,
  title,
  body = '',
  onClick = null,
  showCloseIcon = false,
  messageDuration = 5000,
  bodyIsLink = false,
  durationIsInfinite = false
) => {
  return toast(
    t => (
      <div
        className={styles.toastInner}
        onClick={
          onClick
            ? onClick
            : !showCloseIcon
            ? () => {
                toast.remove(t.id);
              }
            : () => {}
        }
      >
        <div className={styles.header}>
          {showCloseIcon ? (
            <div
              className={styles.closeButton}
              onClick={() => {
                toast.remove(t.id);
              }}
            >
              <img src={closeIcon} />
            </div>
          ) : null}
          <img src={icons[type]} alt={type} className={styles.icon} />
          <span>{title}</span>
        </div>
        {body &&
        ((typeof body === 'string' && body.length > 0) ||
          typeof body === 'object') ? (
          <div className={styles.body}>
            {bodyIsLink ? (
              <>
                View your transaction{' '}
                <a
                  className={styles.bodyLink}
                  href={body}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
              </>
            ) : (
              body
            )}
          </div>
        ) : null}
      </div>
    ),
    {
      ...(durationIsInfinite
        ? { duration: 2 ** 31 - 1 }
        : { duration: messageDuration }),
      className: showCloseIcon
        ? cx(styles.toast, styles.toastWithCloseIcon)
        : styles.toast,
    }
  );
};
