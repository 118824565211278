import MapsConstant from '../constants/maps.constants';

const MapsActions = { setMapsLoadState };

function setMapsLoadState(isLoaded) {
  return dispatch => {
    dispatch(_setMapsLoadState(isLoaded));
  };
}

const _setMapsLoadState = isLoaded => {
  return {
    type: MapsConstant.SET_MAPS_LOAD_STATE,
    isLoaded,
  };
};

export default MapsActions;
