import React from 'react';
import cx from 'classnames';

import closeIcon from 'assets/svgs/v2/close.svg';

import styles from './styles.module.scss';

const Modal = ({
  visible,
  title,
  onClose,
  children,
  submitDisabled,
  submitLabel,
  isSubmitDeny = false,
  onSubmit,
  small,
  showTitle = true,
  showCloseIcon = true,
}) => {
  return (
    <div className={cx(styles.container, visible ? styles.visible : null)}>
      <div className={cx(styles.modal, small && styles.small)}>
        <div
          className={
            showTitle
              ? styles.header
              : cx(styles.header, styles.headerWithoutTitle)
          }
        >
          {showTitle ? <div className={styles.title}>{title}</div> : null}
          {showCloseIcon ? (
            <div className={styles.closeButton} onClick={onClose}>
              <img src={closeIcon} />
            </div>
          ) : null}
        </div>
        <div className={showTitle ? styles.body : styles.bodyWithoutTitle}>
          {children}
        </div>
        {submitLabel && (
          <div className={styles.footer}>
            <div
              className={
                isSubmitDeny
                  ? cx(
                      styles.submitButton,
                      submitDisabled && styles.disabled,
                      styles.submitDenyButton
                    )
                  : cx(styles.submitButton, submitDisabled && styles.disabled)
              }
              onClick={onSubmit}
            >
              {submitLabel}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
