import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
// import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import 'react-alice-carousel/lib/alice-carousel.css';
import styles from './styles.module.scss';
import ModalActions from '../../actions/modal.actions';
import closeIcon from '../../assets/svgs/close_opacity.svg';
import { CalendarIcon } from '../icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useApi } from 'api';
import showToast from 'utils/toast';

const AlphaBackdrop = withStyles({
  root: {
    backgroundColor: 'rgba(35, 35, 52, 0.9)',
  },
})(Backdrop);

const CustomDatePiker = withStyles({
  root: {
    backgroundColor: 'white',
    borderRadius: '12px',
  },
})(KeyboardDatePicker);

const CsvReportlModal = authToken => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isOpenStart, setIsOpenStart] = useState(false);
  const [isOpenEnd, setIsOpenEnd] = useState(false);

  const dispatch = useDispatch();
  const { donwloadCsvReport } = useApi();

  const { csvReportModalVisible } = useSelector(state => state.Modal);

  const handleCsvReportlModal = () => {
    dispatch(ModalActions.hideCsvReportModal());
  };

  const downloadCsvReportHandler = async () => {
    try {
      const result = await donwloadCsvReport(authToken, startDate, endDate);
      if (!result) {
        showToast('error', "Couldn't download report");
        return;
      } else if (result.error) {
        showToast('error', result.error);
        return;
      }
      const report = new Blob([result], { type: 'text/plain' });
      if (window.showSaveFilePicker) {
        const opts = {
          suggestedName: 'NFT report.csv',
          types: [
            {
              accept: { 'text/plain': ['.txt'] },
            },
          ],
        };
        const fileHandle = await window.showSaveFilePicker(opts);
        const fileStream = await fileHandle.createWritable();
        await fileStream.write(report);
        await fileStream.close();
      } else {
        const url = window.URL.createObjectURL(report);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'NFT report.csv';
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      }

      showToast('success', 'Report was downloaded');
    } catch (error) {
      showToast('error', "Couldn't download report");
      console.log(error);
    } finally {
      dispatch(ModalActions.hideCsvReportModal());
    }
  };

  return (
    <Modal
      className={styles.modalPopup}
      aria-labelledby="csv-report-carousel"
      open={csvReportModalVisible}
      // onClose={handleCsvReportlModal}
      BackdropComponent={AlphaBackdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={csvReportModalVisible}>
        <div className={styles.modalContainer} id="csv-report-carousel">
          <div
            className={styles.closeIconContainer}
            onClick={handleCsvReportlModal}
          >
            <img className={styles.closeIcon} src={closeIcon} />
          </div>
          <p className={styles.mainLabel}>Download .csv Report</p>
          <p className={styles.subLabel}>Please choose report period</p>
          <div className={styles.dateSelector}>
            <CustomDatePiker
              className={styles.customDatePiker}
              placeholder="Stard date"
              value={startDate}
              onChange={date => setStartDate(date)}
              variant="inline"
              inputVariant="outlined"
              keyboardIcon={<CalendarIcon />}
              format="dd/MM/yyyy"
              open={isOpenStart}
              onOpen={() => setIsOpenStart(true)}
              onClose={() => setIsOpenStart(false)}
              onAccept={() => setIsOpenStart(false)}
              onClick={() => setIsOpenStart(true)}
            />
            <CustomDatePiker
              className={styles.customDatePiker}
              placeholder="End date"
              value={endDate}
              onChange={date => setEndDate(date)}
              variant="inline"
              inputVariant="outlined"
              keyboardIcon={<CalendarIcon />}
              format="dd/MM/yyyy"
              open={isOpenEnd}
              onOpen={() => setIsOpenEnd(true)}
              onClose={() => setIsOpenEnd(false)}
              onAccept={() => setIsOpenEnd(false)}
              onClick={() => setIsOpenEnd(true)}
            />
          </div>
          <div
            className={styles.downloadButton}
            onClick={downloadCsvReportHandler}
          >
            Download
          </div>
          <div className={styles.cancelButton} onClick={handleCsvReportlModal}>
            Cancel
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default CsvReportlModal;
