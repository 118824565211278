import React from 'react';
import styles from './styles.module.scss';
import LogoCNFT from 'assets/icons/logoCNFT';

const Background = () => {
  return (
    <div className={styles.Background}>
      <div className={styles.bg1}>
        <LogoCNFT />
      </div>
      <div className={styles.bg2}>
        <LogoCNFT />
      </div>
    </div>
  );
};

export default Background;
