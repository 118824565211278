import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import FilterWrapper from 'components/FilterWrapper';

import sellerFinanceIcon from 'assets/svgs/seller_finance_icon.svg';

import './styles.scss';

const useStyles = makeStyles(() => ({
  body: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '0',
  },
}));

const SellerFinanceFilter = () => {
  const classes = useStyles();

  return (
    <FilterWrapper
      title="Seller Finance"
      icon={sellerFinanceIcon}
      classes={{ body: classes.body }}
      isComingSoon={true}
    >
      <div></div>
    </FilterWrapper>
  );
};

export default SellerFinanceFilter;
