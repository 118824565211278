export const NFT_STATUSES = {
  PENDING_APPROVAL: 'Pending approval',
  APPROVED_BY_AGENT_AND_ADMIN: 'Approved by Admin',
  APPROVED_BY_AGENT_AND_DENIED_BY_ADMIN: 'Denied by Admin',
  MINTED: 'Minted',
  APPROVED_BY_AGENT: 'Approved by Agent',
  DENIED_BY_AGENT: 'Denied by Agent',
  PREMINT: 'PreMint',
  MINING_PENDING: 'Pending Mint',
  PAYING_FEE_PENDING: 'Paying Fee Pending',
  APPROVE_MINTING_PENDING: 'Approve Minting Pending',
  BURNED: 'Burned',
  ERROR_MINTING_TX: 'Processing error',
};

export const NFT_STATUSES_CODES = {
  PENDING_APPROVAL: 1,
  APPROVED_BY_AGENT_AND_ADMIN: 2,
  APPROVED_BY_AGENT_AND_DENIED_BY_ADMIN: 3,
  MINTED: 4,
  APPROVED_BY_AGENT: 5,
  DENIED_BY_AGENT: 6,
  PREMINT: 7,
  MINING_PENDING: 8,
  PAYING_FEE_PENDING: 9,
  APPROVE_MINTING_PENDING: 10,
  BURNED: 11,
  ERROR_MINTING_TX: 12,
};
