import { ChainId } from '@sushiswap/sdk';

export const NETWORK_LABEL = {
  [ChainId.MAINNET]: 'Ethereum',
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan',
  [ChainId.FANTOM]: 'Fantom',
  [ChainId.FANTOM_TESTNET]: 'Fantom Testnet',
  [ChainId.MATIC]: 'Matic',
  [ChainId.MATIC_TESTNET]: 'Matic Testnet',
  [ChainId.XDAI]: 'xDai',
  [ChainId.BSC]: 'BSC',
  [ChainId.BSC_TESTNET]: 'BSC Testnet',
  [ChainId.MOONBASE]: 'Moonbase',
  [ChainId.AVALANCHE]: 'Avalanche',
  [ChainId.FUJI]: 'Fuji',
  [ChainId.HECO]: 'HECO',
  [ChainId.HECO_TESTNET]: 'HECO Testnet',
  [ChainId.HARMONY]: 'Harmony',
  [ChainId.HARMONY_TESTNET]: 'Harmony Testnet',
};

export const Contracts = {
  auction: process.env.REACT_APP_FANTOM_AUCTION,
  sales: process.env.REACT_APP_FANTOM_MARKETPLACE,
  bundleSales: process.env.REACT_APP_FANTOM_BUNDLE_MARKETPLACE,
  factory: process.env.REACT_APP_FANTOM_NFT_FACTORY, //FantomNFTFactory
  artFactory: process.env.REACT_APP_FANTOM_ART_FACTORY, //FantomArtFactory
  //privateFactory: process.env., //FantomNFTFactoryPrivate
  // privateArtFactory: process.env., //FantomArtFactoryPrivate
};
