import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import cx from 'classnames';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useApi } from 'api';
import iconUSDC from '../../assets/imgs/usdc.png';

import HeaderActions from 'actions/header.actions';
import FilterActions from 'actions/filter.actions';
import Header from 'components/header';
import Footer from 'components/Footer';

import card1 from 'assets/svgs/card1.svg';
import card2 from 'assets/svgs/card2.svg';
import card3 from 'assets/svgs/card3.svg';
import card4 from 'assets/svgs/card4.svg';

import card1Animation from 'assets/animations/card1_animation.json';
import card2Animation from 'assets/animations/card2_animation.json';
import card3Animation from 'assets/animations/card3_animation.json';
import card4Animation from 'assets/animations/card4_animation.json';

import styles from './styles.module.scss';

const cards = [
  {
    icon: card1,
    title: 'Legally Safe',
    description:
      'The first compliant, safe, and tradable Real Estate NFT finally exists.',
    path: '/',
    animationData: card1Animation,
  },
  {
    icon: card2,
    title: 'Easy to Use',
    description: 'A simple, intuitive, low cost trading platform.',
    path: '/',
    animationData: card2Animation,
  },
  {
    icon: card3,
    title: 'Buy Now',
    description: 'The first ever "Buy Now" for real estate.',
    path: '/',
    animationData: card3Animation,
  },
  {
    icon: card4,
    title: 'Earn Royalties',
    description:
      'Minters and brokers can earn indefinite royalties on each NFT Trade.',
    path: '/explore',
    animationData: card4Animation,
  },
];

const CATEGORIES_AMOUNT = 6;

const LandingPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getCategories } = useApi();
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    dispatch(HeaderActions.toggleSearchbar(true));
    dispatch(FilterActions.updateCategoryFilter(null));
    dispatch(FilterActions.resetStatusFilter());
  }, []);

  useEffect(() => {
    getCategories(CATEGORIES_AMOUNT, true).then(
      categoriesDto => {
        if (categoriesDto) {
          setCategories(categoriesDto);
        }
      },
      () => {}
    );
  }, []);

  const handleViewCategory = category => {
    dispatch(
      FilterActions.updateCategoryFilter(category === 'all' ? null : category)
    );
    history.push('/explore');
  };

  const renderAboutCard = (key, icon, title, desc, animationData) => (
    <div className={styles.aboutCard} key={key}>
      <div className={styles.cardIconWrapper}>
        <Lottie animationData={animationData} loop={true} />
      </div>
      <div className={styles.cardTitle}>{title}</div>
      <div className={styles.cardDesc}>{desc}</div>
    </div>
  );

  const renderCategoryCard = category => {
    return (
      <div
        className={styles.categoryCard}
        key={category.type}
        onClick={() =>
          handleViewCategory({
            id: category.type,
            label: category.label,
            address: category._id,
          })
        }
      >
        <div
          className={styles.categoryImage}
          style={{
            backgroundImage: `url(${category.icon})`,
          }}
        >
          {category.isComingSoon ? (
            <div className={styles.comingSoon}>Coming Soon</div>
          ) : null}
        </div>
        <div className={styles.cardLabelWrapper}>
          <div className={styles.cardLabel}>{category.label}</div>
          <div className={styles.browseBtn}>
            <ChevronRightIcon className={styles.browseBtnIcon} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.body}>
        <div className={styles.main}>
          <div className={styles.mainLeft}>
            <div className={styles.title}>Valid Tradable Real Estate NFTs</div>
            <div className={styles.subtitle}>
              Easily buy, sell, and leverage real estate as an NFT.
            </div>
            <div className={styles.buttonsContainer}>
              <Link
                to="/explore"
                className={cx(styles.button, styles.exploreButton)}
              >
                Explore
              </Link>
              <Link
                to="/create"
                className={cx(styles.button, styles.createButton)}
              >
                Create
              </Link>
            </div>
          </div>
          <Link to="/explore">
            <div className={styles.cardNFT}>
              <img
                src={
                  'https://files.slack.com/files-pri/T0MSLPLSG-F03GQCZ0TSL/house-1477041_1280.jpg?pub_secret=6e38d85f0f'
                }
                alt=""
                className={styles.imgNFT}
              />
              <div className={styles.borderLine}>
                <div className={styles.cardNFTInfo}>
                  <div className={styles.cardNFTName}>
                    Miami Modern Miami, FL
                  </div>
                  <span className={styles.cardNFTViewDetails}>
                    View Details
                  </span>
                </div>
                <div className={styles.price}>
                  <img src={iconUSDC} alt="" />
                  250,000
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className={styles.about}>
        <div className={styles.aboutInner}>
          <div className={styles.aboutTitle}>Why LiquidEarth?</div>
          <div className={styles.aboutCards}>
            {cards.map((card, key) =>
              renderAboutCard(
                key,
                card.icon,
                card.title,
                card.description,
                card.animationData
              )
            )}
          </div>
          {categories ? (
            <>
              <div className={styles.aboutTitle}>Browse by category</div>
              <div className={styles.categories}>
                {categories.map(category => renderCategoryCard(category))}
                <div
                  className={cx(styles.button, styles.exploreAllButton)}
                  onClick={() => handleViewCategory('all')}
                >
                  Explore All NFTs
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
