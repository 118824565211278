import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ProfileRouter({ component: Component, ...restOfProps }) {
  const { authToken } = useSelector(state => state.Auth);
  const location = useLocation();
  return (
    <Route
      {...restOfProps}
      render={props =>
        authToken ? (
          <Component {...props} />
        ) : (
          <Redirect to={`/auth/sign-in?redirect_to=${location.pathname}`} />
        )
      }
    />
  );
}

export default ProfileRouter;
