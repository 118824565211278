import React, { useState, useEffect } from 'react';
import Header from 'components/header';
import styles from './../styles.module.scss';
import cx from 'classnames';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useApi } from 'api';
import showToast from 'utils/toast';
import closeIcon from 'assets/svgs/v2/close.svg';
import letterOk from 'assets/svgs/v2/letter_ok.svg';
import letterError from 'assets/svgs/v2/letter_error.svg';
import Background from '../../../components/Background';
import Footer from '../../../components/Footer';

const ConfirmAccount = ({ isRegister }) => {
  const { postConfirmAccount, resendVerificationEmail } = useApi();
  const token = useParams();
  const [message, setMessage] = useState('');
  const [isResposed, setIsResposed] = useState(false);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(null);
  const [email, setEmail] = useState('');

  const onClose = () => {
    window.scrollTo(0, 0);
    history.push('/');
  };
  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 0);
    }
  }, [isLoading]);

  const handleConfirmAccount = async () => {
    window.scrollTo(0, 0);
    history.push('/');
  };
  const handleResend = async () => {
    if (!email) {
      showToast('error', 'Please enter your email');
    } else {
      setMessage('Sending ...');
      try {
        const { message } = await resendVerificationEmail(email);
        if (message == 'OK') {
          setMessage('New Confirmation Email Has Been Sent');
          setIsSuccess(true);
          setIsResposed(true);
        } else {
          if (message) {
            showToast('error', message);
          }
          setIsSuccess(false);
          setIsResposed(true);
          setMessage('Something went wrong');
        }
      } catch (e) {
        if (e?.response?.data?.error) {
          showToast('error', e.response.data.error);
        }
        setMessage('Something went wrong');
        setIsResposed(true);
      }
    }
  };
  useEffect(() => {
    setMessage('Checking ...');
    try {
      postConfirmAccount(token.token).then(
        response => {
          if (response.message === 'OK') {
            setMessage('Your Email Has Been Verified');
            setIsSuccess(true);
            setIsResposed(true);
          } else {
            setIsSuccess(false);
            setIsResposed(true);
            setMessage('Something went wrong');
          }
        },
        () => {
          setIsSuccess(false);
          setIsResposed(true);
          setMessage('Something went wrong');
        }
      );
    } catch (e) {
      setMessage('Something went wrong');
      setIsResposed(true);
    }
  }, [isRegister]);

  return (
    <div className={styles.outerContainer}>
      <div className={styles.authContainer}>
        <Background />
        <Header border />
        <div className={cx(styles.inner, styles.inner560)}>
          <form className={styles.authForm}>
            <div className={styles.closeButton} onClick={onClose}>
              <img src={closeIcon} />
            </div>
            <div className={styles.title}>{message}</div>
            {isResposed && (
              <>
                {!isSuccess ? (
                  <div className={styles.subtitle}>
                    Access expired. Please try again.
                  </div>
                ) : null}
                <div className={styles.confirmIcon}>
                  <img src={isSuccess ? letterOk : letterError} />
                </div>
                <div className={styles.buttonsWrapper}>
                  {isSuccess ? (
                    <div
                      className={cx(
                        styles.submitButton,
                        !isResposed && styles.disabled
                      )}
                      onClick={isResposed ? handleConfirmAccount : null}
                    >
                      Ok
                    </div>
                  ) : (
                    <>
                      <div className={styles.formGroup}>
                        <div className={styles.formInputCont}>
                          <input
                            className={styles.formInput}
                            placeholder="Email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div
                        className={cx(
                          styles.submitButton,
                          !isResposed && styles.disabled
                        )}
                        onClick={isResposed ? handleResend : null}
                      >
                        Resend Verification Email
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default ConfirmAccount;
