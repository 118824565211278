import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useApi } from 'api';
import { Popover, Box } from '@material-ui/core';
import styles from './styles.module.scss';
import cx from 'classnames';
import toast from 'utils/toast';
import collectionIcon from 'assets/svgs/collectionIcon.svg';
import { DeleteOutline } from '@material-ui/icons';

const accept = ['image/jpeg', 'image/png', 'image/bmp'];
const acceptMultile = ['image/jpeg', 'image/png', 'image/bmp', 'image/gif'];
export const MAX_FILES = 30;

const InputPhotos = ({
  changeFunction,
  assetType,
  setProgressUpload,
  waitingUpload,
  multiple = true,
  data,
  removeCb,
  photosLength = 0,
}) => {
  const authToken = useSelector(state => state.Auth.authToken);
  const imageRef = useRef();
  const { uploadFile } = useApi();
  const [uploadedFiles, setUploadedFiles] = useState(0);

  const deleteImageRef = useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopover = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);
  const idPopover = openPopover ? 'delete-popover' : undefined;

  useEffect(() => setUploadedFiles(photosLength), [photosLength]);

  const onDrop = useCallback(
    async acceptedFiles => {
      if (acceptedFiles.length < 1) return;
      setProgressUpload(prev => {
        const uploadingBars = acceptedFiles.reduce((obj, file) => {
          obj[file.name] = 0;
          return obj;
        }, {});
        return { ...prev, ...uploadingBars };
      });
      const progressBarCb = fileName => newValue => {
        setProgressUpload(prev => {
          return { ...prev, [fileName]: newValue };
        });
      };
      const completeCb = file => response => {
        setProgressUpload(prev => {
          const afterUpload = Object.keys(prev)
            .filter(k => k !== file.name)
            .reduce((obj, key) => {
              obj[key] = prev[key];
              return obj;
            }, {});
          return afterUpload;
        });

        if (response?.error) {
          toast(
            'error',
            response?.message || response?.error || 'Upload error'
          );
          return;
        }

        const asset = response.data.aswLink;
        changeFunction(prevState => {
          const result = multiple ? [...prevState, asset] : [asset];
          return result;
        });

        toast('success', 'File saved', '', null, false, 3000);
      };

      acceptedFiles.map(file => {
        return uploadFile(
          authToken,
          assetType,
          file,
          progressBarCb(file.name),
          completeCb(file)
        );
      });
    },
    [authToken]
  );

  const acceptedFiles = multiple ? acceptMultile : accept;
  const maxFiles = uploadedFiles >= MAX_FILES ? 1 : MAX_FILES - uploadedFiles;

  const handlerRejectedFiles = fileRejections => {
    if (fileRejections?.length >= maxFiles) {
      toast('warning', 'Max number of downloaded files');
    } else if (
      fileRejections.some(el => !acceptedFiles.includes(el.file.type))
    ) {
      toast('error', 'Invalid file format');
    } else {
      toast('error', 'Max file size 2mb');
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFiles.join(', '),
    onDrop,
    onDropRejected: handlerRejectedFiles,
    maxSize: 2 * 1024 * 1024,
    noClick: true,
    multiple,
    maxFiles: multiple ? maxFiles : 1,
  });

  const addPhotoButton = () =>
    multiple &&
    !waitingUpload && (
      <div
        className={cx(styles.smallImage, styles.addPhotoButton)}
        onClick={() => imageRef.current?.click()}
      >
        <p className={cx(styles.iconSubtitle)}>+</p>
        <p className={cx(styles.uploadsubtitle)}>Add One More Photo</p>
      </div>
    );

  return (
    <div
      className={cx(styles.board, waitingUpload && styles.waitingUpload)}
      onClick={() => {
        if (data.length === 0) {
          imageRef.current?.click();
        }
      }}
    >
      <div
        {...getRootProps({
          className: cx(
            styles.uploadCont,
            !data.length > 0 && styles.emtyCont,
            data.length > 0 && styles.labelEmptyCont
          ),
        })}
      >
        <input {...getInputProps()} ref={imageRef} disabled={waitingUpload} />
        {data.length > 0 ? (
          <div className={styles.additionalImages}>
            {data.map(el => {
              return (
                <div
                  key={el.assetId}
                  className={cx(
                    styles.deleteImage,
                    multiple && styles.smallImage
                  )}
                  disabled={waitingUpload}
                  onClick={e => {
                    e.stopPropagation();
                    deleteImageRef.current = el.assetId;
                    handlePopover(e);
                    // removeCb(el.assetId);
                  }}
                >
                  <div
                    className={cx(
                      styles.deleteIcon,
                      multiple && styles.smallIcon
                    )}
                  >
                    <DeleteOutline fontSize="large" />
                  </div>
                  <img
                    src={el.fullUrl}
                    className={cx(multiple && styles.smallIcon)}
                  />
                </div>
              );
            })}
            {MAX_FILES > uploadedFiles && addPhotoButton()}
          </div>
        ) : (
          <>
            <img
              src={collectionIcon}
              alt="collection"
              className={cx(styles.icon, styles.uploadsubtitle)}
            />
            <div className={cx(styles.formatList, styles.uploadsubtitle)}>
              {`JPG, PNG, BMP${multiple ? ', GIF' : ''} Max 2mb.`}
            </div>
          </>
        )}
      </div>
      <Popover
        id={idPopover}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <p className={cx(styles.popoverLabel)}>
          Are you sure you want to delete this image?
        </p>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <div
            className={cx(styles.button)}
            onClick={() => {
              removeCb(deleteImageRef.current);
              handleClosePopover();
            }}
          >
            Yes
          </div>
          <div className={cx(styles.cancelButton)} onClick={handleClosePopover}>
            Cancel
          </div>
        </Box>
      </Popover>
    </div>
  );
};

export default InputPhotos;
