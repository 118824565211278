import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import './index.css';

import App from 'components/app';
import Web3ReactManager from 'components/Web3ReactManager';
import createStore from '../src/stores/reduxStore';
import { NetworkContextName } from './constants';
import getLibrary from './utils/getLibrary';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

const { store, persistor } = createStore();

ReactDOM.render(
  <Provider store={store}>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <Web3ReactManager>
          <PersistGate loading={null} persistor={persistor}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <App fullScreen />
            </MuiPickersUtilsProvider>
          </PersistGate>
        </Web3ReactManager>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  </Provider>,
  document.getElementById('root')
);
