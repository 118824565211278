import React, { useState, useEffect } from 'react';
import Header from 'components/header';
import styles from './../styles.module.scss';
import { useParams } from 'react-router-dom';
import cx from 'classnames';
import { ClipLoader } from 'react-spinners';
import { useHistory } from 'react-router';
import { useApi } from 'api';
import closeIcon from 'assets/svgs/v2/close.svg';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Background from '../../../components/Background';
import Footer from '../../../components/Footer';
import IconCloseEye from '../../../assets/svgs/eye_close_icon.svg';
import IconEye from '../../../assets/svgs/eye_icon.svg';
import showToast from 'utils/toast';

const NewPassword = ({ isRegister }) => {
  const { postSetNewPassword } = useApi();
  const token = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState(null);
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(null);
  const [reseting, setReseting] = useState(false);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const onClose = () => {
    window.scrollTo(0, 0);
    history.push('/');
  };
  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleClickShowConfirmNewPassword = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  };
  const handleMouseDownPassword = () => {
    event.preventDefault();
  };
  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 0);
    }
  }, [isLoading]);

  const validateNewPassword = () => {
    if (newPassword.length === 0) {
      setNewPasswordError("New Password field can't be blank");
    } else {
      setNewPasswordError(null);
    }
  };
  const validateConfirmNewPassword = () => {
    if (confirmNewPassword.length === 0) {
      setConfirmNewPasswordError("Confirm New Password field can't be blank");
    } else if (confirmNewPassword !== newPassword) {
      setConfirmNewPasswordError(
        'Field New Password and Confirm New Password should match'
      );
    } else {
      setConfirmNewPasswordError(null);
    }
  };
  const handleSetPassword = async () => {
    if (reseting) return;
    try {
      const response = await postSetNewPassword(newPassword, token);
      if (response?.message === 'OK') {
        console.log('Success');
        window.scrollTo(0, 0);
        history.push('/');
      } else if (response?.error) {
        showToast('error', response.error);
      }
    } catch (error) {
      showToast('error', error);
    } finally {
      setReseting(false);
    }
  };
  useEffect(() => {
    setNewPassword('');
    setConfirmNewPassword('');
    setNewPasswordError(null);
    setConfirmNewPasswordError(null);
  }, [isRegister]);
  const isValid = (() => {
    if (newPasswordError) return false;
    if (confirmNewPasswordError) return false;
    return true;
  })();
  return (
    <div className={styles.outerContainer}>
      <div className={styles.authContainer}>
        <Background />
        <Header border />
        <div className={cx(styles.inner, styles.inner700)}>
          <form className={styles.authForm}>
            <div className={styles.closeButton} onClick={onClose}>
              <img src={closeIcon} alt="Close" />
            </div>
            <div className={styles.title}>Change Password</div>
            <div className={styles.subtitle}>
              Create your new password to login
            </div>
            <div className={styles.inputGroup}>
              <div className={styles.inputWrapper}>
                <OutlinedInput
                  className={cx(
                    styles.input,
                    styles.password,
                    newPasswordError && styles.hasError
                  )}
                  placeholder="New password"
                  type={showNewPassword ? 'text' : 'password'}
                  value={newPassword}
                  onChange={e => setNewPassword(e.target.value)}
                  onBlur={validateNewPassword}
                  inputProps={{
                    autocomplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showNewPassword ? (
                          <img src={IconCloseEye} alt={'Hide password'} />
                        ) : (
                          <img src={IconEye} alt={'Show password'} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {newPasswordError && (
                  <div className={styles.error}>{newPasswordError}</div>
                )}
              </div>
            </div>
            <div className={styles.inputGroup}>
              <div className={styles.inputWrapper}>
                <OutlinedInput
                  className={cx(
                    styles.input,
                    styles.password,
                    confirmNewPasswordError && styles.hasError
                  )}
                  placeholder="Confirm new password"
                  type={showConfirmNewPassword ? 'text' : 'password'}
                  value={confirmNewPassword}
                  onChange={e => setConfirmNewPassword(e.target.value)}
                  onBlur={validateConfirmNewPassword}
                  inputProps={{
                    autocomplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmNewPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmNewPassword ? (
                          <img src={IconCloseEye} alt={'Hide password'} />
                        ) : (
                          <img src={IconEye} alt={'Show password'} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {confirmNewPasswordError && (
                  <div className={styles.error}>{confirmNewPasswordError}</div>
                )}
              </div>
            </div>
            <div className={styles.buttonsWrapper}>
              <div
                className={cx(styles.submitButton, !isValid && styles.disabled)}
                onClick={isValid ? handleSetPassword : null}
              >
                {reseting ? <ClipLoader color="#FFF" size={16} /> : 'Save'}
              </div>
              <div className={styles.formLabelRow}>
                <div className={styles.labelLink} onClick={onClose}>
                  Cancel
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default NewPassword;
