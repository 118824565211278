import React from 'react';

const LogoCNFT = props => (
  <svg
    width="48"
    height="49"
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <ellipse
      cx="23.5952"
      cy="24.5812"
      rx="22.9619"
      ry="23.4488"
      fill="#2249B3"
      fillOpacity="0.1"
    />
    <path
      d="M16.0739 31.8584H37.8481V39.1356H8.94775L16.0739 31.8584Z"
      fill="#98EED5"
      fillOpacity="0.1"
    />
    <path
      d="M26.6528 20.9429H37.8486V27.4115H20.4292L26.6528 20.9429Z"
      fill="#98EED5"
      fillOpacity="0.1"
    />
    <path
      d="M37.8479 9.62207V9.82422V16.495H31.1177L37.8479 9.62207Z"
      fill="#98EED5"
      fillOpacity="0.1"
    />
    <path
      d="M31.115 16.4956L20.03 16.4956V9.62267L37.8452 9.62267L31.115 16.4956Z"
      fill="#83CEB8"
      fillOpacity="0.1"
    />
    <path
      d="M26.6577 20.9429H20.0322V27.4115H20.4278L26.6577 20.9429Z"
      fill="#83CEB8"
      fillOpacity="0.1"
    />
    <path
      d="M16.0718 31.8585V9.62254H8.94566L8.94566 39.1357L16.0718 31.8585Z"
      fill="#83CEB8"
      fillOpacity="0.1"
    />
  </svg>
);

export default LogoCNFT;
