import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createReducer from '../reducers/index';

const persistConfig = {
  key: 'gaia',
  storage,
  whitelist: ['Auth', 'ConnectWallet'],
};

export default asyncReducers =>
  persistReducer(persistConfig, createReducer(asyncReducers));
