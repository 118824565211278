import FilterConstants from '../constants/filter.constants';

const initialState = {
  status: '',
  collections: [],
  category: null,
  groupType: 'all',
  sortBy: 'viewed',
};

export function Filter(state = initialState, action) {
  switch (action.type) {
    case FilterConstants.UPDATE_STATUS_FILTER: {
      const newStatus = state.status === action.field ? '' : action.field;
      return { ...state, status: newStatus };
    }
    case FilterConstants.RESET_STATUS_FILTER: {
      const newState = { ...state, status: '' };
      return newState;
    }
    case FilterConstants.UPDATE_COLLECTIONS_FILTER: {
      return {
        ...state,
        collections: action.collections,
      };
    }
    case FilterConstants.UPDATE_CATEGORIES_FILTER: {
      return {
        ...state,
        category: action.category,
      };
    }
    case FilterConstants.UPDATE_GROUP_TYPE_FILTER: {
      return {
        ...state,
        groupType: action.groupType,
      };
    }
    case FilterConstants.UPDATE_SORT_BY_FILTER: {
      return {
        ...state,
        sortBy: action.sortBy,
      };
    }
    default: {
      return state;
    }
  }
}
