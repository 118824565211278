import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import FilterWrapper from 'components/FilterWrapper';
import FilterActions from 'actions/filter.actions';

import categoriesIcon from 'assets/svgs/categoriesIcon.svg';

import './styles.scss';
import { useApi } from '../../api';
import { ClipLoader } from 'react-spinners';
import cx from 'classnames';

const useStyles = makeStyles(() => ({
  body: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '0',
  },
  collectionsList: {},
  collection: {
    height: 48,
    padding: '0 8px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    opacity: 0.6,
    color: '#121223',
    '&:hover': {
      background: 'rgba(18, 18, 35, 0.06)',
    },
  },
  selected: {
    opacity: '1',
    background: 'rgba(18, 18, 35, 0.06)',
  },
  logo: {
    border: 'none',
    marginRight: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 59.25,

    '& img': {
      width: 24,
      height: 24,
    },
  },
  name: {
    fontWeight: 700,
    fontSize: 14,
    marginRight: 4,
  },
}));

const CategoriesFilter = () => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const { category } = useSelector(state => state.Filter);

  const { getCategories } = useApi();
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    getCategories().then(
      categoriesDto => {
        if (categoriesDto) {
          setCategories(categoriesDto);
        }
      },
      () => {}
    );
  }, []);

  const handleSelectCategory = newCategory => {
    dispatch(
      FilterActions.updateCategoryFilter(
        category?.id === newCategory?.type
          ? null
          : {
              id: newCategory.type,
              label: newCategory.label,
              address: newCategory._id,
            }
      )
    );
  };

  return (
    <FilterWrapper
      title="Categories"
      icon={categoriesIcon}
      classes={{ body: classes.body }}
    >
      <div className={classes.collectionsList}>
        {categories ? (
          categories.map(cat => (
            <div
              key={cat.type}
              className={cx(
                classes.collection,
                cat.type === category?.id && classes.selected
              )}
              onClick={() => handleSelectCategory(cat)}
            >
              <img
                src={cat?.smallIcon}
                alt={cat?.label}
                className={classes.logo}
              />
              <span className={classes.name}>{cat?.label || 'All'}</span>
            </div>
          ))
        ) : (
          <ClipLoader color="#7F56D9" size={16} />
        )}
      </div>
    </FilterWrapper>
  );
};

export default CategoriesFilter;
