import React from 'react';
import Modal from '../Modal';
import styles from './styles.module.scss';
import { useWeb3React } from '@web3-react/core';
import WalletConnectActions from '../../actions/walletconnect.actions';
import { useDispatch } from 'react-redux';
import { shortenAddress } from 'utils';

const WrongWalletModal = ({ visible, onClose, userWalletAddress }) => {
  const dispatch = useDispatch();
  const { deactivate } = useWeb3React();

  const handleDisconnect = () => {
    deactivate();
    dispatch(WalletConnectActions.disconnectWallet());
    onClose();
  };

  return (
    <Modal
      visible={visible}
      title="Wrong wallet"
      onClose={handleDisconnect}
      submitDisabled={true}
      showTitle={false}
      small
    >
      <div className={styles.title}>Wrong wallet</div>
      <div className={styles.subtitleContainer}>
        <div className={styles.subtitle}>
          Connected wallet is not associated with the current account. Please
          change your wallet to the one you have already verified
          {userWalletAddress ? (
            <span className={styles.walletAddress}>
              &nbsp;({shortenAddress(userWalletAddress)})
            </span>
          ) : null}
          .
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.switchBtn} onClick={handleDisconnect}>
          Disconnect Wallet
        </div>
      </div>
    </Modal>
  );
};

export default WrongWalletModal;
