import React, { useRef, useState, useEffect, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useResizeDetector } from 'react-resize-detector';
import cx from 'classnames';
import { Tooltip, Menu, MenuItem } from '@material-ui/core';
import { useWeb3React } from '@web3-react/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Skeleton from 'react-loading-skeleton';
import ReactPlayer from 'react-player';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import TxButton from 'components/TxButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

import NFTsGrid from 'components/NFTsGrid';
import Header from 'components/header';
import Identicon from 'components/Identicon';
import NewBundleModal from 'components/NewBundleModal';
import FollowersModal from 'components/FollowersModal';
import SuspenseImg from 'components/SuspenseImg';
import {
  shortenAddress,
  formatFollowers,
  getRandomIPFS,
  formatError,
} from 'utils';
import toast from 'utils/toast';
import { useApi } from 'api';
import useTokens from 'hooks/useTokens';
import usePrevious from 'hooks/usePrevious';
import HeaderActions from 'actions/header.actions';
import ModalActions from 'actions/modal.actions';
import Footer from 'components/Footer';
import AuthActions from 'actions/auth.actions';

import iconCopy from 'assets/svgs/copy.svg';
import iconSettings from 'assets/svgs/settings.svg';
import iconShare from 'assets/svgs/share.svg';
import iconEdit from 'assets/svgs/edit.svg';
import iconLink from 'assets/svgs/v2/link.svg';
import iconFacebook from 'assets/imgs/facebook.png';
import iconTwitter from 'assets/svgs/twitter_blue.svg';
import iconGoogleAuth from 'assets/svgs/google_authenticator.svg';
import iconGooglePlay from 'assets/svgs/google_play.svg';
import iconAppStore from 'assets/svgs/app_store.svg';
import defaultAvatar from 'assets/imgs/default_avatar.png';
import IconProperty from 'assets/icons/iconProperty';
import IconHeart from 'assets/icons/iconHeart';
import IconClock from 'assets/icons/iconClock';
import IconCheck from 'assets/icons/iconCheck';
import IconKYC from 'assets/icons/iconKYC';
import IconExclamation from 'assets/icons/iconExclamation';
import IconSecurity from 'assets/icons/iconSecurity';
import IconBids from 'assets/icons/iconBids';
import IconHandMoney from 'assets/icons/iconHandMoney';
import IconWallet from 'assets/icons/iconWallet';
import IconReceivedOffers from 'assets/icons/iconReceivedOffers';
import IconSentOffers from 'assets/icons/iconSentOffers';
import { ethers } from 'ethers';
import { launchWebSdk } from 'utils';
import { getSigner, useSalesContract, useAuctionContract } from 'contracts';

import styles from './styles.module.scss';
import FilterActions from '../../actions/filter.actions';
import { isEmptyObject } from '../../utils/objects';
import IconCheckBig from '../../assets/icons/iconCheckBig';
import LogoCNFT from '../../assets/icons/logoCNFT';
import { ClipLoader } from 'react-spinners';
import { GOOGLE_PLAY, APP_STORE } from '../../constants/auth.constants';
import showToast from 'utils/toast';

const CustomCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#7F56D9',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const SettingOption = ({ name, title, checked, disabled, onChange }) => (
  <FormControlLabel
    classes={{ root: styles.optionPanel }}
    control={
      <CustomCheckbox
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        name={name}
      />
    }
    labelPlacement="start"
    label={<div className={styles.optionTitle}>{title}</div>}
  />
);

const AccountDetails = () => {
  const dispatch = useDispatch();

  const {
    storageUrl,
    getUserAccountDetails,
    getUserFigures,
    //fetchCollections,
    fetchTokens,
    updateBanner,
    getAccountActivity,
    getActivityFromOthers,
    getMyOffers,
    getFollowing,
    followUser: _followUser,
    getFollowers,
    getFollowings,
    getMyLikes,
    getItemsLiked,
    verifyWallet,
    fetchUserNft,
    sumsubToken: getSumsubToken,
    sumsubStatus: getSumsubStatus,
    getUserWalletAddress,
    getUserByWalletAddress,
    checkIfWalletIsVerifiedByUser,
    getUserIsKycVerified,
    getValidationString,
    postUpdateUser,
    fetchQrcode,
    check2faCode,
    getAuctionBidFromOthers,
    getMyBidsAuction,
  } = useApi();
  const { withdrawBid } = useAuctionContract();
  const { cancelOffer } = useSalesContract();
  const { getTokenByAddress } = useTokens();
  const { account, chainId } = useWeb3React();
  const { width, ref } = useResizeDetector();
  const location = useLocation();

  const { uid: uidFromParams } = useParams();
  const [uid, setUid] = useState(undefined);

  const { user: me, authToken } = useSelector(state => state.Auth);

  const fileInput = useRef();

  const [anchorEl, setAnchorEl] = useState(null);
  const [prevUID, setPrevUID] = useState(null);
  const [bundleModalVisible, setBundleModalVisible] = useState(false);
  const [followingsModalVisible, setFollowingsModalVisible] = useState(false);
  const [followersModalVisible, setFollowersModalVisible] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [bundleFetching, setBundleFetching] = useState(false);
  const [favFetching, setFavFetching] = useState(false);
  const [fguresFetching, setFiguresFetching] = useState(false);
  const tokens = useRef([]);
  const bundles = useRef([]);
  const likes = useRef([]);
  const [followersLoading, setFollowersLoading] = useState(false);
  const followers = useRef([]);
  const followings = useRef([]);
  const [following, setFollowing] = useState(false);
  const [followingInProgress, setFollowingInProgress] = useState(false);
  const [count, setCount] = useState(0);
  const [bundleCount, setBundleCount] = useState(0);
  const [favCount, setFavCount] = useState(0);
  const [offersCount, setOffersCount] = useState(0);
  const [myOffersCount, setMyOffersCount] = useState(0);
  const [now, setNow] = useState(new Date());
  const [page, setPage] = useState(0);
  const [bannerUrl, setBannerUrl] = useState();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [copied, setCopied] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tab, setTab] = useState(0);
  const [activityLoading, setActivityLoading] = useState(false);
  const [activities, setActivities] = useState([]);
  const [bidsLoading, setBidsLoading] = useState(false);
  const [bids, setBids] = useState([]);
  const [offersLoading, setOffersLoading] = useState(false);
  const [offers, setOffers] = useState([]);
  const [auctionLoading, setAuctionsLoading] = useState(false);
  const [auctions, setAuctions] = useState([]);
  const [auctionsCount, setAuctionsCount] = useState(0);
  const [myBidsAuction, setMyBidsAuction] = useState([]);
  const [myBidsAuctionCount, setMyBidsAuctionCount] = useState(0);
  //const [fetchInterval, setFetchInterval] = useState(null);
  const [offerCanceling, setOfferCanceling] = useState(false);
  const [bidWithdrawing, setBidWithdrawing] = useState(false);
  const [likeCancelSource, setLikeCancelSource] = useState(null);
  const [prevNumPerRow, setPrevNumPerRow] = useState(null);
  const prevAuthToken = usePrevious(authToken);
  const [walletVerified, setWalletVerified] = useState(false);

  // Is user KYC verified in backend database
  const [kycVerified, setKycVerified] = useState();

  // Is user KYC verified on sumsub website
  const [
    isKycVerifiedOnSumsubWebsite,
    setIsKycVerifiedOnSumsubWebsite,
  ] = useState(false);
  const sumsubStatusIntervalRef = useRef(null);

  const [showSumSubContainer, setShowSumSubContainer] = useState(false);
  const [countGaia, setCountGaia] = useState(0);
  const [hideExpiredReceivedOffers, setHideExpiredReceivedOffers] = useState(
    false
  );
  const [hideExpiredSentOffers, setHideExpiredSentOffers] = useState(false);
  const [hideExpiredReceivedBids, setHideExpiredReceivedBids] = useState(false);
  const [hideExpiredPlacedBids, setHideExpiredPlacedBids] = useState(false);
  const fetchedGaiaNfts = useRef(false);
  const [isMe, setIsMe] = useState(
    authToken &&
      (location.pathname === '/profile' ||
        account?.toLowerCase() === uid?.toLowerCase())
  );
  const [
    walletIsVerifiedByAnotherUser,
    setWalletIsVerifiedByAnotherUser,
  ] = useState(false);
  const [isKycReqSent, setIsKycReqSent] = useState(false);

  const [bannerUrlLink, setBannerUrlLink] = useState(null);
  const [userBannerLink, setUserBannerLink] = useState(null);
  const [qrcode, setQrcode] = useState('');
  const [qrValueError, setQRValueError] = useState(false);
  const qrValue = useRef(0);

  const numPerRow = Math.floor(width / 240);
  const fetchCount = numPerRow <= 3 ? 18 : numPerRow === 4 ? 16 : numPerRow * 3;

  const getUserDetails = async _account => {
    setLoading(true);
    if (location.pathname === '/profile' && authToken && me) {
      console.log(showSumSubContainer);
      const accountDetailsResponse = await getUserAccountDetails(uid);
      const data = {
        address:
          me.user_wallets?.length && me.user_wallets[0]?.address > 1
            ? me.user_wallets[0].address
            : null,
        alias: me.name,
        bio: me.bio,
        email: me.email,
        followers: accountDetailsResponse?.data?.followers || 0,
        followings: accountDetailsResponse?.data?.followings || 0,
        avatar: me.avatar,
        banner: me.banner,
        user_id: me.user_id,
      };
      setUser(data);
      setFollowing(false);
    } else if (_account) {
      try {
        const userDto = await getUserByWalletAddress(_account);
        const accountDetailsResponse = await getUserAccountDetails(uid);
        if (userDto) {
          setUser({
            address: _account,
            alias: userDto.name,
            bio: userDto.bio,
            email: userDto.email,
            followers: accountDetailsResponse?.data?.followers || 0,
            followings: accountDetailsResponse?.data?.followings || 0,
            avatar: userDto.avatar,
            banner: userDto.banner,
          });
        }
      } catch {
        setUser({});
      }

      if (account === undefined) {
        setFollowing(false);
      } else {
        try {
          const { data: isFollowing } = await getFollowing(account, _account);
          setFollowing(isFollowing);
        } catch {
          setFollowing(false);
        }
      }
    }
    setLoading(false);
  };

  const connectedWrongWallet = () => {
    return uid && account && uid.toLowerCase() !== account.toLowerCase();
  };

  const getFigures = async _account => {
    setFiguresFetching(true);

    try {
      const {
        data: { single, bundle, fav, offers, myOffers, bids, myBids },
      } = await getUserFigures(_account);
      setCount(single);
      setBundleCount(bundle);
      setFavCount(fav);
      setOffersCount(offers);
      setMyOffersCount(myOffers);
      setAuctionsCount(bids);
      setMyBidsAuctionCount(myBids);
    } catch {
      setCount(0);
      setBundleCount(0);
      setFavCount(0);
      setOffersCount(0);
      setMyOffersCount(0);
      setAuctionsCount(0);
      setMyBidsAuctionCount(0);
    }

    setFiguresFetching(false);
  };

  const fetchNFTs = async () => {
    if (tab === 0) {
      if (fetching) return;
      setFetching(true);
    } else {
      if (bundleFetching) return;
      setBundleFetching(true);
    }

    try {
      let data;
      if (uid) {
        const start =
          tab === 0
            ? tokens.current.length - countGaia
            : bundles.current.length - countGaia;
        const _count =
          fetchCount -
          (((tab === 0 ? tokens.current : bundles.current).length - countGaia) %
            numPerRow);
        const fetchedTokens = await fetchTokens(
          start,
          _count,
          tab === 0 ? 'single' : 'bundle',
          [],
          null,
          'createdAt',
          [],
          uid
        );
        data = fetchedTokens?.data;
      } else {
        data = { tokens: [] };
      }

      if (tab === 0) {
        // eslint-disable-next-line require-atomic-updates
        let gaiaNft;
        if (!fetchedGaiaNfts.current && authToken && isMe) {
          gaiaNft = await fetchUserNft(authToken);
        } else {
          gaiaNft = { meta: { total: 0 }, nfts: [] };
        }
        if (!fetchedGaiaNfts.current) {
          setCountGaia(gaiaNft?.meta?.total || 0);
        }
        fetchedGaiaNfts.current = true;
        tokens.current = [...tokens.current, ...gaiaNft?.nfts, ...data.tokens];
        setCount(isNaN(data?.total) ? 0 : data?.total);
        if (authToken) {
          updateItems(tokens.current)
            .then(_tokens => (tokens.current = _tokens))
            .catch();
        }
      } else {
        // eslint-disable-next-line require-atomic-updates
        bundles.current = [...bundles.current, ...data.tokens];
        setBundleCount(data.total);
        if (authToken) {
          updateItems(bundles.current)
            .then(_bundles => (bundles.current = _bundles))
            .catch();
        }
      }

      setFetching(false);
      setBundleFetching(false);
    } catch {
      setFetching(false);
      setBundleFetching(false);
    }
  };

  const fetchLikes = async step => {
    if (favFetching || fetching) return;

    setFavFetching(true);

    try {
      const { data } = await getMyLikes(step, uid);
      setFavFetching(false);
      likes.current = [...likes.current, ...data.tokens];
      setFavCount(data.total);
      if (authToken) {
        updateItems(likes.current)
          .then(_likes => (likes.current = _likes))
          .catch(() => {});
      }
      setPage(step);
    } catch {
      setFavCount(0);
      setFavFetching(false);
    }
  };

  const userIsNotFound = () => {
    return !user || isEmptyObject(user);
  };

  useEffect(() => {
    if (location.pathname === '/profile' && authToken) {
      setLoading(true);
      getUserWalletAddress(authToken)
        .then(
          userWalletAddress => {
            if (userWalletAddress) {
              setUid(userWalletAddress);
            }
          },
          () => {
            setUid(null);
          }
        )
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      setUid(uidFromParams);
      setLoading(false);
    }
  }, [authToken, account, walletVerified, location.pathname, uidFromParams]);

  useEffect(() => {
    setIsMe(
      authToken &&
        (location.pathname === '/profile' ||
          account?.toLowerCase() === uid?.toLowerCase())
    );
  }, [authToken, uid, account, location.pathname]);

  useEffect(() => {
    setPrevNumPerRow(numPerRow);
    if (isNaN(numPerRow) || (prevNumPerRow && prevNumPerRow !== numPerRow))
      return;

    if (uid && prevUID !== uid) {
      setPrevUID(uid);
      getUserDetails(uid);
      getFigures(uid);
      // If just verified wallet stay on the same tab
      if (!(isMe && tab === 6)) {
        setTab(0);
      }
      init();
    } else {
      if (!uid && isEmptyObject(user) && authToken) {
        getUserDetails(null);
      }
      // Fetch NFTs or other data depending on chosen tab
      // if attempt to get uid was made
      if (uid !== undefined) {
        init();
      }
    }
  }, [uid, tab, chainId, numPerRow]);

  useEffect(() => {
    if (me && user && uid && isMe && !uidFromParams) {
      setUser({ ...user, ...me });
    }

    if (account === undefined) {
      setFollowing(false);
    }

    if (!me.is_2fa) {
      setTab(8);
    }
  }, [me, uid, account, isMe]);

  useEffect(() => {
    if (user?.alias && me?.name) {
      setUser({ ...user, alias: me.name });
    }
  }, [me?.name]);

  useEffect(() => {
    const donloadQrcode = async token => {
      const qrcodeUrl = await fetchQrcode(token);
      if (qrcodeUrl?.error) {
        toast('error', qrcodeUrl.error);
      } else {
        setQrcode(qrcodeUrl);
      }
    };

    if (authToken && !me.is_2fa) {
      donloadQrcode(authToken);
    }
  }, [authToken]);

  useEffect(() => {
    dispatch(HeaderActions.toggleSearchbar(true));
    dispatch(FilterActions.updateCategoryFilter(null));
    dispatch(FilterActions.resetStatusFilter());
  }, []);

  useEffect(() => {
    const id = setInterval(() => setNow(new Date()), 1000);
    return () => clearInterval(id);
  }, []);

  const updateItems = async _tokens => {
    return new Promise((resolve, reject) => {
      if (!authToken) {
        return resolve(
          _tokens.map(tk => ({
            ...tk,
            isLiked: false,
          }))
        );
      }
      let missingTokens = _tokens.map((tk, index) =>
        tk.items
          ? {
              index,
              isLiked: tk.isLiked,
              bundleID: tk._id,
            }
          : {
              index,
              isLiked: tk.isLiked,
              contractAddress: tk.contractAddress,
              tokenID: tk.tokenID,
            }
      );
      if (prevAuthToken) {
        missingTokens = missingTokens.filter(tk => tk.isLiked === undefined);
      }

      if (missingTokens.length === 0) {
        reject();
        return;
      }

      const cancelTokenSource = axios.CancelToken.source();
      setLikeCancelSource(cancelTokenSource);
      getItemsLiked(missingTokens, authToken, cancelTokenSource.token)
        .then(({ data, status }) => {
          setLikeCancelSource(null);
          if (status === 'success') {
            const newTokens = [...tokens.current];
            missingTokens.map((tk, idx) => {
              newTokens[tk.index].isLiked = data[idx].isLiked;
            });
            resolve(newTokens);
          }
        })
        .catch(() => {
          reject();
        });
    });
  };

  const fetchKycStatus = async () => {
    const userIsKycVerified = await getUserIsKycVerified(authToken);
    setKycVerified(userIsKycVerified);
  };

  useEffect(() => {
    if (!authToken) {
      setIsMe(false);
    }

    if (likeCancelSource) {
      likeCancelSource.cancel();
    }

    if (tokens.current.length) {
      updateItems(tokens.current)
        .then(_tokens => (tokens.current = _tokens))
        .catch();
    }
    if (bundles.current.length) {
      updateItems(bundles.current)
        .then(_bundles => (bundles.current = _bundles))
        .catch();
    }
    if (likes.current.length) {
      updateItems(likes.current)
        .then(_likes => (likes.current = _likes))
        .catch();
    }
    // get sumsub token
    fetchKycStatus().catch(() => {
      setKycVerified(false);
    });
  }, [authToken]);

  useEffect(() => {
    const id = setInterval(() => {
      getSumsubStatus(authToken)
        .then(isVerified => {
          setIsKycVerifiedOnSumsubWebsite(isVerified);
          if (isVerified && sumsubStatusIntervalRef?.current) {
            clearInterval(sumsubStatusIntervalRef.current);
          }
        })
        .catch(() => {
          setIsKycVerifiedOnSumsubWebsite(false);
        });
    }, 5000);
    sumsubStatusIntervalRef.current = id;

    return () => clearInterval(id);
  }, [authToken]);

  useEffect(() => {
    if (authToken && account && account.toLowerCase() === uid?.toLowerCase()) {
      checkIfWalletIsVerifiedByUser(authToken, account).then(
        ([isVerified, error]) => {
          if (!error) {
            setWalletVerified(isVerified);
            setWalletIsVerifiedByAnotherUser(false);
          }
        },
        () => {
          setWalletVerified(false);
          setWalletIsVerifiedByAnotherUser(false);
        }
      );
    } else if (authToken && account && !uid) {
      getUserByWalletAddress(account).then(
        userDto => {
          if (userDto?.user_id && userDto.user_id !== me?.user_id) {
            setWalletIsVerifiedByAnotherUser(true);
          } else {
            setWalletIsVerifiedByAnotherUser(false);
          }
        },
        () => {
          setWalletIsVerifiedByAnotherUser(false);
        }
      );
    } else if (authToken && !account && uid) {
      checkIfWalletIsVerifiedByUser(authToken, uid).then(
        ([isVerified, error]) => {
          if (!error && isVerified) {
            setIsMe(true);
            setWalletVerified(false);
          }
        },
        () => {
          setWalletVerified(false);
        }
      );
    } else {
      setWalletVerified(false);
    }
  }, [authToken, account, uid]);

  useEffect(() => {
    if (bannerUrl) {
      setBannerUrlLink(getRandomIPFS(bannerUrl));
    }
  }, [bannerUrl]);

  useEffect(() => {
    if (user?.banner) {
      setUserBannerLink(getRandomIPFS(user.banner));
    }
  }, [user?.banner]);

  const loadNextPage = () => {
    if (fetching || bundleFetching) return;

    if (tab === 0 && tokens.current.length - countGaia === count) return;
    if (tab === 1 && bundles.current.length === bundleCount) return;
    if (tab === 2 && likes.current.length === favCount) return;

    if (tab === 0 || tab === 1) {
      fetchNFTs();
    } else {
      fetchLikes(page + 1);
    }
  };

  const handleScroll = e => {
    if (tab > 2) return;
    const obj = e.currentTarget;
    if (obj.scrollHeight - obj.clientHeight - obj.scrollTop < 100) {
      loadNextPage();
    }
  };

  const init = async () => {
    if (tab === 0) {
      tokens.current = [];
      setCount(0);
      fetchedGaiaNfts.current = false;
      fetchNFTs();
    } else if (tab === 1) {
      bundles.current = [];
      setBundleCount(0);
      fetchNFTs();
    } else if (tab === 2) {
      likes.current = [];
      setFavCount(0);
      fetchLikes(0);
    } else if (tab === 3) {
      getActivity();
    } else if (tab === 4) {
      getOffersFromOthers();
    } else if (tab === 5) {
      getOffers();
    } else if (tab === 9) {
      getAuctionFromOthers();
    } else if (tab === 10) {
      getMyAuctionBids();
    }
  };

  const getLinkToAccount = () => {
    if (uid) {
      return `${window.location.origin}/account/${uid}`;
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopyLink = () => {
    handleClose();
    toast('success', 'Link copied to clipboard!');
  };

  const handleShareOnFacebook = () => {
    handleClose();
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${getLinkToAccount()}`,
      '_blank'
    );
  };

  const handleShareToTwitter = () => {
    handleClose();
    window.open(
      `https://twitter.com/intent/tweet?text=Check%20out%20this%20account%20on%20CNFT&url=${getLinkToAccount()}`,
      '_blank'
    );
  };

  const handleVerifyWalllet = async () => {
    // dispatch(ModalActions.showConnectWalletModal());
    let signature;
    let addr;
    try {
      const signer = await getSigner();
      const validationString = await getValidationString();
      if (!validationString) {
        toast('error', "Couldn't get validation string");
        return;
      }
      signature = await signer.signMessage(validationString);
      addr = ethers.utils.verifyMessage(validationString, signature);
      const { message, is_valid } = await verifyWallet(
        signature,
        addr,
        authToken
      );
      if (message === 'OK' && is_valid === true) {
        setWalletVerified(true);
      }
    } catch (error) {
      let errorMessage;
      if (error?.response?.data?.error) {
        errorMessage = error.response.data.error;
      } else {
        errorMessage =
          'You need to sign the message to be able to verify your wallet.';
      }
      toast('error', errorMessage);
    }
  };

  const handleKYC = async () => {
    const { message, data, error } = await getSumsubToken(authToken);
    if (error) {
      toast('error', error);
    }
    if (message === 'OK' && data?.token) {
      setShowSumSubContainer(true);
      console.log('sumsubToken', data.token);
      launchWebSdk(data.token, onSumSubMessage);
    } else {
      toast('error', "Couldn't get sumsub token");
    }
  };

  const handleCancelOffer = async (address, tokenID) => {
    if (offerCanceling) return;

    try {
      setOfferCanceling(true);
      const tx = await cancelOffer(address, tokenID);
      await tx.wait();

      setMyOffersCount(prev => prev - 1);
      setBids(prev => prev.filter(el => el.tokenID !== tokenID));
    } catch (error) {
      showToast('error', formatError(error));
    } finally {
      setOfferCanceling(false);
    }
  };

  const handleWithdrawBid = async (address, tokenID) => {
    if (bidWithdrawing) return;

    try {
      setBidWithdrawing(true);
      await withdrawBid(address, ethers.BigNumber.from(tokenID));
      setMyBidsAuctionCount(prev => prev - 1);
      setMyBidsAuction(prev => prev.filter(el => el.tokenID !== tokenID));
      setBidWithdrawing(false);
      showToast('success', 'You have withdrawn your bid!');
    } catch (error) {
      showToast('error', formatError(error));
      setBidWithdrawing(false);
    }
  };

  const onSumSubMessage = (type, payload) => {
    if (payload?.reviewStatus === 'completed') {
      setIsKycReqSent(true);
      toast('success', 'The KYC authentication request was sent');
    }
  };

  const goToTab = _tab => {
    if (_tab !== tab) {
      tokens.current = [];
      bundles.current = [];
      likes.current = [];

      if (!me.is_2fa) {
        _tab = 8;
        toast('warning', 'Please, complete the 2FA first');
      } else if (_tab === 7 && !walletVerified) {
        _tab = 6;
        toast('error', 'Please, verify your wallet first');
      }
      setTab(_tab);
    }
  };

  const getActivity = async () => {
    try {
      setActivityLoading(true);
      const { data } = await getAccountActivity(uid);
      const _activities = [];

      data.bids.map(bActivity => _activities.push(bActivity));
      data.listings.map(lActivity => _activities.push(lActivity));
      data.offers.map(oActivity => _activities.push(oActivity));
      data.sold.map(sActivity => _activities.push(sActivity));

      _activities.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
      _activities.map(item => {
        item.token = getTokenByAddress(item.paymentToken);
      });
      setActivities(_activities);
      setActivityLoading(false);
    } catch {
      setActivityLoading(false);
    }
  };

  const getOffersFromOthers = async () => {
    try {
      setOffersLoading(true);
      const { data } = await getActivityFromOthers(uid);
      data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
      data.map(item => {
        item.token = getTokenByAddress(item.paymentToken);
      });
      setOffers(data);
      setOffersCount(data?.length);
      setOffersLoading(false);
    } catch {
      setOffersCount(0);
      setOffersLoading(false);
    }
  };

  const getOffers = async () => {
    try {
      setBidsLoading(true);
      const { data } = await getMyOffers(uid);
      data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
      data.map(item => {
        item.token = getTokenByAddress(item.paymentToken);
      });
      setBids(data);
      setMyOffersCount(data?.length);
      setBidsLoading(false);
    } catch {
      setBidsLoading(false);
      setMyOffersCount(0);
    }
  };

  const getAuctionFromOthers = async () => {
    try {
      setAuctionsLoading(true);
      const { data } = await getAuctionBidFromOthers(uid);
      data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
      data.map(item => {
        item.token = getTokenByAddress(item.paymentToken);
      });
      setAuctions(data);
      setAuctionsCount(data?.length);
      setAuctionsLoading(false);
    } catch {
      setAuctionsCount(0);
      setAuctionsLoading(false);
    }
  };

  const getMyAuctionBids = async () => {
    try {
      setAuctionsLoading(true);
      const { data } = await getMyBidsAuction(uid);
      data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
      data.map(item => {
        item.token = getTokenByAddress(item.paymentToken);
      });
      setMyBidsAuction(data);
      setMyBidsAuctionCount(data?.length);
      setAuctionsLoading(false);
    } catch {
      setMyBidsAuctionCount(0);
      setAuctionsLoading(false);
    }
  };

  const handleCopyAddress = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const handleMouseOver = () => {
    setTooltipOpen(true);
  };

  const handleMouseLeave = () => {
    setTooltipOpen(false);
    setCopied(false);
  };

  const handleSendQRVerification = async () => {
    if (qrValue.current.length !== 6 || isNaN(qrValue.current)) {
      setQRValueError(true);
      return;
    }
    try {
      const workToken = await check2faCode(authToken, qrValue.current);
      if (workToken.error) {
        toast('error', workToken.error);
      } else {
        dispatch(AuthActions.set2FA(workToken));
      }
    } catch (error) {
      toast('error', error.message);
    } finally {
      setQRValueError(false);
    }
  };

  const selectBanner = () => {
    fileInput.current?.click();
  };

  const handleEnterSendQR = e => {
    if (e.key === 'Enter') {
      handleSendQRVerification();
    }
  };

  const handleSelectFile = e => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];

      const reader = new FileReader();

      reader.onload = async function(e) {
        const { data } = await updateBanner(e.target.result, authToken);
        const userBanner = `https://Liquid.mypinata.cloud/ipfs/${data}`;
        await postUpdateUser(
          authToken,
          user.user_id,
          user.name || user?.alias,
          user.bio,
          user.avatar,
          userBanner
        );
        setBannerUrl(userBanner);
        dispatch(AuthActions.updateBanner(userBanner));
      };

      reader.readAsDataURL(file);
    }
    e.target.value = null;
  };

  const openAccountSettings = () => {
    dispatch(ModalActions.showAccountModal());
  };

  // const handleCreateBundle = () => {
  //   setBundleModalVisible(true);
  // };

  const fetchFollowers = async () => {
    setFollowersLoading(true);
    try {
      const { data } = await getFollowers(uid);
      followers.current = data;
    } catch {
      followers.current = [];
    }
    setFollowersLoading(false);
  };

  const fetchFollowings = async () => {
    setFollowersLoading(true);
    try {
      const { data } = await getFollowings(uid);
      followings.current = data;
    } catch {
      followings.current = [];
    }
    setFollowersLoading(false);
  };

  const showFollowers = () => {
    if (loading || !user.followers || user.followers === 0) return;

    setFollowersModalVisible(true);
    fetchFollowers();
  };

  const showFollowings = () => {
    if (loading || !user.followings || user.followings === 0) return;

    setFollowingsModalVisible(true);
    fetchFollowings();
  };

  const followUser = async () => {
    if (followingInProgress) return;

    if (account === undefined) {
      toast('error', 'Please connect your wallet!');
      return;
    }

    setFollowingInProgress(true);
    try {
      const { status, data } = await _followUser(uid, !following, authToken);
      if (status === 'success') {
        const accountDetailsResponse = await getUserAccountDetails(uid);
        setUser(prevState => ({
          ...prevState,
          followers: accountDetailsResponse?.data?.followers,
          followings: accountDetailsResponse?.data?.followings,
        }));
        setFollowing(!following);
      } else {
        toast('error', data);
      }
    } catch (e) {
      console.log(e);
    }
    setFollowingInProgress(false);
  };

  const formatDate = _date => {
    const date = new Date(_date);
    return date.toDateString();
  };

  const renderMedia = image => {
    if (image?.includes('youtube')) {
      return (
        <ReactPlayer
          className={styles.mediaInner}
          url={image}
          controls={true}
          width="100%"
          height="100%"
        />
      );
    } else {
      return (
        <Suspense
          fallback={
            <Loader type="Oval" color="#7F56D9" height={32} width={32} />
          }
        >
          <SuspenseImg className={styles.mediaInner} src={image} />
        </Suspense>
      );
    }
  };

  const renderTab = (label, Icon, idx, count, countLoading) => (
    <div
      className={cx(styles.tab, idx === tab && styles.selected)}
      onClick={() => goToTab(idx)}
    >
      <Icon
        className={styles.tabIcon}
        color={idx === tab ? '#7F56D9' : '#121223'}
      />
      <div
        className={cx(styles.tabLabel, idx === tab && styles.tabLabelSelected)}
      >
        {label}
      </div>
      <div className={styles.tabCount}>
        {countLoading ? (
          <Skeleton className={styles.tabCountLoading} width={40} height={22} />
        ) : (
          count
        )}
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.profile}>
        <div className={styles.wrapper}>
          <div className={styles.banner}>
            {loading ? (
              <Skeleton width="100%" height={200} />
            ) : bannerUrlLink || userBannerLink ? (
              <img
                src={bannerUrlLink || userBannerLink}
                className={styles.bannerImg}
              />
            ) : (
              <div className={styles.bannerPlaceholder}>
                <div className={styles.colorBackground} />
                <div className={styles.bg1}>
                  <LogoCNFT />
                </div>
                <div className={styles.bg2}>
                  <LogoCNFT />
                </div>
                <div className={styles.bg3}>
                  <LogoCNFT />
                </div>
                <div className={styles.bg4}>
                  <LogoCNFT />
                </div>
              </div>
            )}
            {isMe && uid && (
              <div className={styles.editBanner} onClick={selectBanner}>
                <input
                  ref={fileInput}
                  hidden
                  type="file"
                  onChange={handleSelectFile}
                  accept="image/*"
                />
                <img src={iconEdit} alt="Edit" />
              </div>
            )}
          </div>
          <div className={styles.wrapperRow}>
            <div className={styles.avatarWrapper}>
              {loading ? (
                <Skeleton width={160} height={160} className={styles.avatar} />
              ) : user.avatar ? (
                <img src={`${user.avatar}`} className={styles.avatar} />
              ) : uid ? (
                <Identicon className={styles.avatar} account={uid} size={160} />
              ) : (
                <div className={styles.defaultAvatar}>
                  <img src={defaultAvatar} alt={'avatar'} />
                </div>
              )}
            </div>
            <div className={styles.bottomWrapper}>
              <div className={styles.bottomWrapperInner}>
                <div className={styles.usernameWrapper}>
                  {loading ? (
                    <Skeleton width={120} height={24} />
                  ) : (
                    <div className={styles.username}>
                      {user?.alias
                        ? user.alias
                        : userIsNotFound()
                        ? 'User not found'
                        : 'Unnamed'}
                    </div>
                  )}
                </div>
                {uid ? (
                  <div className={styles.addressWrapper}>
                    {loading ? (
                      <Skeleton width={120} height={20} />
                    ) : (
                      <>
                        {uid ? (
                          <Tooltip
                            title={copied ? 'Copied!' : 'Copy'}
                            open={tooltipOpen}
                            arrow
                            classes={{ tooltip: styles.tooltip }}
                          >
                            <div className={styles.address}>
                              {shortenAddress(uid)}
                            </div>
                          </Tooltip>
                        ) : null}
                      </>
                    )}
                    {uid ? (
                      <CopyToClipboard text={uid} onCopy={handleCopyAddress}>
                        <div className={styles.copyIcon}>
                          <img
                            src={iconCopy}
                            onMouseOver={handleMouseOver}
                            onMouseLeave={handleMouseLeave}
                          />
                        </div>
                      </CopyToClipboard>
                    ) : null}
                  </div>
                ) : null}
                <div className={styles.followers} onClick={showFollowers}>
                  {loading ? (
                    <Skeleton width={100} height={24} />
                  ) : (
                    <>
                      <span className={styles.followersNumber}>
                        {formatFollowers(user.followers || 0)}
                      </span>{' '}
                      Followers
                    </>
                  )}
                </div>
                <div className={styles.followers} onClick={showFollowings}>
                  {loading ? (
                    <Skeleton width={100} height={24} />
                  ) : (
                    <>
                      <span className={styles.followersNumber}>
                        {formatFollowers(user.followings || 0)}
                      </span>{' '}
                      Following
                    </>
                  )}
                </div>
                {isMe || !authToken || !account ? null : loading ? (
                  <Skeleton width={80} height={26} style={{ marginLeft: 16 }} />
                ) : (
                  <div
                    className={cx(
                      styles.followBtn,
                      followingInProgress && styles.disabled
                    )}
                    onClick={followUser}
                  >
                    {followingInProgress ? (
                      <ClipLoader color="#FFF" size={14} />
                    ) : following ? (
                      'Unfollow'
                    ) : (
                      'Follow'
                    )}
                  </div>
                )}
              </div>
              <div className={styles.buttonsWrapper}>
                {isMe && (
                  <div
                    className={styles.settings}
                    onClick={openAccountSettings}
                  >
                    <img src={iconSettings} className={styles.settingsIcon} />
                  </div>
                )}
                {uid ? (
                  <div
                    className={styles.settings}
                    onClick={e => setAnchorEl(e.currentTarget)}
                  >
                    <img src={iconShare} className={styles.settingsIcon} />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/*<div className={styles.bio}>{user.bio || ''}</div>*/}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.contentSidebar}>
          <div className={styles.tabsGroup}>
            <div className={styles.groupTitle}>Properties</div>
            {renderTab(
              'My NFTs',
              IconProperty,
              0,
              count + countGaia,
              fetching || fguresFetching
            )}
            {/* {renderTab(
              'Bundles',
              IconBundle,
              1,
              bundleCount,
              bundleFetching || fguresFetching
            )} */}
            {renderTab(
              'Favorites',
              IconHeart,
              2,
              favCount,
              favFetching || fguresFetching
            )}
          </div>
          <div className={styles.tabsGroup}>
            <div className={styles.groupTitle}>Account</div>
            {isMe
              ? renderTab(
                  'Verify Wallet',
                  walletVerified && !connectedWrongWallet()
                    ? IconWallet
                    : IconExclamation,
                  6
                )
              : null}
            {isMe
              ? renderTab(
                  'KYC Verification',
                  kycVerified ? IconKYC : IconExclamation,
                  7
                )
              : null}
            {renderTab('2FA Security', IconSecurity, 8)}
            {renderTab('Activity', IconClock, 3)}
            {renderTab(
              'Received Offers',
              IconReceivedOffers,
              4,
              offersCount,
              offersLoading || fguresFetching
            )}
            {renderTab(
              'Sent Offers',
              IconSentOffers,
              5,
              myOffersCount,
              bidsLoading || fguresFetching
            )}
            {renderTab(
              'Received Bids',
              IconBids,
              9,
              auctionsCount,
              auctionLoading || fguresFetching
            )}
            {renderTab(
              'Placed Bids',
              IconHandMoney,
              10,
              myBidsAuctionCount,
              auctionLoading || fguresFetching
            )}
          </div>
        </div>
        <div ref={ref} className={styles.contentBody} onScroll={handleScroll}>
          {tab === 0 ? (
            <NFTsGrid
              items={tokens.current}
              numPerRow={numPerRow}
              loading={fetching}
            />
          ) : // tab === 1 ? (
          //   <NFTsGrid
          //     items={bundles.current}
          //     numPerRow={numPerRow}
          //     loading={fetching}
          //     showCreate={isMe}
          //     onCreate={handleCreateBundle}
          //   />
          // ) :
          tab === 2 ? (
            <NFTsGrid
              items={likes.current}
              numPerRow={numPerRow}
              loading={fetching}
              onLike={() => {
                likes.current = [];
                fetchLikes(0);
              }}
              showFavoriteIcon={true}
              forFavoritedTab={true}
            />
          ) : tab === 3 ? (
            <table className={styles.table}>
              <thead>
                <tr className={styles.activityHeader}>
                  <th>Event</th>
                  <th>Item</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Owner</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody className={styles.activityList}>
                {(activityLoading ? new Array(5).fill(null) : activities).map(
                  (activity, idx) => (
                    <tr key={idx} className={styles.activity}>
                      <td className={styles.event}>
                        {activity ? (
                          activity.event
                        ) : (
                          <Skeleton width={100} height={20} />
                        )}
                      </td>
                      {activity ? (
                        <td>
                          <Link
                            to={`/explore/${activity.contractAddress}/${activity.tokenID}`}
                            className={styles.name}
                          >
                            <div className={styles.media}>
                              {renderMedia(
                                activity.thumbnailPath.length > 10
                                  ? `${storageUrl}/image/${activity.thumbnailPath}`
                                  : activity.imageURL
                              )}
                            </div>
                            {activity.name}
                          </Link>
                        </td>
                      ) : (
                        <td className={styles.name}>
                          <Skeleton width={120} height={20} />
                        </td>
                      )}
                      <td className={styles.price}>
                        {activity ? (
                          <>
                            <div className={styles.tokenLogo}>
                              <img src={activity.token?.icon} />
                            </div>
                            {activity.price}
                            &nbsp;
                            <p>{activity.token?.symbol}</p>
                          </>
                        ) : (
                          <Skeleton width={100} height={20} />
                        )}
                      </td>
                      <td className={styles.quantity}>
                        {activity ? (
                          activity.quantity
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </td>
                      {activity ? (
                        activity.to ? (
                          <td>
                            <Link
                              to={`/account/${activity.to}`}
                              className={styles.owner}
                            >
                              <div className={styles.ownerAvatarWrapper}>
                                {activity.image ? (
                                  <img
                                    src={`https://cloudflare-ipfs.com/ipfs/${activity.image}`}
                                    className={styles.ownerAvatar}
                                  />
                                ) : (
                                  <Identicon
                                    account={activity.to}
                                    size={24}
                                    className={styles.ownerAvatar}
                                  />
                                )}
                              </div>
                              {activity.alias || shortenAddress(activity.to)}
                            </Link>
                          </td>
                        ) : (
                          <td className={styles.owner} />
                        )
                      ) : (
                        <td className={styles.owner}>
                          <Skeleton width={130} height={20} />
                        </td>
                      )}
                      <td className={styles.date}>
                        {activity ? (
                          formatDate(activity.createdAt)
                        ) : (
                          <Skeleton width={120} height={20} />
                        )}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          ) : tab === 4 ? (
            <table className={styles.table}>
              <SettingOption
                checked={hideExpiredReceivedOffers}
                onChange={e => setHideExpiredReceivedOffers(e.target.checked)}
                name="Expired On"
                title="Hide expired offers"
              />
              <thead>
                <tr className={styles.activityHeader}>
                  <th className={styles.headerName}>Item</th>
                  <th className={styles.headerTo}>From</th>
                  <th className={styles.headerPrice}>Price</th>
                  <th>Quantity</th>
                  <th className={styles.headerDate}>Date Listed</th>
                  <th className={styles.headerSwitch}>Expired On</th>
                </tr>
              </thead>
              <tbody className={styles.activityList}>
                {(offersLoading
                  ? new Array(5).fill(null)
                  : offers.filter(
                      offer =>
                        !hideExpiredReceivedOffers ||
                        offer.deadline > now.getTime()
                    )
                ).map((offer, idx) => (
                  <tr key={idx} className={styles.activity}>
                    {offer ? (
                      <td>
                        <Link
                          to={`/explore/${offer.contractAddress}/${offer.tokenID}#offer_${offer.creator}`}
                          className={styles.name}
                        >
                          <div className={styles.media}>
                            {renderMedia(
                              offer.thumbnailPath.length > 10
                                ? `${storageUrl}/image/${offer.thumbnailPath}`
                                : offer.imageURL
                            )}
                          </div>
                          {offer.name}
                        </Link>
                      </td>
                    ) : (
                      <td className={styles.name}>
                        <Skeleton width={120} height={20} />
                      </td>
                    )}
                    {offer ? (
                      <td>
                        <Link
                          to={`/account/${offer.creator}`}
                          className={styles.owner}
                        >
                          <div className={styles.ownerAvatarWrapper}>
                            {offer.image ? (
                              <img
                                src={`https://cloudflare-ipfs.com/ipfs/${offer.image}`}
                                className={styles.ownerAvatar}
                              />
                            ) : (
                              <Identicon
                                account={offer.creator}
                                size={24}
                                className={styles.ownerAvatar}
                              />
                            )}
                          </div>
                          {offer.alias || shortenAddress(offer.creator)}
                        </Link>
                      </td>
                    ) : (
                      <td className={styles.owner}>
                        <Skeleton width={130} height={20} />
                      </td>
                    )}
                    <td className={styles.price}>
                      {offer ? (
                        <>
                          <div className={styles.tokenLogo}>
                            <img src={offer.token?.icon} />
                          </div>
                          {offer.pricePerItem}
                          &nbsp;
                          <p>{offer.token?.symbol}</p>
                        </>
                      ) : (
                        <Skeleton width={100} height={20} />
                      )}
                    </td>
                    <td className={styles.quantity}>
                      {offer ? (
                        offer.quantity
                      ) : (
                        <Skeleton width={80} height={20} />
                      )}
                    </td>
                    <td className={styles.date}>
                      {offer ? (
                        new Date(offer.createdAt).toDateString()
                      ) : (
                        <Skeleton width={120} height={20} />
                      )}
                    </td>
                    <td className={styles.date}>
                      {offer ? (
                        formatDate(offer.deadline)
                      ) : (
                        <Skeleton width={120} height={20} />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : tab === 5 ? (
            <table className={styles.table}>
              <SettingOption
                checked={hideExpiredSentOffers}
                onChange={e => setHideExpiredSentOffers(e.target.checked)}
                name="Expired On"
                title="Hide expired offers"
              />
              <thead>
                <tr className={styles.activityHeader}>
                  <th className={styles.headerName}>Item</th>
                  <th className={styles.headerPrice}>Price</th>
                  <th>Quantity</th>
                  <th className={styles.headerDate}>Date Listed</th>
                  <th className={styles.headerSwitch}>Expired On</th>
                  <th className={styles.headerButton}>Withdraw Offer</th>
                </tr>
              </thead>
              <tbody className={styles.activityList}>
                {(bidsLoading
                  ? new Array(5).fill(null)
                  : bids.filter(
                      bid =>
                        !hideExpiredSentOffers || bid.deadline > now.getTime()
                    )
                ).map((bid, idx) => (
                  <tr key={idx} className={styles.activity}>
                    {bid ? (
                      <td>
                        <Link
                          to={`/explore/${bid.contractAddress}/${bid.tokenID}`}
                          className={styles.name}
                        >
                          <div className={styles.media}>
                            {renderMedia(
                              bid.thumbnailPath.length > 10
                                ? `${storageUrl}/image/${bid.thumbnailPath}`
                                : bid.imageURL
                            )}
                          </div>
                          {bid.name}
                        </Link>
                      </td>
                    ) : (
                      <td className={styles.name}>
                        <Skeleton width={120} height={20} />
                      </td>
                    )}
                    <td className={styles.price}>
                      {bid ? (
                        <>
                          <div className={styles.tokenLogo}>
                            <img src={bid.token?.icon} />
                          </div>
                          {bid.pricePerItem}
                          &nbsp;
                          <p>{bid.token?.symbol}</p>
                        </>
                      ) : (
                        <Skeleton width={100} height={20} />
                      )}
                    </td>
                    <td className={styles.quantity}>
                      {bid ? bid.quantity : <Skeleton width={80} height={20} />}
                    </td>
                    <td className={styles.date}>
                      {bid ? (
                        formatDate(bid.createdAt)
                      ) : (
                        <Skeleton width={120} height={20} />
                      )}
                    </td>
                    <td className={styles.date}>
                      {bid ? (
                        new Date(bid.deadline).toDateString()
                      ) : (
                        <Skeleton width={120} height={20} />
                      )}
                    </td>
                    <td className={styles.date}>
                      {bid ? (
                        <TxButton
                          className={cx(
                            styles.makeOffer,
                            offerCanceling && styles.disabled
                          )}
                          onClick={() =>
                            handleCancelOffer(bid.contractAddress, bid.tokenID)
                          }
                        >
                          Withdraw Offer
                        </TxButton>
                      ) : (
                        <Skeleton width={120} height={20} />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : tab === 6 ? (
            <div className={styles.walletVerificationContainer}>
              {isMe && connectedWrongWallet() ? (
                <>
                  <div className={styles.walletVerificationHeader}>
                    Your account is already verified with another wallet
                  </div>
                  <div className={styles.walletVerificationSubtitle}>
                    Please change your wallet to
                  </div>
                  <div className={styles.walletAddress}>{uid}</div>
                  <IconExclamation
                    className={styles.walletVerificationOkIcon}
                  />
                </>
              ) : walletVerified ? (
                <>
                  <div className={styles.walletVerificationHeader}>
                    Your Wallet is Verified
                  </div>
                  <IconCheckBig />
                  <div className={styles.walletVerificationLabel}>
                    Wallet address
                  </div>
                  <div className={styles.walletAddress}>{uid}</div>
                </>
              ) : walletIsVerifiedByAnotherUser ? (
                <>
                  <div className={styles.walletVerificationHeader}>
                    Connected wallet is already verified by another user
                  </div>
                  <div className={styles.walletVerificationSubtitle}>
                    Please use another wallet
                  </div>
                  <IconExclamation
                    className={styles.walletVerificationOkIcon}
                  />
                </>
              ) : (
                <>
                  <div className={styles.walletVerificationHeader}>
                    Please Verify your Wallet
                  </div>
                  {me.user_id ? (
                    account ? (
                      <>
                        <div className={styles.walletVerificationSubtitle}>
                          Please authenticate
                        </div>
                        <div className={styles.walletVerificationInfo}>
                          This is a safety feature! By signing this transaction,
                          you are ensuring that <b>only you</b> own the keys to
                          your NFT. No one can remove any assets from
                          LiquidEarth, because
                          <b> only you control the keys to your wallet.</b>
                          <br /> <br /> ⚠️ ⚠️ ⚠️ Warning! You can only verify a
                          single wallet address per user account. Because you
                          will undergo KYC to use LiquidEarth, this address will
                          be permanently attached to your LiquidEarth account.
                          Only verify wallets that you are comfortable using in
                          public. If you lose access to your keys, you can
                          always let us know and file a support case and we will
                          help you reassign your account to a new wallet
                          address.{' '}
                          <a
                            href="https://docs.liquidearth.io/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.learnMoreLink}
                          >
                            Learn More.
                          </a>
                        </div>
                        <div className={styles.tabButtonsWrapper}>
                          <div
                            className={styles.submitButton}
                            onClick={handleVerifyWalllet}
                          >
                            Verify
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className={styles.walletVerificationSubtitle}>
                        Please connect your wallet
                      </div>
                    )
                  ) : (
                    <div className={styles.walletVerificationSubtitle}>
                      You have to log in first
                    </div>
                  )}
                </>
              )}
            </div>
          ) : tab === 7 ? (
            <div className={styles.kycAuthentificationContainer}>
              {isKycReqSent ? (
                <div className={styles.kycAuthentificationTitle}>
                  The authentication request has been sent. This may take a few
                  minutes.
                </div>
              ) : kycVerified ? (
                <>
                  <div className={styles.kycAuthentificationTitle}>KYC</div>
                  <IconCheck className={styles.kycAuthentificationOkIcon} />
                  <div className={styles.kycAuthentificationSubtitle}>
                    You have successfully completed
                    <br />
                    KYC Authentication
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.kycAuthentificationTitle}>KYC</div>
                  <div className={styles.kycAuthentificationSubtitle}>
                    Please authenticate
                  </div>
                  {!showSumSubContainer || isKycVerifiedOnSumsubWebsite ? (
                    <div className={styles.kycAuthentificationInfo}>
                      Due to the risk of fraud associated with real world assets
                      like real estate and collectibles, LiquidEarth is required
                      to KYC all users on the platform.&nbsp;
                      {isKycVerifiedOnSumsubWebsite && (
                        <>
                          <br />
                          Your KYC is currently being reviewed by the
                          LiquidEarth team for secure access. Please check back
                          within 24-48 hours.&nbsp;
                        </>
                      )}
                      <a
                        href="https://docs.liquidearth.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.learnMoreLink}
                      >
                        Learn More.
                      </a>
                    </div>
                  ) : null}
                  {!isKycVerifiedOnSumsubWebsite ? (
                    <>
                      <div className={styles.tabButtonsWrapper}>
                        <div
                          className={styles.submitButton}
                          onClick={handleKYC}
                        >
                          Authenticate
                        </div>
                      </div>
                      <div
                        id="sumsub-websdk-container"
                        className={styles.sumsubContainer}
                      />
                    </>
                  ) : null}
                </>
              )}
            </div>
          ) : tab === 8 ? (
            <div className={styles.securityContainer}>
              <div className={styles.securityHeader}>
                Two-factor Authentication
              </div>
              {isMe && me.is_2fa && (
                <IconCheck className={styles.securityOkIcon} />
              )}
              <div
                className={cx(
                  styles.securitySubtitle,
                  styles.label2FAContainer
                )}
              >
                <p className={cx(styles.label2FA)}>
                  2FA is an extra layer of security used to make sure that
                  people trying to gain access to an online account are who they
                  say they are
                </p>
              </div>
              {isMe && !me.is_2fa && (
                <>
                  <div className={styles.submainSubtitle}>
                    Set Up Google Authenticator
                  </div>
                  <div className={styles.instructionSteps}>
                    <div className={styles.instructionContainer}>
                      <p className={styles.instructionHeader}>Step 1</p>
                      <p className={styles.instructionDescription}>
                        Download and install Google Authenticator on your mobile
                        device
                      </p>
                      <img src={iconGoogleAuth} className={styles.googleIcon} />
                      <div className={styles.buttonContainer}>
                        <a href={GOOGLE_PLAY} target="_blank" rel="noreferrer">
                          <img
                            src={iconGooglePlay}
                            className={styles.buttonImag}
                          />
                        </a>
                        <a href={APP_STORE} target="_blank" rel="noreferrer">
                          <img
                            src={iconAppStore}
                            className={styles.buttonImag}
                          />
                        </a>
                      </div>
                    </div>
                    <div className={styles.instructionContainer}>
                      <p className={styles.instructionHeader}>Step 2</p>
                      <p className={styles.instructionDescription}>
                        Click the <q>+</q> icon in Google Authenticator, scan
                        the QR code on the screen
                      </p>
                      <img src={qrcode} className={styles.qrcodeImg} />
                    </div>
                    <div className={styles.instructionContainer}>
                      <p className={styles.instructionHeader}>Step 3</p>
                      <p className={styles.instructionDescription}>
                        Verify your device
                      </p>
                      <input
                        className={cx(
                          styles.formInputCont,
                          qrValueError && styles.errorValidate
                        )}
                        placeholder="Enter code"
                        type="number"
                        max="999999"
                        min="0"
                        onChange={e => (qrValue.current = e.target.value)}
                        onKeyPress={handleEnterSendQR}
                      />
                      <div
                        className={cx(
                          styles.tabButtonsWrapper,
                          styles.veifyButton
                        )}
                      >
                        <div
                          className={styles.submitButton}
                          onClick={handleSendQRVerification}
                        >
                          Verify And Finish
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : tab === 9 ? (
            <table className={styles.table}>
              <SettingOption
                checked={hideExpiredReceivedBids}
                onChange={e => setHideExpiredReceivedBids(e.target.checked)}
                name="Expired On"
                title="Hide expired auctions"
              />
              <thead>
                <tr className={styles.activityHeader}>
                  <th className={styles.headerName}>Item</th>
                  <th className={styles.headerTo}>From</th>
                  <th className={styles.headerPrice}>Bid</th>
                  <th className={styles.headerDate}>Date Listed</th>
                  <th className={styles.headerSwitch}>Expired On</th>
                </tr>
              </thead>
              <tbody className={styles.activityList}>
                {(auctionLoading
                  ? new Array(5).fill(null)
                  : auctions.filter(
                      auction =>
                        !hideExpiredReceivedBids ||
                        new Date(auction.deadline) > now.getTime()
                    )
                ).map((auction, idx) => (
                  <tr key={idx} className={styles.activity}>
                    {auction ? (
                      <td>
                        <Link
                          to={`/explore/${auction.contractAddress}/${auction.tokenID}`}
                          className={styles.name}
                        >
                          <div className={styles.media}>
                            {renderMedia(
                              auction.thumbnailPath.length > 10
                                ? `${storageUrl}/image/${auction.thumbnailPath}`
                                : auction.imageURL
                            )}
                          </div>
                          {auction.name}
                        </Link>
                      </td>
                    ) : (
                      <td className={styles.name}>
                        <Skeleton width={120} height={20} />
                      </td>
                    )}
                    {auction ? (
                      <td>
                        <Link
                          to={`/account/${auction.creator}`}
                          className={styles.owner}
                        >
                          <div className={styles.ownerAvatarWrapper}>
                            {auction.image ? (
                              <img
                                src={`https://cloudflare-ipfs.com/ipfs/${auction.image}`}
                                className={styles.ownerAvatar}
                              />
                            ) : (
                              <Identicon
                                account={auction.creator}
                                size={24}
                                className={styles.ownerAvatar}
                              />
                            )}
                          </div>
                          {auction.alias || shortenAddress(auction.creator)}
                        </Link>
                      </td>
                    ) : (
                      <td className={styles.owner}>
                        <Skeleton width={130} height={20} />
                      </td>
                    )}
                    <td className={styles.price}>
                      {auction ? (
                        <>
                          <div className={styles.tokenLogo}>
                            <img src={auction.token?.icon} />
                          </div>
                          {auction.pricePerItem}
                          &nbsp;
                          <p>{auction.token?.symbol}</p>
                        </>
                      ) : (
                        <Skeleton width={100} height={20} />
                      )}
                    </td>
                    <td className={styles.date}>
                      {auction ? (
                        formatDate(auction.createdAt)
                      ) : (
                        <Skeleton width={120} height={20} />
                      )}
                    </td>
                    <td className={styles.date}>
                      {auction ? (
                        new Date(auction.deadline).toDateString()
                      ) : (
                        <Skeleton width={120} height={20} />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : tab === 10 ? (
            <table className={styles.table}>
              <SettingOption
                checked={hideExpiredPlacedBids}
                onChange={e => setHideExpiredPlacedBids(e.target.checked)}
                name="Expired On"
                title="Hide expired auctions"
              />
              <thead>
                <tr className={styles.activityHeader}>
                  <th className={styles.headerName}>Item</th>
                  <th className={styles.headerTo}>To</th>
                  <th className={styles.headerPrice}>Bid</th>
                  <th className={styles.headerDate}>Date Listed</th>
                  <th className={styles.headerSwitch}>Expired On</th>
                  <th className={styles.headerButton}>Withdraw Bid</th>
                </tr>
              </thead>
              <tbody className={styles.activityList}>
                {(auctionLoading
                  ? new Array(5).fill(null)
                  : myBidsAuction.filter(
                      auction =>
                        !hideExpiredPlacedBids ||
                        new Date(auction.deadline) > now.getTime()
                    )
                ).map((auction, idx) => (
                  <tr key={idx} className={styles.activity}>
                    {auction ? (
                      <td className={styles.itemName}>
                        <Link
                          to={`/explore/${auction.contractAddress}/${auction.tokenID}`}
                          className={styles.name}
                        >
                          <div className={styles.media}>
                            {renderMedia(
                              auction.thumbnailPath.length > 10
                                ? `${storageUrl}/image/${auction.thumbnailPath}`
                                : auction.imageURL
                            )}
                          </div>
                          {auction.name}
                        </Link>
                      </td>
                    ) : (
                      <td className={styles.name}>
                        <Skeleton width={120} height={20} />
                      </td>
                    )}
                    {auction ? (
                      <td>
                        <Link
                          to={`/account/${auction.name}`}
                          className={styles.owner}
                        >
                          <div className={styles.ownerAvatarWrapper}>
                            {auction.image ? (
                              <img
                                src={`https://cloudflare-ipfs.com/ipfs/${auction.image}`}
                                className={styles.ownerAvatar}
                              />
                            ) : (
                              <Identicon
                                account={auction.creator}
                                size={24}
                                className={styles.ownerAvatar}
                              />
                            )}
                          </div>
                          {auction.alias || shortenAddress(auction.creator)}
                        </Link>
                      </td>
                    ) : (
                      <td className={styles.owner}>
                        <Skeleton width={130} height={20} />
                      </td>
                    )}
                    <td className={styles.price}>
                      {auction ? (
                        <>
                          <div className={styles.tokenLogo}>
                            <img src={auction.token?.icon} />
                          </div>
                          {auction.pricePerItem}
                          &nbsp;
                          <p>{auction.token?.symbol}</p>
                        </>
                      ) : (
                        <Skeleton width={100} height={20} />
                      )}
                    </td>
                    <td className={styles.date}>
                      {auction ? (
                        formatDate(auction.createdAt)
                      ) : (
                        <Skeleton width={120} height={20} />
                      )}
                    </td>
                    <td className={styles.date}>
                      {auction ? (
                        new Date(auction.deadline).toDateString()
                      ) : (
                        <Skeleton width={120} height={20} />
                      )}
                    </td>
                    <td className={styles.date}>
                      {auction &&
                      now.getTime() >=
                        new Date(auction?.deadline).getTime() + 86400000 ? (
                        <TxButton
                          className={cx(
                            styles.makeOffer,
                            bidWithdrawing && styles.disabled
                          )}
                          onClick={() =>
                            handleWithdrawBid(
                              auction.contractAddress,
                              auction.tokenID
                            )
                          }
                        >
                          {bidWithdrawing
                            ? 'Withdrawing Bid...'
                            : 'Withdraw Bid'}
                        </TxButton>
                      ) : (
                        <div>Wait for the auction to end</div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div />
          )}
        </div>
      </div>
      <Footer />
      {uid ? (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          classes={{ paper: styles.shareMenu, list: styles.shareMenuList }}
        >
          <CopyToClipboard text={getLinkToAccount()} onCopy={handleCopyLink}>
            <MenuItem classes={{ root: styles.menuItem }}>
              <img src={iconLink} />
              Copy Link
            </MenuItem>
          </CopyToClipboard>
          <MenuItem
            classes={{ root: styles.menuItem }}
            onClick={handleShareOnFacebook}
          >
            <img src={iconFacebook} />
            Share on Facebook
          </MenuItem>
          <MenuItem
            classes={{ root: styles.menuItem }}
            onClick={handleShareToTwitter}
          >
            <img src={iconTwitter} />
            Share to Twitter
          </MenuItem>
        </Menu>
      ) : null}
      <NewBundleModal
        visible={bundleModalVisible}
        onClose={() => setBundleModalVisible(false)}
        onCreateSuccess={() => {
          bundles.current = [];
          fetchNFTs();
        }}
      />
      <FollowersModal
        visible={followersModalVisible || followingsModalVisible}
        onClose={() => {
          setFollowersModalVisible(false);
          setFollowingsModalVisible(false);
        }}
        title={followersModalVisible ? 'Followers' : 'Followings'}
        users={
          followersLoading
            ? new Array(5).fill(null)
            : followersModalVisible
            ? followers.current
            : followings.current
        }
      />
    </div>
  );
};

export default AccountDetails;
