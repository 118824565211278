import React from 'react';
import { Link } from 'react-router-dom';

import style from './styles.module.scss';
import { NFT_STATUSES } from '../../constants/NFTStatuses.constants';

const NFTStatusColor = {
  Primary: '#7F56D9',
  Secondary: '#FF3434',
};

const AdminDashboardItem = ({
  title,
  subtitle,
  date_created,
  date_purchased,
  status,
  last_price,
  price,
  ticker,
  icon,
  nft_link,
  id,
}) => {
  const getStatusElement = () => {
    let textColor;
    switch (status) {
      case NFT_STATUSES.PENDING_APPROVAL:
        textColor = NFTStatusColor.Primary;
        break;
      case NFT_STATUSES.APPROVED_BY_AGENT_AND_ADMIN:
        textColor = NFTStatusColor.Primary;
        break;
      case NFT_STATUSES.APPROVED_BY_AGENT_AND_DENIED_BY_ADMIN:
        textColor = NFTStatusColor.Secondary;
        break;
      case NFT_STATUSES.APPROVED_BY_AGENT:
        textColor = NFTStatusColor.Primary;
        break;
      case NFT_STATUSES.DENIED_BY_AGENT:
        textColor = NFTStatusColor.Secondary;
        break;
      case NFT_STATUSES.MINTED:
        textColor = NFTStatusColor.Primary;
        break;
      default:
        textColor = NFTStatusColor.Primary;
        break;
    }
    return (
      <div style={{ color: textColor }}>{status ? status : 'No Status'}</div>
    );
  };

  const link =
    status === NFT_STATUSES.MINTED || status === NFT_STATUSES.BURNED
      ? `/explore/${nft_link}`
      : `/pending/${id}`;

  return (
    <Link to={link} className={style.nftLink}>
      <div className={style.AdminDashboardItem}>
        <div className={style.head}>
          <div className={style.img_container}>
            <img src={icon} alt="" />
          </div>
          <div className={style.text_container}>
            <div className={style.title}>{title}</div>
            <div className={style.description}>{subtitle}</div>
          </div>
        </div>
        <div className={style.body}>
          <div className={style.body_item}>
            <div className={style.label}>Date of creation</div>
            <div className={style.date}>{date_created}</div>
          </div>
          <div className={style.body_item}>
            <div className={style.label}>Date of purchase</div>
            <div className={style.date}>{date_purchased}</div>
          </div>
          <div className={style.body_item}>
            <div className={style.label}>Status</div>
            <div className={style.status}>{getStatusElement()}</div>
          </div>
        </div>
        {last_price ? (
          <div className={style.body_item}>
            {' '}
            <div className={style.label}>Last price</div>
            <div className={style.price2}>
              <img src={ticker} alt="" />
              {last_price}
            </div>
          </div>
        ) : null}
        <div className={style.footer}>
          <div className={style.price}>
            <img src={ticker} alt="" />
            {price}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default AdminDashboardItem;
