import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import { ListItem, List } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import cx from 'classnames';
import styles from './styles.module.scss';
import showToast from 'utils/toast';

export const ZERO_POINT = {
  lat: 48.354314,
  lng: -99.997963,
};

const PlacesAutocomplete = ({
  setPointName,
  validateError,
  setGeoPoint,
  initialName,
  initialPosition,
}) => {
  const [markerPosition, setMarkerPosition] = useState();

  useEffect(() => {
    setValue(initialName);
    setMarkerPosition({
      lat: initialPosition?.lat || ZERO_POINT.lat,
      lng: initialPosition?.lng || ZERO_POINT.lng,
    });
  }, [initialName, initialPosition]);

  const [anchorEl, setAnchorEl] = useState(null);

  const {
    clearSuggestions,
    // ready,
    setValue,
    value,
    suggestions: { status, data },
  } = usePlacesAutocomplete();

  const handleSelectLocation = async address => {
    setValue(address, false);
    clearSuggestions();
    setAnchorEl(null);
    setPointName(address);

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = getLatLng(results[0]);
      setMarkerPosition({ lat, lng });
      setGeoPoint({ lat, lng });
    } catch (error) {
      showToast(error);
      setMarkerPosition(ZERO_POINT);
      setGeoPoint(ZERO_POINT);
    }
  };

  const showlePopover = event => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    }
  };

  const closePopover = () => {
    if (anchorEl) {
      setMarkerPosition(ZERO_POINT);
      setGeoPoint(ZERO_POINT);
      setAnchorEl(null);
    }
  };

  const enterPress = e => {
    if (e.key === 'Enter') {
      setPointName(e.target.value);
      closePopover();
    }
  };

  const openPopover = Boolean(anchorEl);

  return (
    <ClickAwayListener onClickAway={closePopover}>
      <div>
        <input
          aria-describedby={openPopover ? 'auto-popover' : undefined}
          className={cx(
            styles.formInput,
            validateError.name && styles.errorValidate
          )}
          placeholder="Enter Address"
          value={value}
          onChange={e => {
            setValue(e.target.value);
            setPointName(e.target.value);
          }}
          onFocus={showlePopover}
          onKeyPress={enterPress}
        />
        <Popper
          id={'auto-popover'}
          anchorEl={anchorEl}
          open={openPopover}
          placement={'bottom-start'}
          className={styles.geoPopper}
        >
          {status === 'OK' && (
            <List>
              {data.map(suggestion => {
                const {
                  description,
                  place_id,
                  structured_formatting: { main_text, secondary_text },
                } = suggestion;

                return (
                  <ListItem
                    key={place_id}
                    button
                    onClick={() => handleSelectLocation(description)}
                  >
                    <p>
                      <strong>{main_text}</strong>
                      <small> {secondary_text}</small>
                    </p>
                  </ListItem>
                );
              })}
            </List>
          )}
        </Popper>
        <GoogleMap
          center={markerPosition}
          zoom={10}
          mapContainerClassName={styles.mapContainer}
        >
          {markerPosition && <Marker position={markerPosition} />}
        </GoogleMap>
      </div>
    </ClickAwayListener>
  );
};

export default PlacesAutocomplete;
