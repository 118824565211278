import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
// import { ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core';
// import { Client } from '@bandprotocol/bandchain.js';

import AccountModal from './AccountModal';
import NotFound from './NotFound';
import PaintBoard from './PaintBoard';
import LandingPage from '../pages/landingpage';
import ExplorePage from '../pages/explorepage';
import AccountDetails from '../pages/AccountDetails';
import NFTItem from '../pages/NFTItem';
import GaiaNFTItem from '../pages/GaiaNFTItem';
import NotificationSetting from '../pages/NotificationSetting';
// import PriceActions from 'actions/price.actions';
import SignIn from '../pages/Auth/SignIn';
import SignUp from '../pages/Auth/SignUp';
import ResetPassword from '../pages/Auth/ResetPassword';
import NewPassword from '../pages/Auth/NewPassword';
import ConfirmAccount from '../pages/Auth/ConfirmAccount';
import Admin from '../pages/Admin';
import NeedToBeLoggedRoute from './NeedToBeLoggedRoute';
import ProfileRouter from './ProfileRouter';
import { useApi } from '../api';
import WrongWalletModal from './WrongWalletModal';
import TermsOfService from '../pages/TermsOfService';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import AdminOrAgentRoute from './AdminOrAgentRoute';
import TwoFARouter from './TwoFARouter';
import ModalActions from '../actions/modal.actions';
import { USER_ROLES } from '../constants/userRoles.constants';
import { useLoadScript } from '@react-google-maps/api';
import MapsActions from '../actions/maps.actions';

const googleLibraries = ['places'];

const App = () => {
  const dispatch = useDispatch();
  const { authToken, user } = useSelector(state => state.Auth);
  const { account } = useWeb3React();
  const { getUserWalletAddress } = useApi();

  // const [priceInterval, setPriceInterval] = useState(null);
  const [wrongWalletModalVisible, setWrongWalletModalVisible] = useState(false);
  const [userWalletAddress, setUserWalletAddress] = useState(null);
  const { connectWalletModalVisible } = useSelector(state => state.Modal);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API,
    libraries: googleLibraries,
  });

  useEffect(() => dispatch(MapsActions.setMapsLoadState(isLoaded)), [isLoaded]);

  // const getPrice = async () => {
  //   try {
  //     // const endpoint = 'https://rpc.bandchain.org';
  //     // const client = new Client(endpoint);
  //     // const resp = await client.getReferenceData(['FTM/USD', 'BTC/USD']);
  //     // console.log({ resp });
  //     // dispatch(PriceActions.updatePrice(resp.rate));
  //     const provider = new ethers.providers.JsonRpcProvider(
  //       'https://rpc.ftm.tools/',
  //       250
  //     );
  //     const oracle = new ethers.Contract(
  //       process.env.REACT_APP_ORACLE_FTM_TOKEN,
  //       [
  //         {
  //           inputs: [],
  //           name: 'latestAnswer',
  //           outputs: [{ internalType: 'int256', name: '', type: 'int256' }],
  //           stateMutability: 'view',
  //           type: 'function',
  //         },
  //       ],
  //       provider
  //     );
  //     const _price = await oracle.latestAnswer();
  //     const price = parseFloat(_price.toString()) / 10 ** 8;
  //     dispatch(PriceActions.updatePrice(price));
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   if (priceInterval) {
  //     clearInterval(priceInterval);
  //   }

  //   getPrice();
  //   setPriceInterval(setInterval(getPrice, 1000 * 10));
  // }, [chainId]);

  useEffect(() => {
    if (account && authToken) {
      getUserWalletAddress(authToken).then(
        userWalletAddress => {
          if (
            user?.role_id !== USER_ROLES.ADMIN &&
            user?.role_id !== USER_ROLES.ESCROW_AGENT &&
            userWalletAddress &&
            userWalletAddress.toLowerCase() !== account.toLowerCase()
          ) {
            setWrongWalletModalVisible(true);
            setUserWalletAddress(userWalletAddress);
          } else {
            setWrongWalletModalVisible(false);
            setUserWalletAddress(null);
            if (connectWalletModalVisible) {
              dispatch(ModalActions.hideConnectWalletModal());
            }
          }
        },
        () => {
          setWrongWalletModalVisible(false);
          setUserWalletAddress(null);
        }
      );
    }
  }, [account, authToken]);

  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <TwoFARouter exact path="/explore" component={ExplorePage} />
          <TwoFARouter path="/explore/:addr/:id" component={NFTItem} />
          <Route path="/auth/sign-in" component={SignIn} />
          <Route path="/auth/sign-up" component={SignUp} />
          <Route path="/terms-of-service" component={TermsOfService} />
          <Route path="/privacy" component={PrivacyPolicy} />
          <Route
            path="/auth/confirm-account/:token"
            component={ConfirmAccount}
          />
          <Route exact path="/auth/reset-password" component={ResetPassword} />
          <Route path="/auth/reset-password/:token" component={NewPassword} />
          <Route path="/account/:uid" component={AccountDetails} />
          <NeedToBeLoggedRoute path="/pending/:id" component={GaiaNFTItem} />
          <NeedToBeLoggedRoute
            path="/create/:createdNftId?"
            component={PaintBoard}
          />
          <ProfileRouter path="/profile" component={AccountDetails} />
          <NeedToBeLoggedRoute
            path="/settings/notification"
            component={NotificationSetting}
          />
          <AdminOrAgentRoute path="/admin/dashboard/items" component={Admin} />
          <AdminOrAgentRoute
            path="/admin/dashboard/referral-system"
            component={Admin}
          />
          <Route path="/404" component={NotFound} />
          <Route path="*">
            <Redirect to="/404" />
          </Route>
        </Switch>
        <AccountModal />
        <WrongWalletModal
          visible={wrongWalletModalVisible}
          onClose={() => setWrongWalletModalVisible(false)}
          userWalletAddress={userWalletAddress}
        />
        <Toaster position="top-right" reverseOrder={false} />
      </Router>
    </div>
  );
};

export default App;
