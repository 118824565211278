import React, { useState, useRef } from 'react';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { useApi } from 'api';
import { LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';
import cx from 'classnames';
import toast from 'utils/toast';

const HightProgress = withStyles({
  root: {
    height: 10,
    borderRadius: 5,
    margin: 5,
  },
  bar: {
    borderRadius: 5,
  },
})(LinearProgress);

const InputPdfFile = ({
  label,
  assetType = 'attachments',
  changeFunction,
  multiple = true,
}) => {
  const [progressUpload, setProgressUpload] = useState({});
  const authToken = useSelector(state => state.Auth.authToken);
  const fileRef = useRef();

  const { uploadFile } = useApi();

  const changeHandler = async files => {
    if (files.length < 1) return;
    setProgressUpload(prev => {
      const uploadingBars = files.reduce((obj, file) => {
        obj[file.name] = 0;
        return obj;
      }, {});
      return { ...prev, ...uploadingBars };
    });
    const progressBarCb = fileName => newValue => {
      setProgressUpload(prev => {
        return { ...prev, [fileName]: newValue };
      });
    };
    const completeCb = fileName => response => {
      setProgressUpload(prev => {
        const afterUpload = Object.keys(prev)
          .filter(k => k !== fileName)
          .reduce((obj, key) => {
            obj[key] = prev[key];
            return obj;
          }, {});
        return afterUpload;
      });
      if (response?.error || !response?.data?.aswLink) {
        toast('error', response.error);
        return;
      }
      const asset = response.data.aswLink;
      changeFunction(prevState => {
        const result = multiple ? [...prevState, asset] : [asset];
        return result;
      });
      toast('success', 'File saved', '', null, false, 3000);
    };
    files.map(file => {
      return uploadFile(
        authToken,
        assetType,
        file,
        progressBarCb(file.name),
        completeCb(file.name)
      );
    });
  };

  const bars = Object.entries(progressUpload).map(el => {
    return (
      !!el[1] && (
        <HightProgress variant="determinate" key={el[0]} value={el[1]} />
      )
    );
  });

  const waitingUpload = !multiple && Object.keys(progressUpload).length !== 0;

  const { getRootProps, getInputProps, isFocused, isDragAccept } = useDropzone({
    multiple,
    onDrop: changeHandler,
    maxSize: 15728640,
    noClick: true,
    accept: '.pdf',
  });

  return (
    <div {...getRootProps({ className: styles.formGroup })}>
      <div className={styles.formInput}>
        <div
          className={cx(styles.board, waitingUpload && styles.waitingUpload)}
          onClick={() => fileRef.current?.click()}
        >
          <div
            className={cx(
              styles.uploadFile,
              isFocused && styles.isFocused,
              isDragAccept && styles.isDragAccept
            )}
          >
            <input {...getInputProps()} ref={fileRef} />
            <div className={styles.uploadtitle}>+</div>
          </div>
          <p className={styles.formNotation}>{`Add ${label}`}</p>
        </div>
        {bars}
      </div>
    </div>
  );
};

export default InputPdfFile;
