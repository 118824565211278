import React, { useState, useEffect } from 'react';
import Header from 'components/header';
import styles from './../styles.module.scss';
import cx from 'classnames';
import { ClipLoader } from 'react-spinners';
import { useHistory } from 'react-router';
import { useApi } from 'api';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Background from '../../../components/Background';
import toast from 'utils/toast';
import Footer from '../../../components/Footer';
import closeIcon from '../../../assets/svgs/v2/close.svg';

const ResetPassword = ({ isRegister }) => {
  const { postResetPassword } = useApi();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [reseting, setReseting] = useState(false);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  const onClose = () => {
    window.scrollTo(0, 0);
    history.push('/');
  };

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 0);
    }
  }, [isLoading]);

  const validateEmail = () => {
    if (email.length === 0) {
      setEmailError("Email field can't be blank");
    } else {
      setEmailError(null);
    }
  };
  const handleResetPassword = async () => {
    if (reseting) return;

    setReseting(true);
    const response = await postResetPassword(email);
    setReseting(false);
    if (response?.error) {
      toast('error', response?.error);
    } else {
      toast('success', 'Reset password email has been successfully sent');
      window.scrollTo(0, 0);
      history.push('/');
    }
  };
  useEffect(() => {
    setEmail('');
    setEmailError(null);
    /*
    if (isRegister) {
      // Log out
    }
    */
  }, [isRegister]);
  const isValid = (() => {
    if (emailError) return false;
    return true;
  })();
  return (
    <div className={styles.outerContainer}>
      <div className={styles.authContainer}>
        <Background />
        <Header border />
        <div className={cx(styles.inner, styles.inner700)}>
          <form className={styles.authForm}>
            <div className={styles.closeButton} onClick={onClose}>
              <img src={closeIcon} />
            </div>
            <div className={styles.title}>Password Recovery</div>
            <div className={styles.subtitle}>
              No worries, you just need to type your email address and we will
              send you recovery email.
            </div>
            <div className={styles.inputGroup}>
              <div className={styles.inputWrapper}>
                <OutlinedInput
                  className={cx(styles.input, emailError && styles.hasError)}
                  placeholder="Email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  onBlur={validateEmail}
                />
                {emailError && <div className={styles.error}>{emailError}</div>}
              </div>
            </div>
            <div className={styles.buttonsWrapper}>
              <div
                className={cx(styles.submitButton, !isValid && styles.disabled)}
                onClick={isValid ? handleResetPassword : null}
              >
                {reseting ? <ClipLoader color="#FFF" size={16} /> : 'Send'}
              </div>
              <div className={styles.formLabelRow}>
                <div className={styles.labelLink} onClick={onClose}>
                  Cancel
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default ResetPassword;
