import axios from 'axios';

axios.interceptors.response.use(
  config => {
    return config;
  },
  error => {
    return {
      status: error?.response?.status,
      error: error?.response?.data?.error,
    };
  }
);

//eslint-disable-next-line no-undef
//const isMainnet = process.env.REACT_APP_ENV === 'MAINNET';
const isMainnet = true;

export const useApi = () => {
  const explorerUrl =
    process.env.REACT_APP_NETWORK_CHAIN_ID === '4002'
      ? 'https://testnet.ftmscan.com'
      : 'https://ftmscan.com';

  const apiUrl = isMainnet
    ? process.env.REACT_APP_API_URL
    : 'https://api.testnet.artion.io';

  // eslint-disable-next-line no-undef
  // const apiUrl = process.env.REACT_APP_API_URI;
  const storageUrl = isMainnet
    ? 'https://storage.artion.io'
    : 'https://storage.testnet.artion.io';

  // const tokenURL = 'https://fetch-tokens.vercel.app/api';
  // const tokenURL = 'https://api.artion.io/nftitems/fetchTokens';
  // eslint-disable-next-line no-undef
  const gaiaApiUrl = process.env.REACT_APP_GAIA_BACKEND;

  const postLogin = async (login, password) => {
    const res = await axios({
      method: 'post',
      url: `${gaiaApiUrl}/auth/login`,
      data: JSON.stringify({ email: login, password: password }),
      headers: { 'Content-Type': 'application/json' },
    });
    console.log(res);
    if (res?.error) {
      throw new Error(res.error);
    } else if (res?.data?.status == 'success') {
      let token = res.data.token;
      return token;
    }
    return res.data;
  };

  const postSignUp = async (name, login, password) => {
    const res = await axios({
      method: 'post',
      url: `${gaiaApiUrl}/auth/registration`,
      data: JSON.stringify({ name: name, email: login, password: password }),
      headers: { 'Content-Type': 'application/json' },
    });
    return res;
  };

  const resendVerificationEmail = async email => {
    const res = await axios({
      method: 'post',
      url: `${gaiaApiUrl}/auth/resend_verification_email`,
      data: JSON.stringify({ email: email }),
      headers: { 'Content-Type': 'application/json' },
    });
    return res.data;
  };

  const postConfirmAccount = async confirmToken => {
    const res = await axios({
      method: 'post',
      url: `${gaiaApiUrl}/auth/confirm`,
      data: JSON.stringify({ token: confirmToken }),
      headers: { 'Content-Type': 'application/json' },
    });
    return res.data;
  };

  const postResetPassword = async email => {
    try {
      const res = await axios({
        method: 'post',
        url: `${gaiaApiUrl}/auth/reset_password`,
        data: JSON.stringify({ email: email }),
        headers: { 'Content-Type': 'application/json' },
      });
      if (res.status === 200) {
        return null;
      }
      return { error: res.error };
    } catch (error) {
      return { error: error?.response?.data?.error };
    }
  };

  const postSetNewPassword = async (password, authToken) => {
    try {
      const res = await axios({
        method: 'post',
        url: `${gaiaApiUrl}/auth/accept_password`,
        data: JSON.stringify({ password, authToken }),
        headers: { 'Content-Type': 'application/json' },
      });
      if (res.status === 200) {
        return res?.data;
      }
      return { error: res.error };
    } catch (error) {
      return { error: error?.response?.data?.error };
    }
  };

  const verifyWallet = async (signature, address, authToken) => {
    const res = await axios({
      method: 'post',
      url: `${gaiaApiUrl}/users/me/verify_metamask?address=${address}&signature=${signature}`,
      headers: {
        'Content-Type': 'application/json',
        token: authToken,
      },
    });
    return res.data;
  };

  const sumsubToken = async authToken => {
    const res = await axios({
      method: 'get',
      url: `${gaiaApiUrl}/users/me/sumsub_token`,
      headers: {
        'Content-Type': 'application/json',
        token: authToken,
      },
    });
    if (res.status === 200 && res.data) {
      return { message: res.data.message, data: res.data.data };
    }
    return { error: res.error };
  };

  /**
   * Checks if user was KYC verified on sumsub website
   */
  const sumsubStatus = async authToken => {
    const res = await axios({
      method: 'get',
      url: `${gaiaApiUrl}/users/me/sumsub_status`,
      headers: {
        'Content-Type': 'application/json',
        token: authToken,
      },
    });

    return !!res?.data?.isVerified;
  };

  const postUpdateUser = async (
    authToken,
    userId,
    name,
    bio,
    avatar,
    banner
  ) => {
    const res = await axios({
      method: 'put',
      url: `${gaiaApiUrl}/users/${userId}`,
      data: JSON.stringify({ name, bio, avatar, banner }),
      headers: { 'Content-Type': 'application/json', token: authToken },
    });
    if (res.status === 200 && res?.data) {
      return res.data;
    }
    return null;
  };

  const getNonce = async (address, authToken) => {
    const res = await axios({
      method: 'get',
      url: `${apiUrl}/account/nonce/${address}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  };

  const getIsModerator = async address => {
    const { data } = await axios({
      method: 'get',
      url: `${apiUrl}/mod/isModerator/${address}`,
    });
    if (data.status == 'success') {
      return data.data;
    }
    return false;
  };

  const getAccountDetails = async authToken => {
    const res = await axios({
      method: 'get',
      url: `${apiUrl}/account/getaccountinfo`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return res.data;
  };

  const getUserIsKycVerified = async authToken => {
    const res = await axios({
      method: 'get',
      url: `${apiUrl}/info/getUserIsKycVerified`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    if (res?.data?.status == 'success') {
      return res?.data?.data;
    }
    return false;
  };

  const getUserAccountDetails = async address => {
    const data = { address };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/account/getuseraccountinfo`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return res.data;
  };

  const getUserFigures = async address => {
    const res = await axios({
      method: 'get',
      url: `${apiUrl}/info/getFigures/${address}`,
    });

    return res.data;
  };

  const updateAccountDetails = async (
    alias,
    email,
    bio,
    avatar,
    authToken,
    signature,
    signatureAddress
  ) => {
    const formData = new FormData();
    formData.append('alias', alias);
    formData.append('email', email);
    if (bio) {
      formData.append('bio', bio);
    }
    if (avatar) {
      formData.append('imgData', avatar);
    }
    formData.append('signature', signature);
    formData.append('signatureAddress', signatureAddress);

    const res = await axios({
      method: 'post',
      url: `${apiUrl}/account/accountdetails`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  };

  const updateBanner = async (imageData, authToken) => {
    const formData = new FormData();
    formData.append('imgData', imageData);
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/ipfs/uploadBannerImage2Server`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  };

  const get1155Info = async (contractAddress, tokenID) => {
    const { data } = await axios.get(
      `${apiUrl}/info/get1155info/${contractAddress}/${tokenID}`
    );
    return data;
  };

  const getTokenHolders = async (contractAddress, tokenID) => {
    const { data } = await axios.get(
      `${apiUrl}/info/getOwnership/${contractAddress}/${tokenID}`
    );
    return data;
  };

  const fetchCollections = async () => {
    const res = await axios.get(`${apiUrl}/info/getcollections`);
    return res.data;
  };

  const fetchCollection = async contractAddress => {
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/collection/getCollectionInfo`,
      data: JSON.stringify({ contractAddress }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res.data;
  };

  const fetchPendingCollections = async authToken => {
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/collection/getReviewApplications`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  };

  const approveCollection = async (contractAddress, authToken) => {
    const data = {
      contractAddress,
      status: 1,
    };
    await axios({
      method: 'post',
      url: `${apiUrl}/collection/reviewApplication`,
      data: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });
  };

  const rejectCollection = async (contractAddress, reason, authToken) => {
    const data = {
      contractAddress,
      status: 0,
      reason,
    };
    await axios({
      method: 'post',
      url: `${apiUrl}/collection/reviewApplication`,
      data: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });
  };

  const fetchAllCollections = async authToken => {
    const res = await axios({
      method: 'get',
      url: `${apiUrl}/collection/fetchAllCollections`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return res.data;
  };

  const fetchTokens = async (
    from,
    count,
    type = 'all',
    collections = [],
    category = null,
    sortBy = 'listedAt',
    filterBy = [],
    address = null,
    cancelToken
  ) => {
    const data = { from, count, type };
    if (collections.length > 0) {
      data.collectionAddresses = collections;
    }
    if (category !== null) {
      data.category = category;
    }
    if (address) {
      data.address = address;
    }
    if (filterBy.length) {
      data.filterby = filterBy;
    }
    data.sortby = sortBy;
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/nftitems/fetchTokens`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
      cancelToken,
    });
    return res.data;
  };

  const getItemsLiked = async (items, authToken, cancelToken) => {
    const data = { items: JSON.stringify(items) };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/like/getPageLiked`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      cancelToken,
    });
    return res.data;
  };

  const getBundleDetails = async bundleID => {
    const data = { bundleID };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/bundle/getBundleByID`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res.data;
  };

  const increaseBundleViewCount = async bundleID => {
    const data = { bundleID };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/bundle/increaseViews`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res.data;
  };

  const fetchItemDetails = async (contractAddress, tokenID) => {
    const data = { contractAddress, tokenID };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/nftItems/getSingleItemDetails`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res.data;
  };

  const increaseViewCount = async (contractAddress, tokenID) => {
    const data = { contractAddress, tokenID };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/nftitems/increaseViews`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res.data;
  };

  const getBundleOffers = async bundleID => {
    const data = { bundleID };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/offer/getBundleOffer`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res.data;
  };

  const getBundleTradeHistory = async bundleID => {
    const data = { bundleID };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/tradehistory/getBundleTradeHistory`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res.data;
  };

  const getTransferHistory = async (address, tokenID, tokenType) => {
    const data = { address, tokenID };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/nftitems/transfer${tokenType}History`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res.data;
  };

  const getAccountActivity = async address => {
    const res = await axios({
      method: 'get',
      url: `${apiUrl}/info/getAccountActivity/${address}`,
    });
    return res.data;
  };

  const getActivityFromOthers = async address => {
    const res = await axios({
      method: 'get',
      url: `${apiUrl}/info/getActivityFromOthers/${address}`,
    });
    return res.data;
  };

  const getMyOffers = async address => {
    const res = await axios({
      method: 'get',
      url: `${apiUrl}/info/getOffersFromAccount/${address}`,
    });
    return res.data;
  };

  const getAuctionBidFromOthers = async address => {
    const res = await axios({
      method: 'get',
      url: `${apiUrl}/info/getAuctionBidFromOthers/${address}`,
    });
    return res.data;
  };

  const getMyBidsAuction = async address => {
    const res = await axios({
      method: 'get',
      url: `${apiUrl}/info/getMyBidsAuction/${address}`,
    });
    return res.data;
  };

  const addMod = async (
    name,
    address,
    authToken,
    signature,
    signatureAddress
  ) => {
    const data = { name, address, signature, signatureAddress };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/mod/add`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  };

  const removeMod = async (address, authToken, signature, signatureAddress) => {
    const data = { address, signature, signatureAddress };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/mod/remove`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  };

  const banCollection = async (
    address,
    authToken,
    signature,
    signatureAddress
  ) => {
    const data = { address, signature, signatureAddress };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/ban/banCollection`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  };

  const unbanCollection = async (
    address,
    authToken,
    signature,
    signatureAddress
  ) => {
    const data = { address, signature, signatureAddress };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/ban/unbanCollection`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  };

  const checkBan = async (address, authToken) => {
    const data = { address };
    const res = await axios({
      method: 'get',
      url: `${apiUrl}/ban/banUser`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });

    let check = res.data;

    return check.status == 'success' ? true : false;
  };

  const banUser = async (address, authToken, signature, signatureAddress) => {
    const data = { address, signature, signatureAddress };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/ban/banUser`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  };

  const unbanUser = async (address, authToken, signature, signatureAddress) => {
    const data = { address, signature, signatureAddress };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/ban/removeBan`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  };

  const banItems = async (
    address,
    tokenIDs,
    authToken,
    signature,
    signatureAddress
  ) => {
    const data = { address, tokenIDs, signature, signatureAddress };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/ban/banItems`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  };

  const boostCollection = async (address, authToken) => {
    const data = { address };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/ban/boostCollection`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  };

  const createBundle = async (name, paymentToken, price, items, authToken) => {
    const data = { name, paymentToken, price, items };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/bundle/createBundle`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  };

  const deleteBundle = async (bundleID, authToken) => {
    const data = { bundleID };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/bundle/removeBundle`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  };

  const getFollowing = async (from, to) => {
    const data = { from, to };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/follow/isFollowing`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res.data;
  };

  const followUser = async (follower, follow, authToken) => {
    const data = { follower, status: follow ? 1 : 0 };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/follow/update`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  };

  const getFollowers = async address => {
    const res = await axios({
      method: 'get',
      url: `${apiUrl}/follow/getFollowers/${address}`,
    });
    return res.data;
  };

  const getFollowings = async address => {
    const res = await axios({
      method: 'get',
      url: `${apiUrl}/follow/getFollowings/${address}`,
    });
    return res.data;
  };

  const getBundleLikes = async bundleID => {
    const res = await axios({
      method: 'get',
      url: `${apiUrl}/bundle/getLikesCount/${bundleID}`,
    });
    return res.data;
  };

  const isLikingItem = async (contractAddress, tokenID, follower) => {
    const data = {
      type: 'nft',
      contractAddress,
      tokenID,
      follower,
    };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/like/isLiked`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res.data;
  };

  const isLikingBundle = async (bundleID, follower) => {
    const data = {
      type: 'bundle',
      bundleID,
      follower,
    };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/like/isLiked`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res.data;
  };

  const likeItem = async (contractAddress, tokenID, authToken) => {
    const data = {
      type: 'nft',
      contractAddress,
      tokenID,
    };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/like/update`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  };

  const likeBundle = async (bundleID, authToken) => {
    const data = {
      type: 'bundle',
      bundleID,
    };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/like/update`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  };

  const getItemLikeUsers = async (contractAddress, tokenID) => {
    const data = {
      type: 'nft',
      contractAddress,
      tokenID,
    };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/like/getLikes`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res.data;
  };

  const getBundleLikeUsers = async bundleID => {
    const data = {
      type: 'bundle',
      bundleID,
    };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/like/getLikes`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res.data;
  };

  const getMyLikes = async (step, address) => {
    const data = { step, address };
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/like/getMyLikes`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res.data;
  };

  const getNotificationSettings = async authToken => {
    const res = await axios({
      method: 'get',
      url: `${apiUrl}/account/getnotificationsettings`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  };

  const updateNotificationSettings = async (settings, authToken) => {
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/account/notificationsettings`,
      data: JSON.stringify({
        settings: settings,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  };

  const addUnlockableContent = async (
    contractAddress,
    tokenID,
    content,
    signature,
    signatureAddress,
    authToken
  ) => {
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/unlockable/addUnlockableContent`,
      data: JSON.stringify({
        contractAddress,
        tokenID,
        content,
        signature,
        signatureAddress,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  };

  const retrieveUnlockableContent = async (
    contractAddress,
    tokenID,
    signature,
    signatureAddress,
    authToken
  ) => {
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/unlockable/retrieveUnlockableContent`,
      data: JSON.stringify({
        contractAddress,
        tokenID,
        signature,
        signatureAddress,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  };

  const getNftsAsAdmin = async (authToken, offset, limit, status, category) => {
    const address = category ? category?.address : '';
    const res = await axios({
      method: 'get',
      url: `${gaiaApiUrl}/nfts/?offset=${offset}&limit=${limit}&status=${status}&category=${address}`,
      headers: {
        'Content-Type': 'application/json',
        token: authToken,
      },
    });
    if (res.status === 200) {
      return [res.data.nfts, res.data.meta.total];
    }
    return res;
  };

  const getReferrals = async (authToken, offset, limit) => {
    const res = await axios({
      method: 'get',
      url: `${gaiaApiUrl}/referrals/?offset=${offset}&limit=${limit}`,
      headers: {
        'Content-Type': 'application/json',
        token: authToken,
      },
    });
    if (res.status === 200) {
      return [res.data.referrals, res.data.meta.total];
    }
    return null;
  };

  const approveNft = async (authToken, contractAddress, tokenId) => {
    const res = await axios({
      method: 'post',
      url: `${gaiaApiUrl}/nfts/${contractAddress}/${tokenId}/approve`,
      headers: {
        'Content-Type': 'application/json',
        token: authToken,
      },
    });
    if (res.status === 200) {
      return res;
    }
    if (res.error) {
      return { error: res.error };
    }
    return null;
  };

  const rejectNft = async (authToken, contractAddress, tokenId, denyReason) => {
    const res = await axios({
      method: 'post',
      url: `${gaiaApiUrl}/nfts/${contractAddress}/${tokenId}/reject`,
      headers: {
        'Content-Type': 'application/json',
        token: authToken,
      },
      data: {
        denyReason,
      },
    });
    if (res.status === 200) {
      return res;
    }
    if (res.status === 403 && res.error) {
      return { error: res.error };
    }
    return null;
  };

  const nftSatus = async (authToken, contractAddress, tokenId) => {
    const res = await axios({
      method: 'get',
      url: `${gaiaApiUrl}/nfts/${contractAddress}/${tokenId}/status`,
      headers: {
        'Content-Type': 'application/json',
        token: authToken,
      },
    });
    if (res.status === 200) {
      return res;
    }
    return null;
  };

  const nftSatusGaia = async (authToken, nftId) => {
    const res = await axios({
      method: 'get',
      url: `${gaiaApiUrl}/nfts/status/${nftId}`,
      headers: {
        'Content-Type': 'application/json',
        token: authToken,
      },
    });
    if (res.status === 200) {
      return res;
    }
    return { status: res.status, error: res.error };
  };

  const getReferralByCode = async (authToken, code) => {
    const res = await axios({
      method: 'get',
      url: `${gaiaApiUrl}/referrals/getByCode/${code}`,
      headers: {
        'Content-Type': 'application/json',
        token: authToken,
      },
    });
    if (res.status === 200) {
      return res;
    }
    return null;
  };

  const approveNftGaia = async (
    authToken,
    nftId,
    escrowFee,
    escrowFeePeriod
  ) => {
    const res = await axios({
      method: 'post',
      url: `${gaiaApiUrl}/nfts/approve/${nftId}`,
      headers: {
        'Content-Type': 'application/json',
        token: authToken,
      },
      data: { escrowFee, escrowFeePeriod },
    });
    if (res.status === 200) {
      return res;
    }
    if (res.status === 403 && res.error) {
      return { error: res.error };
    }
    return null;
  };
  const rejectNftGaia = async (authToken, _, nftId, denyReason) => {
    const res = await axios({
      method: 'post',
      url: `${gaiaApiUrl}/nfts/reject/${nftId}`,
      headers: {
        'Content-Type': 'application/json',
        token: authToken,
      },
      data: {
        denyReason,
      },
    });
    if (res.status === 200) {
      return res;
    }
    if (res.status === 403 && res.error) {
      return { error: res.error };
    }
    return null;
  };
  const uploadFile = async (
    authToken,
    assetType,
    fileData,
    progressCb,
    completeCb
  ) => {
    const formData = new FormData();
    formData.append('data', fileData);
    formData.append('assetType', assetType);
    const res = await axios({
      method: 'post',
      url: `${gaiaApiUrl}/documents/uploadFiles`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        token: authToken,
      },
      onUploadProgress: loadingStatus => {
        progressCb(
          Math.round((100 * loadingStatus.loaded) / loadingStatus.total)
        );
      },
    });
    completeCb(res);
    if (res.error) {
      return res.error;
    }
    return res.data;
  };
  const deleteFile = async (authToken, id) => {
    const res = await axios({
      method: 'delete',
      url: `${gaiaApiUrl}/documents/${id}`,
      headers: {
        'Content-Type': 'application/json',
        token: authToken,
      },
    });
    if (res.error) {
      return res.error;
    }
    return res;
  };
  const registerNft = async (authToken, formData) => {
    const res = await axios({
      method: 'post',
      url: `${gaiaApiUrl}/nfts/register`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        token: authToken,
      },
    });
    if (res.error) {
      return res;
    }
    return res.data;
  };
  const fetchUserNft = async authToken => {
    const res = await axios({
      method: 'get',
      url: `${gaiaApiUrl}/users/me/nfts`,
      headers: {
        'Content-Type': 'application/json',
        token: authToken,
      },
    });
    if (res.error) {
      return res.error;
    }
    return res.data;
  };
  const fetchCreatedNft = async (authToken, nftId) => {
    const res = await axios({
      method: 'get',
      url: `${gaiaApiUrl}/nfts/created/${nftId}`,
      headers: {
        'Content-Type': 'application/json',
        token: authToken,
      },
    });
    if (res.error) {
      return res;
    }
    return res.data;
  };

  const getReferralCode = async (authToken, contractAddress, tokenId) => {
    const res = await axios({
      method: 'get',
      url: `${gaiaApiUrl}/referrals/${contractAddress}/${tokenId}/code`,
      headers: {
        'Content-Type': 'application/json',
        token: authToken,
      },
    });
    if (res.status === 200) {
      return { response: res.data };
    }
    return res;
  };

  const addReferralTrack = async (
    authToken,
    contractAddress,
    tokenId,
    referralCode
  ) => {
    const res = await axios({
      method: 'post',
      url: `${gaiaApiUrl}/referrals/addReferralTrack`,
      headers: {
        'Content-Type': 'application/json',
        token: authToken,
      },
      data: {
        referralCode,
      },
    });
    if (res.status === 200) {
      return res;
    }
  };

  const getCategories = async (limit = 0, useOrderField = false) => {
    const res = await axios({
      method: 'get',
      url: `${apiUrl}/info/getCategories/?useOrderField=${useOrderField}${
        !isNaN(limit) ? `&limit=${limit}` : '&limit=0'
      }`,
    });
    if (res.status === 200) {
      return res.data.data;
    }
    return null;
  };

  const saveTxHash = async (authToken, nftId, txHashData) => {
    const res = await axios({
      method: 'post',
      url: `${gaiaApiUrl}/nfts/txHash/${nftId}`,
      headers: {
        'Content-Type': 'application/json',
        token: authToken,
      },
      data: txHashData,
    });
    return res;
  };

  const getTradeHistoriesWithReferral = async (
    authToken,
    offset = null,
    limit = null
  ) => {
    const res = await axios({
      method: 'get',
      url: `${apiUrl}/tradehistory/getTradeHistoriesWithReferral?offset=${offset}&limit=${limit}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    if (res.status === 200) {
      return [res.data.data, res.data.meta.total];
    }
    return res;
  };

  const getReferralDataForTradeHistories = async (
    authToken,
    tradeHistories
  ) => {
    const res = await axios({
      method: 'post',
      url: `${gaiaApiUrl}/referrals/getReferralDataForTradeHistories`,
      headers: {
        'Content-Type': 'application/json',
        token: authToken,
      },
      data: {
        tradeHistories,
      },
    });
    if (res.status === 200) {
      return res.data.data;
    }
    return null;
  };

  const updateReferralTrackStatus = async (
    authToken,
    referralTrackId,
    referralTrackIsPaid
  ) => {
    const res = await axios({
      method: 'post',
      url: `${gaiaApiUrl}/referrals/updateReferralTrackStatus`,
      headers: {
        'Content-Type': 'application/json',
        token: authToken,
      },
      data: {
        referralTrackId,
        isPaid: referralTrackIsPaid,
      },
    });
    if (res.status === 200) {
      return res;
    }
    return { error: res.error };
  };

  const getUserWalletAddress = async authToken => {
    const res = await axios({
      method: 'get',
      url: `${gaiaApiUrl}/users/me/wallet_address`,
      headers: {
        'Content-Type': 'application/json',
        token: authToken,
      },
    });
    if (res.status === 200 && res.data?.user_wallet_address) {
      return res.data.user_wallet_address;
    }
    return null;
  };

  const getUserByWalletAddress = async walletAddress => {
    const res = await axios({
      method: 'get',
      url: `${gaiaApiUrl}/users/get_by_wallet_address/${walletAddress}`,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) {
      return res.data.user;
    }
    return null;
  };

  const checkIfWalletIsVerifiedByUser = async (authToken, walletAddress) => {
    const res = await axios({
      method: 'get',
      url: `${gaiaApiUrl}/users/me/check_if_wallet_is_verified/${walletAddress}`,
      headers: {
        'Content-Type': 'application/json',
        token: authToken,
      },
    });
    if (res.status === 200 && res.data?.is_verified) {
      return [res.data.is_verified, null];
    }
    return [null, res.error];
  };

  const getGaiaNft = async tokenId => {
    const res = await axios({
      method: 'get',
      url: `${gaiaApiUrl}/nfts/${tokenId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) {
      return res.data.nft;
    }
    return null;
  };

  const saveBurnTxHash = async (authToken, tokenID, txHash) => {
    const res = await axios({
      method: 'post',
      url: `${gaiaApiUrl}/nfts/saveBurnTxHash/${tokenID}`,
      headers: {
        'Content-Type': 'application/json',
        token: authToken,
      },
      data: { txHash },
    });
    return res;
  };

  const uploadIpfs = async (authToken, nftId) => {
    const res = await axios({
      method: 'post',
      url: `${gaiaApiUrl}/nfts/uploadIpfs`,
      headers: {
        'Content-Type': 'application/json',
        token: authToken,
      },
      data: { nftId },
    });
    if (res.status === 200) {
      return res.data.ipfsUri;
    }
    return null;
  };

  const getValidationString = async () => {
    const res = await axios({
      method: 'get',
      url: `${gaiaApiUrl}/auth/validation_string`,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data?.validation_string) {
      return null;
    }
    return res.data.validation_string;
  };

  const updateGeoData = async (authToken, nftId, geoPoint) => {
    const res = await axios({
      method: 'post',
      url: `${gaiaApiUrl}/nfts/updateGeoPosition`,
      headers: {
        'Content-Type': 'application/json',
        token: authToken,
      },
      data: { nftId, geoPoint },
    });
    if (res.status === 200) {
      return res.data?.geoPoint;
    }
    return { error: res.error };
  };

  const check2faCode = async (token, twoFACode) => {
    const res = await axios({
      method: 'post',
      url: `${gaiaApiUrl}/auth/check_2faCode`,
      headers: {
        'Content-Type': 'application/json',
        token,
      },
      data: { twoFACode },
    });

    if (res.status === 200 && res?.data?.token) {
      return res?.data?.token;
    }

    return { error: res.error };
  };

  const fetchQrcode = async token => {
    const res = await axios({
      method: 'get',
      url: `${gaiaApiUrl}/auth/fetch_qrcode`,
      headers: {
        'Content-Type': 'application/json',
        token,
      },
    });

    if (res.status === 200 && res?.data?.qrcode) {
      return res?.data?.qrcode;
    }

    return { error: res.error };
  };

  const donwloadCsvReport = async (authToken, startDate, endDate) => {
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/info/csvReport`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken.token}`,
      },
      data: {
        startDate,
        endDate,
      },
    });

    if (res.status === 200) {
      return res?.data;
    }

    return { error: res.error };
  };

  const subscribeByEmail = async email => {
    const res = await axios({
      method: 'post',
      url: `${apiUrl}/subscription/subscribeByEmail`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        email,
      },
    });

    if (res.status === 200) {
      return res?.data;
    }

    return { error: res.error || 'Failed to subscribe' };
  };

  return {
    explorerUrl,
    apiUrl,
    storageUrl,
    getNonce,
    getIsModerator,
    getAccountDetails,
    getUserAccountDetails,
    getUserFigures,
    updateAccountDetails,
    updateBanner,
    get1155Info,
    getTokenHolders,
    fetchCollections,
    fetchCollection,
    fetchPendingCollections,
    approveCollection,
    rejectCollection,
    fetchAllCollections,
    fetchTokens,
    getItemsLiked,
    getNftsAsAdmin,
    getReferrals,
    getBundleDetails,
    increaseBundleViewCount,
    fetchItemDetails,
    increaseViewCount,
    getBundleOffers,
    getBundleTradeHistory,
    getTransferHistory,
    getAccountActivity,
    getActivityFromOthers,
    getMyOffers,
    addMod,
    removeMod,
    banCollection,
    unbanCollection,
    banItems,
    banUser,
    unbanUser,
    checkBan,
    boostCollection,
    createBundle,
    deleteBundle,
    getFollowing,
    followUser,
    getFollowers,
    getFollowings,
    getBundleLikes,
    isLikingItem,
    isLikingBundle,
    likeItem,
    likeBundle,
    getItemLikeUsers,
    getBundleLikeUsers,
    getMyLikes,
    getNotificationSettings,
    updateNotificationSettings,
    addUnlockableContent,
    retrieveUnlockableContent,
    postLogin,
    postSignUp,
    resendVerificationEmail,
    postConfirmAccount,
    postResetPassword,
    postSetNewPassword,
    verifyWallet,
    sumsubToken,
    sumsubStatus,
    postUpdateUser,
    getUserIsKycVerified,
    approveNft,
    rejectNft,
    nftSatus,
    uploadFile,
    registerNft,
    deleteFile,
    fetchUserNft,
    fetchCreatedNft,
    nftSatusGaia,
    approveNftGaia,
    rejectNftGaia,
    getReferralCode,
    addReferralTrack,
    getReferralByCode,
    getCategories,
    saveTxHash,
    getTradeHistoriesWithReferral,
    getReferralDataForTradeHistories,
    updateReferralTrackStatus,
    getUserWalletAddress,
    getUserByWalletAddress,
    checkIfWalletIsVerifiedByUser,
    getGaiaNft,
    saveBurnTxHash,
    getValidationString,
    uploadIpfs,
    updateGeoData,
    fetchQrcode,
    check2faCode,
    donwloadCsvReport,
    getAuctionBidFromOthers,
    getMyBidsAuction,
    subscribeByEmail,
  };
};
