import React from 'react';

const IconReceivedOffers = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.744 2.63358L20.272 7.52679C20.538 7.69969 20.671 7.78615 20.7674 7.90146C20.8527 8.00354 20.9167 8.12162 20.9558 8.24877C21 8.39241 21 8.55104 21 8.8683V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H5.8C4.11984 21 3.27976 21 2.63803 20.673C2.07354 20.3854 1.6146 19.9265 1.32698 19.362C1 18.7202 1 17.8802 1 16.2V8.8683C1 8.55104 1 8.39241 1.04417 8.24877C1.08327 8.12162 1.14735 8.00354 1.23265 7.90146C1.32901 7.78615 1.46201 7.69969 1.72802 7.52679L9.25604 2.63358M12.744 2.63358C12.1127 2.22327 11.7971 2.01812 11.457 1.93829C11.1564 1.86774 10.8436 1.86774 10.543 1.93829C10.2029 2.01812 9.88728 2.22327 9.25604 2.63358M12.744 2.63358L18.9361 6.65849C19.624 7.10559 19.9679 7.32915 20.087 7.61264C20.1911 7.86039 20.1911 8.13961 20.087 8.38737C19.9679 8.67086 19.624 8.89441 18.9361 9.34152L12.744 13.3664C12.1127 13.7767 11.7971 13.9819 11.457 14.0617C11.1564 14.1323 10.8436 14.1323 10.543 14.0617C10.2029 13.9819 9.88728 13.7767 9.25604 13.3664L3.06386 9.34151C2.37601 8.89441 2.03209 8.67086 1.91297 8.38737C1.80888 8.13961 1.80888 7.86039 1.91297 7.61264C2.03209 7.32915 2.37601 7.1056 3.06386 6.65849L9.25604 2.63358M20.5 19L13.8572 13M8.14282 13L1.5 19"
      stroke={props.color ? props.color : '#121223'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconReceivedOffers;
