import { AuthConstants } from '../constants/auth.constants';

export function Auth(
  state = {
    fetching: false,
    user: {},
  },
  action
) {
  switch (action.type) {
    case AuthConstants.PROFILE_GET_START: {
      return {
        ...state,
        fetching: true,
      };
    }
    case AuthConstants.PROFILE_GET_SUCCESS: {
      return {
        ...state,
        fetching: false,
        user: action.payload,
      };
    }
    case AuthConstants.PROFILE_GET_FAILED: {
      return {
        ...state,
        fetching: false,
        user: {},
      };
    }
    case AuthConstants.SIGN_OUT: {
      return {
        ...state,
        user: {},
        authToken: null,
      };
    }
    case AuthConstants.SIGN_IN: {
      return {
        ...state,
        user: action.user,
        authToken: action.authToken,
      };
    }
    case AuthConstants.UPDATE_BANNER: {
      return {
        ...state,
        user: { ...state.user, banner: action.payload },
      };
    }

    case AuthConstants.SET_2FA: {
      return {
        ...state,
        user: { ...state.user, is_2fa: true },
        authToken: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
