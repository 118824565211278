import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { USER_ROLES } from '../constants/userRoles.constants';

function AdminOrAgentRoute({ component: Component, ...restOfProps }) {
  const { authToken, user } = useSelector(state => state.Auth);
  const location = useLocation();
  return (
    <Route
      {...restOfProps}
      render={props =>
        authToken ? (
          user.is_2fa ? (
            user?.role_id === USER_ROLES.ADMIN ||
            user?.role_id === USER_ROLES.ESCROW_AGENT ? (
              <Component {...props} />
            ) : (
              <Redirect to={`/`} />
            )
          ) : (
            <Redirect to={'/profile'} />
          )
        ) : (
          <Redirect to={`/auth/sign-in?redirect_to=${location.pathname}`} />
        )
      }
    />
  );
}

export default AdminOrAgentRoute;
