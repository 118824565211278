import ModalConstants from '../constants/modal.constants';

const ModalActions = {
  showAccountModal,
  hideAccountModal,
  showWFTMModal,
  hideWFTMModal,
  showConnectWalletModal,
  hideConnectWalletModal,
  showPhotoCarouselModal,
  hidePhotoCarouselModal,
  showCsvReportModal,
  hideCsvReportModal,
};

function showAccountModal() {
  return dispatch => {
    dispatch(_showAccountModal());
  };
}

const _showAccountModal = () => {
  return {
    type: ModalConstants.SHOW_ACCOUNT_MODAL,
  };
};

function hideAccountModal() {
  return dispatch => {
    dispatch(_hideAccountModal());
  };
}

const _hideAccountModal = () => {
  return {
    type: ModalConstants.HIDE_ACCOUNT_MODAL,
  };
};

function showWFTMModal() {
  return dispatch => {
    dispatch(_showWFTMModal());
  };
}

const _showWFTMModal = () => {
  return {
    type: ModalConstants.SHOW_WFTM_MODAL,
  };
};

function hideWFTMModal() {
  return dispatch => {
    dispatch(_hideWFTMModal());
  };
}

const _hideWFTMModal = () => {
  return {
    type: ModalConstants.HIDE_WFTM_MODAL,
  };
};

function showConnectWalletModal() {
  return dispatch => {
    dispatch(_showConnectWalletModal());
  };
}

const _showConnectWalletModal = () => {
  return {
    type: ModalConstants.SHOW_CONNECT_WALLET_MODAL,
  };
};

function hideConnectWalletModal() {
  return dispatch => {
    dispatch(_hideConnectWalletModal());
  };
}

const _hideConnectWalletModal = () => {
  return {
    type: ModalConstants.HIDE_CONNECT_WALLET_MODAL,
  };
};

function showPhotoCarouselModal() {
  return dispatch => {
    dispatch(_showPhotoCarouselModal());
  };
}

const _showPhotoCarouselModal = () => {
  return {
    type: ModalConstants.SHOW_PHOTO_CAROUSEL_MODAL,
  };
};

function hidePhotoCarouselModal() {
  return dispatch => {
    dispatch(_hidePhotoCarouselModal());
  };
}

const _hidePhotoCarouselModal = () => {
  return {
    type: ModalConstants.HIDE_PHOTO_CAROUSEL_MODAL,
  };
};

function showCsvReportModal() {
  return dispatch => {
    dispatch(_showCsvReportModal());
  };
}

const _showCsvReportModal = () => {
  return {
    type: ModalConstants.SHOW_CSV_REPORT,
  };
};

function hideCsvReportModal() {
  return dispatch => {
    dispatch(_hideCsvReportModal());
  };
}

const _hideCsvReportModal = () => {
  return {
    type: ModalConstants.HIDE_CSV_REPORT,
  };
};

export default ModalActions;
