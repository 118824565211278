import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

function NeedToBeLoggedRoute({ component: Component, ...restOfProps }) {
  const { authToken, user } = useSelector(state => state.Auth);
  const location = useLocation();
  return (
    <Route
      {...restOfProps}
      render={props =>
        authToken ? (
          user.is_2fa ? (
            <Component {...props} />
          ) : (
            <Redirect to={'/profile'} />
          )
        ) : (
          <Redirect to={`/auth/sign-in?redirect_to=${location.pathname}`} />
        )
      }
    />
  );
}

export default NeedToBeLoggedRoute;
