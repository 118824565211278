import React, { useState } from 'react';
import Modal from '../Modal';
import styles from './styles.module.scss';
import cx from 'classnames';
import showToast from '../../utils/toast';
import { ClipLoader } from 'react-spinners';

const ConfirmItemBurnModal = ({ visible, onConfirmClose, onCancelClose }) => {
  const [isBurningNft, setIsBurningNft] = useState(false);

  const handleConfirm = async () => {
    if (isBurningNft) return;
    setIsBurningNft(true);

    try {
      await onConfirmClose();
    } catch (error) {
      showToast('error', error?.message || 'Failed to burn NFT');
    } finally {
      setIsBurningNft(false);
    }
  };

  return (
    <Modal
      visible={visible}
      title="Share Link"
      onClose={onCancelClose}
      submitDisabled={true}
      showTitle={false}
      showCloseIcon={!isBurningNft}
    >
      <div className={styles.title}>
        You&apos;re about to claim this property and burn the NFT
      </div>
      <div className={styles.subtitle}>
        We&apos;re excited to offer you the opportunity to “burn” your NFTs and
        turn them back into tangible, physical collectibles.
        <br />
        This process, known as “burning,” is a unique feature of our platform,
        and we&apos;re glad you&apos;re taking advantage of it.
        <br />
        This process cannot be undone, so if you have questions about it, please
        check our&nbsp;
        <a
          href="https://docs.liquidearth.io/"
          className={cx(styles.linkUnderline, styles.docsLink)}
          rel="noopener noreferrer"
          target="_blank"
        >
          docs.
        </a>
        <br />
        With our easy-to-use interface and top-notch customer service, the
        process of redeeming your NFTs has never been easier. So why wait? Start
        burning your NFTs and reclaim your collectibles on LiquidEarth today!
      </div>
      <div className={styles.footer}>
        <button
          className={cx(styles.button, styles.confirmButton)}
          onClick={handleConfirm}
          disabled={isBurningNft}
        >
          {isBurningNft ? <ClipLoader color="#FFF" size={16} /> : 'Confirm'}
        </button>
        {!isBurningNft ? (
          <div className={cx(styles.button, styles.cancelButton)}>
            <a onClick={onCancelClose}>Cancel</a>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export default ConfirmItemBurnModal;
