import React from 'react';

const IconHeart = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.4 20.8L4.5 13.6C4 13.1 3.6 12.5 3.4 11.9C3.1 11.2 3 10.6 3 9.9C3 9.2 3.2 8.5 3.5 7.9C3.8 7.3 4.2 6.7 4.7 6.3C5.2 5.8 5.8 5.5 6.5 5.3C7.1 5 7.8 5 8.5 5C9.2 5.1 9.9 5.3 10.4 5.6C11.1 6 11.6 6.5 12 7C12.4 6.5 12.9 6 13.5 5.7C14.1 5.4 14.8 5.1 15.4 5.1C16.1 5 16.8 5.1 17.4 5.3C18.1 5.5 18.7 5.9 19.2 6.3C19.7 6.8 20.1 7.3 20.4 7.9C20.7 8.5 20.9 9.2 20.9 9.9C20.9 10.6 20.8 11.3 20.5 11.9C20.2 12.5 19.9 13.1 19.4 13.6L12.4 20.8Z"
      stroke={props.color ? props.color : '#121223'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconHeart;
