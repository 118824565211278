import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import ModalActions from 'actions/modal.actions';
import showToast from '../../utils/toast';
import { useApi } from '../../api';
import WalletUtils from '../../utils/wallet';
import { USER_ROLES } from '../../constants/userRoles.constants';

const TxButton = ({ onClick, children, ...rest }) => {
  const dispatch = useDispatch();
  const { chainId } = useWeb3React();
  const { user, authToken } = useSelector(state => state.Auth);
  const location = useLocation();
  const history = useHistory();
  const { getUserIsKycVerified } = useApi();

  const handleClick = async e => {
    if (!user || !authToken) {
      history.push(`/auth/sign-in?redirect_to=${location.pathname}`);
    } else if (
      user?.role_id === USER_ROLES.ADMIN ||
      user?.role_id === USER_ROLES.ESCROW_AGENT
    ) {
      showToast(
        'error',
        `This action is forbidden for ${
          user?.role_id === USER_ROLES.ADMIN ? 'Admin' : 'Agent'
        }`
      );
    } else if (!chainId) {
      dispatch(ModalActions.showConnectWalletModal());
    } else if (chainId !== Number(process.env.REACT_APP_NETWORK_CHAIN_ID)) {
      await WalletUtils.checkNetworkChain(chainId);
    } else {
      const userIsKycVerified = await getUserIsKycVerified(authToken);
      if (!userIsKycVerified) {
        showToast('error', "Profile is not KYC'd");
      } else {
        onClick(e);
      }
    }
  };

  return (
    <div {...rest} onClick={handleClick}>
      {children}
    </div>
  );
};

export default TxButton;
