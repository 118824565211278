import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

function TwoFARouter({ component: Component, ...restOfProps }) {
  const { authToken, user } = useSelector(state => state.Auth);
  return (
    <Route
      {...restOfProps}
      render={props =>
        authToken ? (
          user.is_2fa ? (
            <Component {...props} />
          ) : (
            <Redirect to={'/profile'} />
          )
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

export default TwoFARouter;
