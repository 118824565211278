import React, { useState } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { ArrowIcon } from '../icons';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  wrapper: {
    marginRight: '-1px !important',
    boxShadow: 'none',
    borderRadius: '0 !important',
    border: 'none',
    overflow: 'hidden',
    backgroundColor: '#F4F5F8',
    transition: 'background-color 0.3s',
  },
  wrapperExpanded: {
    backgroundColor: 'rgba(18, 18, 35, 0.04)',
  },
  header: {
    height: 50,
    minHeight: '50px !important',
    boxShadow: 'none',
    padding: '0 15.5px 0 35px',
  },
  heading: {
    fontWeight: 700,
    fontSize: 16,
    flexShrink: 0,
    color: '#121223',
    opacity: '0.6',
    transition: 'opacity 0.3s',
  },
  headingExpanded: {
    opacity: '1',
  },
  arrowIcon: {
    color: '#121223',
    opacity: '0.6',
    paddingTop: '5px',
  },
  arrowIconExpanded: {
    opacity: '1',
  },
  body: {
    padding: '12px 15.5px 12px 35px',
    border: 'none',
    boxSizing: 'border-box',
    opacity: '0',
    transition: 'opacity 0.3s',
  },
  bodyExpanded: {
    opacity: '1',
  },
  statusSvgDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: '13px',
  },
  filterIcon: {
    opacity: '0.6',
  },
  filterIconExpanded: {
    opacity: '1',
  },

  expandIcon: {
    '&$expanded': {
      transform: 'rotate(-90deg)',
    },
  },
  expanded: {},
}));

const FilterWrapper = ({
  title,
  icon,
  classes: classnames,
  children,
  isComingSoon = false,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleAccordionCollapse = (_, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <div className={cx(classes.root, classnames.root)}>
      <Accordion
        className={cx(classes.wrapper, expanded && classes.wrapperExpanded)}
        expanded={expanded}
        onChange={handleAccordionCollapse}
      >
        <AccordionSummary
          expandIcon={
            <ArrowIcon
              className={cx(
                classes.arrowIcon,
                expanded && classes.arrowIconExpanded
              )}
            />
          }
          className={classes.header}
          classes={{
            expandIcon: classes.expandIcon,
            expanded: classes.expanded,
          }}
        >
          <img
            src={icon}
            alt=""
            className={cx(
              classes.filterIcon,
              expanded && classes.filterIconExpanded
            )}
          />
          <div className={classes.statusSvgDiv}>
            <span
              className={cx(
                classes.heading,
                expanded && classes.headingExpanded
              )}
            >
              {title}
            </span>
            {isComingSoon ? (
              <span
                className={cx(
                  classes.heading,
                  expanded && classes.headingExpanded
                )}
              >
                (Coming Soon)
              </span>
            ) : null}
          </div>
        </AccordionSummary>
        <AccordionDetails
          className={cx(
            classes.body,
            classnames.body,
            expanded && classes.bodyExpanded
          )}
        >
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FilterWrapper;
