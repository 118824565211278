import { ethers } from 'ethers';
import { formatError } from 'utils';
import useContract from 'utils/sc.interaction';
// import WalletUtils from 'utils/wallet';
import showToast from 'utils/toast';
import { useApi } from 'api';
import { NFT_STATUSES_CODES } from 'constants/NFTStatuses.constants';
import useTokens from 'hooks/useTokens';
import { useNFTContract } from 'contracts';
const PREMINT_NFT_ABI = [
  {
    inputs: [
      { internalType: 'address', name: '_beneficiary', type: 'address' },
      { internalType: 'string', name: '_tokenUri', type: 'string' },
    ],
    name: 'preMint',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
];

export const usePreMint = () => {
  const { loadContract } = useContract();
  const { checkBan, saveTxHash, getUserWalletAddress, uploadIpfs } = useApi();
  const { tokens } = useTokens();
  const { getERC20Contract } = useNFTContract();

  const preMint = async ({ authToken, nftId }) => {
    const adminFee = process.env.REACT_APP_ADMIN_FEE;
    const walletAddress = await getUserWalletAddress(authToken);
    if (!walletAddress) {
      showToast('info', 'Connect your wallet first');
      return;
    }

    try {
      let isBanned = await checkBan(walletAddress, authToken);
      if (isBanned) {
        showToast('error', 'You are banned from minting');
        return;
      }

      const price = ethers.utils.parseUnits(adminFee, tokens[0].decimals);
      const erc20 = await getERC20Contract(tokens[0].address);
      const balance = await erc20.balanceOf(walletAddress);
      if (balance.lt(price)) {
        showToast(
          'warning',
          `Your balance should be at least ${adminFee} USDC to mint an NFT`
        );
        return;
      }
      const allowance = await erc20.allowance(
        walletAddress,
        process.env.REACT_APP_FANTOM_ARTION
      );
      if (allowance.lt(price)) {
        const tx = await erc20.approve(
          process.env.REACT_APP_FANTOM_ARTION,
          price
        );
        await tx.wait();
      }
    } catch (error) {
      showToast('error', error.message);
      return;
    }

    try {
      //0
      const tokenUri = await uploadIpfs(authToken, nftId);
      if (!tokenUri) {
        showToast('error', 'Problem with saving metadata.');
        return;
      }
      //1
      const jsonHash = `https://Liquid.mypinata.cloud/ipfs/${tokenUri}`;
      const nftContractAddress = process.env.REACT_APP_FANTOM_ARTION;
      const contract = await loadContract(nftContractAddress, PREMINT_NFT_ABI);
      const args = [walletAddress, jsonHash];
      let tx = await contract.preMint(...args);
      // // 2
      // const confirmedTnx = await tx.wait();
      // // 3
      // const evtCaught = confirmedTnx.logs[0].topics;
      // const preMintedTkId = BigNumber.from(evtCaught[2]);
      // const _royalty = parseInt(royalty) * 100;
      // const royaltyTx = await registerRoyalty(
      //   nftContractAddress,
      //   preMintedTkId.toNumber(),
      //   isNaN(_royalty) ? 0 : _royalty
      // );
      // await royaltyTx.wait();
      const txHash = tx?.hash;
      if (!txHash?.length) {
        showToast('error', 'Pre-mint error.');
        return;
      }
      let hashData = { txHash, tokenStatus: NFT_STATUSES_CODES.PREMINT };
      const resp = await saveTxHash(authToken, nftId, hashData);
      if (resp.error) {
        showToast('error', resp.error);
        return;
      }
    } catch (error) {
      console.log(error);
      showToast('error', formatError(error?.error || error));
      return;
    }
  };

  return { preMint };
};
