import React from 'react';

const IconWallet = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 6.99983V3.50048C14 2.66874 14 2.25287 13.8248 1.9973C13.6717 1.77401 13.4346 1.62232 13.1678 1.57691C12.8623 1.52493 12.4847 1.6992 11.7295 2.04775L2.85901 6.14182C2.18551 6.45267 1.84875 6.6081 1.60211 6.84915C1.38406 7.06225 1.21762 7.32238 1.1155 7.60966C1 7.93462 1 8.30551 1 9.04729V13.9998M14.5 13.4998H14.51M1 10.1998L1 16.7998C1 17.9199 1 18.48 1.21799 18.9078C1.40973 19.2841 1.71569 19.5901 2.09202 19.7818C2.51984 19.9998 3.07989 19.9998 4.2 19.9998H15.8C16.9201 19.9998 17.4802 19.9998 17.908 19.7818C18.2843 19.5901 18.5903 19.2841 18.782 18.9078C19 18.48 19 17.9199 19 16.7998V10.1998C19 9.07972 19 8.51967 18.782 8.09185C18.5903 7.71552 18.2843 7.40956 17.908 7.21782C17.4802 6.99983 16.9201 6.99983 15.8 6.99983L4.2 6.99983C3.0799 6.99983 2.51984 6.99983 2.09202 7.21781C1.7157 7.40956 1.40973 7.71552 1.21799 8.09185C1 8.51967 1 9.07972 1 10.1998ZM15 13.4998C15 13.776 14.7761 13.9998 14.5 13.9998C14.2239 13.9998 14 13.776 14 13.4998C14 13.2237 14.2239 12.9998 14.5 12.9998C14.7761 12.9998 15 13.2237 15 13.4998Z"
      stroke={props.color ? props.color : '#121223'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconWallet;
