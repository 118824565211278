import React, { useState, useRef, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import 'react-alice-carousel/lib/alice-carousel.css';
import AliceCarousel from 'react-alice-carousel';
import styles from './styles.module.scss';
import ModalActions from '../../actions/modal.actions';
import LazyLoader from 'components/LazyLoader';

const AlphaBackdrop = withStyles({
  root: {
    backgroundColor: 'rgba(35, 35, 52, 0.9)',
  },
})(Backdrop);

const CarouselModal = ({ items, startIndex }) => {
  const dispatch = useDispatch();
  const [sliderIndex, setSliderIndex] = useState(0);
  const modalCarouselRef = useRef(null);
  const { photoCarouselModalVisible } = useSelector(state => state.Modal);
  const [carouselImages, setCarouselImages] = useState([]);

  const handleCloseCarouselModal = () => {
    dispatch(ModalActions.hidePhotoCarouselModal());
  };

  useEffect(() => {
    const photos = (items || []).map(el => {
      return (
        <LazyLoader
          key={el.asset_id}
          url={el.url}
          rootElement={modalCarouselRef}
          imgStyle={styles}
        />
      );
    });
    setCarouselImages(photos);
  }, [items]);

  return (
    <Modal
      className={styles.modalPopup}
      aria-labelledby="photo-modal-carousel"
      open={photoCarouselModalVisible}
      onClose={handleCloseCarouselModal}
      BackdropComponent={AlphaBackdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={photoCarouselModalVisible}>
        <div className={styles.modalSliderContainer} id="photo-modal-carousel">
          <p className={styles.closeLabel} onClick={handleCloseCarouselModal}>
            Close
          </p>
          <div className={styles.subModalContainer}>
            <div
              className={cx(styles.sliderButtonWrapper, styles.leftButton)}
              onClick={() => modalCarouselRef.current.slidePrev()}
            >
              <i className={cx(styles.arrow, styles.arrowLeft)}></i>
            </div>
            <div className={styles.carouselContainer}>
              <AliceCarousel
                ref={modalCarouselRef}
                activeIndex={startIndex}
                items={carouselImages}
                disableDotsControls={true}
                disableButtonsControls={true}
                autoWidth={true}
                onSlideChanged={ev => setSliderIndex(ev.item)}
                onInitialized={ev => setSliderIndex(ev.item)}
              />
            </div>
            <div
              className={cx(styles.sliderButtonWrapper, styles.rightButton)}
              onClick={() => modalCarouselRef.current.slideNext()}
            >
              <i className={cx(styles.arrow, styles.arrowRight)}></i>
            </div>
          </div>
          <div className={styles.modalSliderCounter}>
            <p>{`${sliderIndex + 1} of ${carouselImages?.length}`}</p>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default CarouselModal;
