import React, { useState, useEffect, Suspense, useRef } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import Skeleton from 'react-loading-skeleton';
import ReactPlayer from 'react-player';
import editIcon from 'assets/svgs/edit.svg';
import pdfPreviewIcon from 'assets/svgs/prfPreviewIcon.svg';
import { ClipLoader } from 'react-spinners';
import useContract from 'utils/sc.interaction';
import { formatError, dateFormat } from 'utils';
import IconExclamation from 'assets/icons/iconExclamation';
import { AttachmentsIcon, RegistryIcon } from 'components/icons';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Popover, Select, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import 'react-alice-carousel/lib/alice-carousel.css';
import AliceCarousel from 'react-alice-carousel';
import CarouselModal from '../../components/CarouselModal';
import Panel from 'components/Panel';
import Identicon from 'components/Identicon';
import LazyLoader from '../../components/LazyLoader';
import showToast from 'utils/toast';
import { useApi } from 'api';
import Header from 'components/header';
import Footer from 'components/Footer';
import styles from './styles.module.scss';
import DenyNFTModal from '../../components/DenyNFTModal';
import SuspenseImg from 'components/SuspenseImg';
import defaultAvatar from 'assets/imgs/default_avatar.png';
import { USER_ROLES } from '../../constants/userRoles.constants';
import { usePreMint } from 'hooks/usePreMint';
import { useMaps } from 'hooks/useMaps';
import ModalActions from '../../actions/modal.actions';
import { useWeb3React } from '@web3-react/core';
import { isEmptyObject } from '../../utils/objects';
import { NFT_STATUSES_CODES } from '../../constants/NFTStatuses.constants';
import WalletUtils from '../../utils/wallet';
import { ArrowIcon } from 'components/icons/index';

const GraySelect = withStyles({
  select: {
    '&:focus': {
      backgroundColor: 'inherit',
    },
  },
  icon: {
    marginRight: '10px',
  },
})(Select);

const MINT_NFT_ABI = [
  {
    inputs: [{ internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
    name: 'mint',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

const blockToAdminStatus = [
  NFT_STATUSES_CODES.PREMINT,
  NFT_STATUSES_CODES.MINING_PENDING,
  NFT_STATUSES_CODES.PAYING_FEE_PENDING,
  NFT_STATUSES_CODES.APPROVE_MINTING_PENDING,
  NFT_STATUSES_CODES.BURNED,
  NFT_STATUSES_CODES.ERROR_MINTING_TX,
  NFT_STATUSES_CODES.MINTED,
];

const STATUS_ID_DEFAULT_VALUE = -1;

const APPROVE_INFO_MESSAGE =
  'Thank you for approving this item. If you change your approval status, these buttons are shown to edit the status.';
const DENY_INFO_MESSAGE =
  'Thank you for denying this item. If you change your denial status, these buttons are shown to edit the status.';
const BEFORE_MINT_INFO_MESSAGE = (
  <>
    Congratulations!🎊🎉🎈
    <br />
    Your ownership of the asset has been approved by our admin team. Please mint
    your NFT by approving the transaction below. This transaction is only paying
    for the cost of gas. For questions, please check our&nbsp;
    <a
      href="https://docs.liquidearth.io/"
      className={cx(styles.linkUnderline, styles.docsLink)}
      rel="noopener noreferrer"
      target="_blank"
    >
      docs.
    </a>
  </>
);

const GaiaNFTItem = () => {
  const { account, chainId } = useWeb3React();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [waitingGaiaResp, setWaitingGaiaResp] = useState(false);
  const [createdNft, setCreatedNft] = useState({});
  const { authToken } = useSelector(state => state.Auth);
  const { addr: address, id: tokenID } = useParams();
  const history = useHistory();
  const {
    fetchCreatedNft,
    nftSatusGaia,
    approveNftGaia,
    getCategories,
    checkBan,
    saveTxHash,
    getUserWalletAddress,
    fetchItemDetails,
  } = useApi();
  const { preMint } = usePreMint();

  const [nftStatus, setNftStatus] = useState(null);
  const [userNftStatus, setUserNftStatus] = useState('');
  const [denyReason, setDenyReason] = useState(null);
  const { user } = useSelector(state => state.Auth);
  const [denyNftModalVisible, setDenyNftModalVisible] = useState(false);
  const [statusId, setStatusId] = useState(STATUS_ID_DEFAULT_VALUE);
  const [collections, setCollections] = useState([]);
  const { loadContract } = useContract();
  const [uid, setUid] = useState(null);
  const [userIsNftOwner, setUserIsNftOwner] = useState(null);
  const [infoMessage, setInfoMessage] = useState(null);
  const [displayDescription, setDisplayDescription] = useState(false);
  const [isOverflowDescription, setIsOverflowDescription] = useState(false);
  const descriptioRef = useRef(null);

  const [openEscrowFeePopover, setOpenEscrowFeePopover] = useState(false);
  const [escrowFeePeriod, setEscrowFeePeriod] = useState(12);
  const [escrowAmount, setEscrowAmount] = useState();
  const [inputEscrowPeriod, setInputEscrowPeriod] = useState(12);
  const [inputEscrowAmount, setInputEscrowAmount] = useState();

  const { markerPosition, isLoaded } = useMaps({
    geoPosition: createdNft.geo_position,
    nftAdress: createdNft.name,
    nftUserId: createdNft.user_id,
    nftId: createdNft.nft_id,
  });
  const { photoCarouselModalVisible } = useSelector(state => state.Modal);
  const [carouselImages, setCarouselImages] = useState([]);
  const carouselRef = useRef(null);
  const carouselTargetInex = useRef(0);
  const carouselIndex = useRef(0);

  const asyncNft = async () => {
    setLoading(true);
    const { nft, error, status } = await fetchCreatedNft(authToken, tokenID);
    setLoading(false);
    if (error) {
      if (status === 403) {
        setUserIsNftOwner(false);
      } else if (status === 404) {
        history.replace('/404');
      } else {
        showToast('error', `${status} ${error}`);
      }
    } else {
      setCreatedNft(nft);
      setEscrowFeePeriod(nft?.escrow_fee_period || 0);
      setEscrowAmount(nft?.escrow_fee || 0);
    }
  };
  useEffect(() => {
    dispatch(ModalActions.hidePhotoCarouselModal());
    asyncNft();
    getUserWalletAddress(authToken).then(
      userWalletAddress => {
        if (userWalletAddress) {
          setUid(userWalletAddress);
        }
      },
      () => {}
    );
  }, [authToken, tokenID]);

  useEffect(() => {
    if (user && createdNft && !isEmptyObject(createdNft)) {
      if (createdNft.user_id === user.user_id) {
        setUserIsNftOwner(true);
      } else {
        setUserIsNftOwner(false);
      }
    }
  }, [createdNft, user]);

  useEffect(() => {
    const getCollections = async () => {
      try {
        const data = await getCategories();
        setCollections(data);
      } catch (err) {
        console.log('Error when getting categories: ', err);
      }
    };
    getCollections();

    const checkNftStatus = setInterval(() => {
      try {
        getNftStatus();
      } catch (e) {
        console.log("Couldn't get NFT status: ", e);
      }
    }, 1000 * 3);
    return () => clearInterval(checkNftStatus);
  }, [authToken, tokenID]);

  useEffect(() => {
    if (
      descriptioRef.current?.offsetHeight < descriptioRef.current?.scrollHeight
    ) {
      setIsOverflowDescription(true);
    } else {
      setIsOverflowDescription(false);
    }
  }, [descriptioRef.current]);

  const isManager =
    user?.role_id === USER_ROLES.ADMIN ||
    user?.role_id === USER_ROLES.ESCROW_AGENT;

  const isAgent = user?.role_id === USER_ROLES.ESCROW_AGENT;

  const getNftStatus = async () => {
    const request = await nftSatusGaia(authToken, tokenID);
    if (request.error) {
      showToast('error', `${request.status} ${request.error}`);
    } else {
      const parsedPresponse = JSON.parse(request?.request?.response);
      const data = parsedPresponse?.data;
      const newStatusId = data?.nftStatusId;
      if (newStatusId === NFT_STATUSES_CODES.MINTED) {
        const { nft, error, status } = await fetchCreatedNft(
          authToken,
          tokenID
        );
        if (error) {
          if (status === 403) {
            setUserIsNftOwner(false);
          } else if (status === 404) {
            history.replace('/404');
          } else {
            showToast('error', `${status} ${error}`);
          }
        } else {
          try {
            const splittedNftLink = nft?.nft_link?.split('/');
            const nftContractAddress = splittedNftLink[0];
            const nftBlockchainTokenId = splittedNftLink[1];
            const artionNftItemDetails = await fetchItemDetails(
              nftContractAddress,
              nftBlockchainTokenId
            );
            if (!artionNftItemDetails?.data?.uri) {
              console.log('Waiting for NFT to be processed by server');
              throw new Error('Waiting for NFT to be processed by server');
            } else {
              history.push(`/explore/${nft.nft_link}`);
            }
          } catch (error) {
            console.log(
              "Couldn't get nft item details. Perhaps it was not processed by server yet"
            );
          }
        }
      }
      if (
        !isManager &&
        newStatusId === NFT_STATUSES_CODES.APPROVED_BY_AGENT_AND_ADMIN
      ) {
        setInfoMessage(BEFORE_MINT_INFO_MESSAGE);
      } else if (!isManager) {
        setInfoMessage(null);
      }
      setStatusId(newStatusId);
      setNftStatus(data?.nftStatus);
      setUserNftStatus(data?.userNftStatus);
      setDenyReason(data?.denyReason);
      setEscrowAmount(data?.escrowFee || 0);
      setEscrowFeePeriod(data?.escrowFeePeriod || 0);
    }
  };

  useEffect(() => {
    if (!denyNftModalVisible) {
      getNftStatus();
    }
  }, [denyNftModalVisible]);

  const handleRejectNft = async () => {
    setWaitingGaiaResp(true);
    setDenyNftModalVisible(true);
  };

  const handleApproveNft = async (escrowFee, period) => {
    setWaitingGaiaResp(true);
    const response = await approveNftGaia(
      authToken,
      tokenID,
      escrowFee,
      period
    );
    setWaitingGaiaResp(false);
    setOpenEscrowFeePopover(false);
    if (response?.error) {
      showToast('error', response?.error);
    } else {
      await getNftStatus();
      showToast('success', 'NFT status changed!');
      setInfoMessage(APPROVE_INFO_MESSAGE);
    }
  };

  const handleActionNft = () => {
    setInputEscrowAmount(escrowAmount);
    setInputEscrowPeriod(escrowFeePeriod);
    setOpenEscrowFeePopover(true);
  };

  const mintNFTHandler = async () => {
    if (!account || !chainId) {
      showToast('error', 'Please connect your wallet');
      dispatch(ModalActions.showConnectWalletModal());
      return;
    }
    if (chainId !== Number(process.env.REACT_APP_NETWORK_CHAIN_ID)) {
      await WalletUtils.checkNetworkChain(chainId);
      return;
    }
    try {
      setWaitingGaiaResp(true);
      const walletAddress = user.user_wallets[0]?.address;
      let isBanned = await checkBan(walletAddress, authToken);
      if (isBanned) {
        showToast('error', 'You are banned from minting');
        return;
      }
      const nftContractAddress = process.env.REACT_APP_FANTOM_ARTION;
      const contract = await loadContract(nftContractAddress, MINT_NFT_ABI);
      try {
        const args = [createdNft.artionTokenId];
        const { hash: txHash } = await contract.mint(...args);
        if (!txHash?.length) {
          showToast('error', 'Mint error.');
          return;
        }
        let hashData = {
          txHash,
          tokenStatus: NFT_STATUSES_CODES.APPROVED_BY_AGENT_AND_ADMIN,
        };
        const resp = await saveTxHash(authToken, createdNft.nft_id, hashData);
        if (resp.error) {
          showToast('error', resp.error);
          return;
        }
        showToast('success', `The NFT pending minting!`);
      } catch (error) {
        showToast('error', formatError(error));
      }
      await getNftStatus();
    } catch (error) {
      showToast('error', error.message);
    } finally {
      setWaitingGaiaResp(false);
    }
  };

  const payFeeNFTHandler = async () => {
    try {
      if (!account || !chainId) {
        showToast('error', 'Please connect your wallet');
        dispatch(ModalActions.showConnectWalletModal());
        return;
      }
      if (chainId !== Number(process.env.REACT_APP_NETWORK_CHAIN_ID)) {
        await WalletUtils.checkNetworkChain(chainId);
        return;
      }
      setWaitingGaiaResp(true);
      await preMint({ authToken, nftId: createdNft.nft_id });
    } catch (error) {
      showToast('error', error.message);
    } finally {
      setWaitingGaiaResp(false);
    }
  };

  const renderMedia = (image, contentType) => {
    if (contentType === 'video' || image?.includes('youtube')) {
      return (
        <ReactPlayer
          className={styles.content}
          url={image}
          controls={true}
          width="100%"
          height="100%"
        />
      );
    } else if (contentType === 'embed') {
      return <iframe className={styles.content} src={image} />;
    } else if (contentType === 'image' || contentType === 'gif') {
      return (
        <Suspense
          fallback={
            <Loader
              type="Oval"
              color="#007BFF"
              height={32}
              width={32}
              className={styles.loader}
            />
          }
        >
          {!!statusId && (
            <div className={cx(styles.status, styles[`status${statusId}`])}>
              <p>{userNftStatus}</p>
            </div>
          )}
          <SuspenseImg className={styles.content} src={image} />
        </Suspense>
      );
    }
  };

  useEffect(() => {
    const handleOpenCarouselModal = idx => {
      return () => {
        carouselIndex.current = idx;
        dispatch(ModalActions.showPhotoCarouselModal());
      };
    };

    const items = (createdNft?.photos || []).map((el, idx) => {
      return (
        <LazyLoader
          key={el.asset_id}
          onClickCb={handleOpenCarouselModal(idx)}
          url={el.url}
          rootElement={carouselRef}
          imgStyle={styles}
        />
      );
    });
    setCarouselImages(items);
  }, [createdNft?.photos]);

  const renderSmallSlider = () => {
    return (
      <div className={styles.sliderContainer}>
        <div
          className={cx(styles.sliderButtonWrapper, styles.leftButton)}
          onClick={() => {
            if (carouselTargetInex.current > 0) {
              carouselTargetInex.current--;
              carouselRef.current.slidePrev();
            }
          }}
        >
          <i className={cx(styles.arrow, styles.arrowLeft)}></i>
        </div>
        <div className={styles.aliceCarouselContainer}>
          <AliceCarousel
            ref={carouselRef}
            items={carouselImages}
            disableDotsControls
            disableButtonsControls
            autoWidth
          />
        </div>
        <div
          className={cx(styles.sliderButtonWrapper, styles.rightButton)}
          onClick={() => {
            if (carouselTargetInex.current < carouselImages.length - 4) {
              carouselTargetInex.current++;
              carouselRef.current.slideNext();
            }
          }}
        >
          <i className={cx(styles.arrow, styles.arrowRight)}></i>
        </div>
      </div>
    );
  };

  let startDate = new Date(createdNft?.escrow_fee_start_date);
  const endDate =
    createdNft?.escrow_fee_start_date &&
    new Date(startDate.setMonth(startDate.getMonth() + escrowFeePeriod));

  const renderItemInfo = () => (
    <>
      {isManager && (
        <div
          className={styles.itemMenu}
          onClick={() => {
            history.push(`/create/${tokenID}`);
          }}
        >
          <div className={styles.itemMenuBtn}>
            <img src={editIcon} className={styles.itemMenuIcon} />
          </div>
        </div>
      )}
      <div className={cx(styles.itemCategory, styles.linkLabel)}>
        {collections?.find(el => el._id === createdNft?.collection_id)?.label ||
          createdNft?.collection_id ||
          ''}
      </div>
      <div className={styles.itemName}>{createdNft?.name || ''}</div>
      {createdNft?.description && (
        <>
          <div
            ref={descriptioRef}
            className={cx(
              styles.itemDescription,
              displayDescription && styles.displayDescription
            )}
          >
            {createdNft.description}
          </div>
          {isOverflowDescription && (
            <div
              className={cx(styles.descriptionShow)}
              onClick={() => setDisplayDescription(prev => !prev)}
            >
              {displayDescription ? 'Hide' : 'Show more'}
            </div>
          )}
        </>
      )}
      <div className={styles.itemStats}>
        {loading ? (
          <Skeleton width={150} height={20} />
        ) : (
          <>
            {createdNft?.user?.avatar ? (
              <img
                src={`${createdNft?.user?.avatar}`}
                className={styles.ownerAvatar}
              />
            ) : uid ? (
              <Identicon
                className={styles.ownerAvatar}
                account={uid}
                size={24}
              />
            ) : (
              <div className={styles.ownerAvatar}>
                <img
                  src={defaultAvatar}
                  alt={'avatar'}
                  className={styles.avatar}
                />
              </div>
            )}
            {uid ? (
              <>
                Owned by&nbsp;
                <Link to={`/account/${uid}`} className={styles.ownerName}>
                  {createdNft?.user?.name}
                </Link>
              </>
            ) : null}
          </>
        )}
        {userNftStatus ? (
          <div className={cx(styles.itemViews, styles.userNftStatus)}>
            <span>{userNftStatus}</span>
          </div>
        ) : null}
      </div>
    </>
  );
  const renderAttachments = () => {
    return createdNft?.assetAttachments?.map(attachment => {
      return (
        <div className={styles.attachmentLabel} key={attachment.asset_id}>
          <a
            href={attachment.url}
            className={styles.documentLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              src={pdfPreviewIcon}
              alt={attachment.name}
              className={styles.documentIcon}
            />
          </a>
          {attachment.name}
        </div>
      );
    });
  };
  const renderFiles = files => {
    return files?.map(file => {
      return (
        <a
          href={file.url}
          key={file.asset_id}
          className={styles.documentLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src={pdfPreviewIcon}
            alt={file.name}
            className={styles.documentIcon}
          />
        </a>
      );
    });
  };

  const shouldShowAdminButtons = () => {
    return (
      isManager &&
      !blockToAdminStatus.includes(statusId) &&
      statusId !== STATUS_ID_DEFAULT_VALUE
    );
  };

  const shouldShowApproveButtons = () => {
    return (
      user?.role_id === USER_ROLES.ESCROW_AGENT &&
      !blockToAdminStatus.includes(statusId) &&
      statusId !== STATUS_ID_DEFAULT_VALUE
    );
  };

  const shouldShowInfoMessage = () => {
    return (
      shouldShowAdminButtons() ||
      (!isManager &&
        statusId === NFT_STATUSES_CODES.APPROVED_BY_AGENT_AND_ADMIN)
    );
  };

  return (
    <div
      className={cx(
        styles.container,
        photoCarouselModalVisible && styles.modalBlur
      )}
    >
      <Header border />
      <div className={styles.inner}>
        <div className={styles.topContainer}>
          {userIsNftOwner === null ? (
            <div className={styles.itemSummary}>
              <div className={styles.itemMedia}>
                <div className={styles.media}>
                  <Loader
                    type="Oval"
                    color="#7F56D9"
                    height={32}
                    width={32}
                    className={styles.loader}
                  />
                </div>
              </div>
            </div>
          ) : !isManager && !userIsNftOwner ? (
            <div className={styles.forbiddenContainer}>
              <div className={styles.forbiddenMessage}>
                You can&apos;t view this page since you are not the owner of
                this NFT
              </div>
              <IconExclamation className={styles.forbiddenIcon} />
            </div>
          ) : (
            <>
              <div className={styles.itemSummary}>
                <div className={styles.itemMedia}>
                  <div className={styles.media}>
                    {loading ? (
                      <Loader
                        type="Oval"
                        color="#007BFF"
                        height={32}
                        width={32}
                        className={styles.loader}
                      />
                    ) : (
                      renderMedia(createdNft?.imageURL, 'image')
                    )}
                  </div>
                </div>
                {carouselImages.length > 0 && renderSmallSlider()}
                {isLoaded ? (
                  <GoogleMap
                    center={markerPosition}
                    zoom={10}
                    mapContainerClassName={styles.mapContainer}
                  >
                    {markerPosition && <Marker position={markerPosition} />}
                  </GoogleMap>
                ) : (
                  <ClipLoader color="#FFF" size={16} />
                )}
              </div>
              <div className={styles.itemMain}>
                <div className={styles.itemInfoWrapper}>{renderItemInfo()}</div>
                <div className={cx(styles.panelWrapper, styles.adminWrapper)}>
                  {nftStatus || userNftStatus ? (
                    <h3 className={styles.statusLabel}>
                      Current status&#58; {nftStatus || userNftStatus}
                    </h3>
                  ) : (
                    <div className={styles.loadingIndicator}>
                      <ClipLoader color="#007BFF" size={25} />
                    </div>
                  )}
                  <div className={styles.pendingApprovalWaiting}>
                    {!isManager ? (
                      statusId === NFT_STATUSES_CODES.PENDING_APPROVAL ? (
                        <>
                          Your NFT is currently being reviewed for accuracy by
                          the LiquidEarth Team. If approved, you will be
                          prompted to send an escrow fee to cover the time
                          period you’d like to escrow your property as an NFT.
                          If rejected, you must resubmit the information for
                          further review.{' '}
                          <a
                            href="https://docs.liquidearth.io/en/articles/6696443-how-to-mint-a-liquidearth-nft"
                            className={styles.linkUnderline}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Please refer to the LiquidEarth documentation here.
                          </a>
                        </>
                      ) : statusId === NFT_STATUSES_CODES.PREMINT ? (
                        <>
                          Admin Fees at LiquidEarth are used to prevent fraud
                          and ensure legal ownership of the asset. The current
                          admin fee for onboarding assets is $10. For more
                          information please see our{' '}
                          <a
                            href="https://docs.liquidearth.io/"
                            className={styles.linkUnderline}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            pricing
                          </a>
                          .
                        </>
                      ) : statusId === NFT_STATUSES_CODES.MINTED ? (
                        <>
                          NFT is currently being processed by server. Please
                          wait.
                        </>
                      ) : null
                    ) : null}
                  </div>
                  {denyReason ? (
                    <span className={styles.statusDenyReason}>
                      {denyReason}
                    </span>
                  ) : null}
                  {infoMessage && shouldShowInfoMessage() ? (
                    <div
                      className={cx(
                        styles.infoMessage,
                        denyReason ? styles.infoMessageUnderDenyMessage : null
                      )}
                    >
                      {infoMessage}
                    </div>
                  ) : null}
                  {!!createdNft?.escrow_fee && (
                    <>
                      <div className={styles.escrowStatus}>
                        {`Escrow Fee: $${escrowAmount}`}
                      </div>
                      <div className={styles.escrowStatus}>
                        {`Escrow Period: ${escrowFeePeriod} months`}
                      </div>
                    </>
                  )}
                  <div className={styles.adminButtons}>
                    {shouldShowAdminButtons() ? (
                      waitingGaiaResp ? (
                        <div className={styles.loadingIndicator}>
                          <ClipLoader color="#007BFF" size={25} />
                        </div>
                      ) : shouldShowApproveButtons() ? (
                        <div className={styles.buttonsContainer}>
                          <div
                            className={cx(styles.deny, styles.admin_buttons)}
                            onClick={async () => await handleRejectNft()}
                          >
                            Deny
                          </div>
                          <div
                            className={cx(styles.approve, styles.admin_buttons)}
                            onClick={handleActionNft}
                          >
                            Set Escrow Fee
                          </div>
                        </div>
                      ) : (
                        <div>
                          {statusId === NFT_STATUSES_CODES.APPROVED_BY_AGENT &&
                            `Please, approve NFT status in Gnosis Safe. Use the Token ID: ${createdNft?.artionTokenId}`}
                          {!isAgent &&
                            statusId === NFT_STATUSES_CODES.PREMINT &&
                            'Agent should approve NFT first'}
                        </div>
                      )
                    ) : (
                      !isManager &&
                      (waitingGaiaResp ? (
                        <div className={styles.loadingIndicator}>
                          <ClipLoader color="#007BFF" size={25} />
                        </div>
                      ) : (
                        <>
                          {statusId ===
                            NFT_STATUSES_CODES.APPROVED_BY_AGENT_AND_ADMIN && (
                            <div
                              className={cx(
                                styles.approve,
                                styles.admin_buttons
                              )}
                              onClick={async () => await mintNFTHandler()}
                            >
                              Mint
                            </div>
                          )}
                          {statusId === NFT_STATUSES_CODES.PREMINT && (
                            <div
                              className={cx(
                                styles.approve,
                                styles.admin_buttons
                              )}
                              onClick={async () => await payFeeNFTHandler()}
                            >
                              Pay fee
                            </div>
                          )}
                        </>
                      ))
                    )}
                  </div>
                </div>
                <div className={styles.panelWrapper}>
                  <Panel title="Registry" icon={RegistryIcon} responsive>
                    <div className={styles.panelBody}>
                      {loading ? (
                        <div className={styles.loadingIndicator}>
                          <ClipLoader color="#007BFF" size={16} />
                        </div>
                      ) : (
                        <div className={styles.itemsList}>
                          <div className={styles.documentContainer}>
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className={styles.registryLabel}>
                                      Link to video
                                    </div>
                                    <div
                                      className={cx(
                                        styles.registryValue,
                                        styles.linkLabel
                                      )}
                                    >
                                      <CopyToClipboard
                                        text={createdNft?.linkToVideo}
                                        onCopy={() => {
                                          showToast(
                                            'success',
                                            'Copied a video link to the clipboard'
                                          );
                                        }}
                                      >
                                        <p className={styles.videoLink}>
                                          copy link
                                        </p>
                                      </CopyToClipboard>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className={styles.registryLabel}>
                                      Annual property taxes
                                    </div>
                                    <div className={styles.registryValue}>
                                      ${createdNft?.annualPropertyTaxes}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className={styles.registryLabel}>
                                      Monthly HOA
                                    </div>
                                    <div className={styles.registryValue}>
                                      ${createdNft?.monthlyHoa}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className={styles.registryLabel}>
                                      Prepaid Title insurance
                                    </div>
                                    <div className={styles.registryValue}>
                                      {createdNft?.prepaidTitleInsurance
                                        ? 'YES'
                                        : 'NO'}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className={styles.registryLabel}>
                                      Monthly property insurance
                                    </div>
                                    <div className={styles.registryValue}>
                                      ${createdNft?.monthlyPropertyInsurance}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className={styles.registryLabel}>
                                      Special assessment
                                    </div>
                                    <div className={styles.registryValue}>
                                      {createdNft?.specialAssessment
                                        ? 'YES'
                                        : 'NO'}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className={styles.registryLabel}>
                                      Parcel Number or Property Record Number
                                    </div>
                                    <div className={styles.registryValue}>
                                      {createdNft?.parcelNumber}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className={styles.documentContainer}>
                            <div className={styles.documentPaddings}>
                              <div className={styles.registryLabel}>
                                Escrow Fee
                              </div>
                              <div className={styles.registryValue}>
                                $ {escrowAmount}
                              </div>
                            </div>
                            <div className={styles.documentPaddings}>
                              <div className={styles.registryLabel}>Period</div>
                              <div className={styles.registryValue}>
                                {escrowFeePeriod} months
                              </div>
                            </div>
                            <div className={styles.documentPaddings}>
                              <div className={styles.registryLabel}>
                                Remain Balance
                              </div>
                              <div className={styles.registryValue}>
                                $ {Number(createdNft.remain_balance).toFixed(2)}
                              </div>
                            </div>
                            <div className={styles.documentPaddings}>
                              <div className={styles.registryLabel}>
                                Start date
                              </div>
                              <div className={styles.registryValue}>
                                {dateFormat(createdNft?.escrow_fee_start_date)}
                              </div>
                            </div>
                            <div className={styles.documentPaddings}>
                              <div className={styles.registryLabel}>
                                End date
                              </div>
                              <div className={styles.registryValue}>
                                {dateFormat(endDate)}
                              </div>
                            </div>
                            <div className={styles.documentPaddings}>
                              <div className={styles.registryLabel}>
                                Inspection reports
                              </div>
                              <div>
                                {renderFiles(createdNft?.inspectionReports)}
                              </div>
                            </div>
                            <div className={styles.documentPaddings}>
                              <div className={styles.registryLabel}>
                                Property disclosure report
                              </div>
                              <div>
                                {renderFiles(createdNft?.propertyReport)}
                              </div>
                            </div>
                            <div className={styles.documentPaddings}>
                              <div className={styles.registryLabel}>
                                Deed and other documents
                              </div>
                              <div>
                                {renderFiles(createdNft?.deedDocuments)}
                              </div>
                            </div>
                            <div className={styles.documentPaddings}>
                              <div className={styles.registryLabel}>
                                Trust or escrow agreement
                              </div>
                              <div>
                                {renderFiles(createdNft?.escrowAgreement)}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </Panel>
                </div>
                <div className={styles.panelWrapper}>
                  <Panel title="Attachments" icon={AttachmentsIcon} responsive>
                    <div className={styles.panelBody}>
                      {loading ? (
                        <div className={styles.loadingIndicator}>
                          <ClipLoader color="#007BFF" size={16} />
                        </div>
                      ) : (
                        <div className={styles.itemsList}>
                          {renderAttachments()}
                        </div>
                      )}
                    </div>
                  </Panel>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <DenyNFTModal
        visible={denyNftModalVisible}
        onClose={() => {
          setDenyNftModalVisible(false);
          setWaitingGaiaResp(false);
        }}
        address={address}
        tokenID={tokenID}
        onDeny={() => {
          setInfoMessage(DENY_INFO_MESSAGE);
        }}
      />
      <Footer />
      <CarouselModal
        items={createdNft?.photos}
        startIndex={carouselIndex.current}
      />
      <Popover
        anchorReference="anchorPosition"
        anchorPosition={{
          top: window.innerHeight / 2,
          left: window.innerWidth / 2,
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        open={openEscrowFeePopover}
      >
        <div className={styles.escrowFee}>
          <p className={styles.escrowFeeLabel}>Escrow Fee (in $USD)</p>
          <div className={styles.escrowAmount}>
            <input
              className={styles.escrowAmountInput}
              placeholder="Amount"
              onChange={e => setInputEscrowAmount(e.target.value)}
              value={inputEscrowAmount}
              type="number"
              min="0"
              max="99999999999999"
            />
          </div>
          <GraySelect
            className={styles.escrowFeePeriod}
            disableUnderline
            IconComponent={ArrowIcon}
            onChange={e => setInputEscrowPeriod(e.target.value)}
            value={inputEscrowPeriod}
          >
            <MenuItem value="3">3 months</MenuItem>
            <MenuItem value="6">6 months</MenuItem>
            <MenuItem value="9">9 months</MenuItem>
            <MenuItem value="12">12 months</MenuItem>
          </GraySelect>
          <div className={styles.escrowButtons}>
            <div
              className={cx(styles.cancelEscrow, styles.buttons)}
              onClick={() => setOpenEscrowFeePopover(false)}
            >
              Cancel
            </div>
            <div
              className={cx(styles.approveEscrow, styles.buttons)}
              onClick={() =>
                handleApproveNft(inputEscrowAmount, inputEscrowPeriod)
              }
            >
              Approve
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default GaiaNFTItem;
