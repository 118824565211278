import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import showToast from 'utils/toast';
import { useApi } from 'api';
import tickerIcon from '../../assets/ticker.svg';
import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import style from './styles.module.scss';
import { useSelector } from 'react-redux';
import cx from 'classnames';

export const PurpleSwitch = withStyles({
  root: {
    width: 32,
    height: 20,
    padding: 0,
  },
  switchBase: {
    padding: 2,
    opacity: 0.5,
    color: '#121223',
    '&$checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      opacity: 1,
      '& + $track': {
        backgroundColor: '#7F56D9',
        opacity: 1,
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
  },
  track: {
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: '#F4F5F8',
  },
  checked: {},
})(Switch);

const AdminReferralSystemItem = ({
  date,
  amount,
  ticker = tickerIcon,
  status,
  icon,
  nftLink,
  referralTrackId,
  referralUser,
  buyer,
}) => {
  const { authToken } = useSelector(state => state.Auth);
  const [statusChecked, setStatusChecked] = useState(status === 'Paid');
  const [statusLabel, setStatusLabel] = useState(status);
  const { updateReferralTrackStatus } = useApi();

  return (
    <div className={style.AdminReferralSystemItem}>
      <div className={style.head}>
        <div className={style.title}>{referralUser}</div>
      </div>
      <div className={cx(style.body_item, style.buyer)}>
        <div className={style.label}>Buyer</div>
        <div className={style.date}>{buyer}</div>
      </div>
      <div className={cx(style.body_item, style.dateOfCreation)}>
        <div className={style.label}>Date of creation</div>
        <div className={style.date}>{date}</div>
      </div>
      <div className={cx(style.body_item, style.amount)}>
        <div className={style.label}>Amount</div>
        <div className={style.price2}>
          <img src={ticker} alt="" />
          {amount}
        </div>
      </div>
      <div className={style.body_item}>
        <div className={style.label}>Status</div>
        <div
          className={cx(
            style.status,
            statusLabel === 'Paid' ? style.paid : style.pending
          )}
        >
          <PurpleSwitch
            name="referralIsPaid"
            onChange={async () => {
              const {
                request: response,
                error,
              } = await updateReferralTrackStatus(
                authToken,
                referralTrackId,
                !statusChecked
              );
              if (error) {
                showToast('error', `Couldn't update referral status: ${error}`);
              } else if (response) {
                if (statusLabel === 'Paid') {
                  setStatusLabel('Pending');
                } else {
                  setStatusLabel('Paid');
                }
                setStatusChecked(!statusChecked);
                showToast(
                  'success',
                  'Referral status was successfully updated'
                );
              } else {
                showToast(
                  'error',
                  `Couldn't update referral status because of inner error`
                );
              }
            }}
            checked={statusChecked}
          />
          <span className={style.statusLabel}>{statusLabel}</span>
        </div>
      </div>
      <Link to={`/explore/${nftLink}`} className={style.nftLink}>
        <div className={style.footer}>
          <div className={style.img_container}>
            <img src={icon} alt="" />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default AdminReferralSystemItem;
