import React, { useState, useRef } from 'react';
import { useIntersection } from 'hooks/useIntersection';
// import styles from './styles.module.scss';

const LazyLoader = ({ url, rootElement, onClickCb, imgStyle }) => {
  const [isInView, setIsInView] = useState(false);
  const elementRef = useRef();

  useIntersection(
    elementRef,
    () => {
      setIsInView(true);
    },
    rootElement
  );

  return (
    <div ref={elementRef} className={imgStyle.carouselContainer}>
      {isInView && (
        <img src={url} className={imgStyle.imgStyle} onClick={onClickCb} />
      )}
    </div>
  );
};

export default LazyLoader;
