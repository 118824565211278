import MapsConstant from '../constants/maps.constants';

const initialState = {
  isLoaded: false,
};

export function Maps(state = initialState, action) {
  switch (action.type) {
    case MapsConstant.SET_MAPS_LOAD_STATE:
      return {
        ...state,
        isLoaded: action.isLoaded,
      };
    default: {
      return state;
    }
  }
}
