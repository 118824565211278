import React, { useState, useEffect, useRef } from 'react';
import Header from 'components/header';
import styles from './../styles.module.scss';
import cx from 'classnames';
import { ClipLoader } from 'react-spinners';
import { useHistory } from 'react-router';
import { useApi } from 'api';
import closeIcon from 'assets/svgs/v2/close.svg';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import toast from 'utils/toast';
import Background from '../../../components/Background';
import { NavLink } from 'react-router-dom';
import Footer from '../../../components/Footer';
import IconCloseEye from '../../../assets/svgs/eye_close_icon.svg';
import IconEye from '../../../assets/svgs/eye_icon.svg';
import { EMAIL_REGEX } from '../../../constants/regex.constants';
import { MIN_PASSWORD_LENGTH } from '../../../constants/auth.constants';

const SignUp = ({ isRegister }) => {
  const { postSignUp } = useApi();

  const [login, setLogin] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const passwordRef = useRef(null);
  const [loginError, setLoginError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [signingIn, setSigningIn] = useState(false);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(undefined);

  const onClose = () => {
    window.scrollTo(0, 0);
    history.push('/');
  };

  const validateLogin = login => {
    if (login.length === 0) {
      setLoginError("Email field can't be blank");
    } else if (!EMAIL_REGEX.test(login)) {
      setLoginError('Please enter a valid email');
    } else {
      setLoginError(null);
    }
  };

  useEffect(() => {
    validateLogin(login);
  }, [login]);

  const validateName = name => {
    if (name.length === 0) {
      setNameError("Name field can't be blank");
    } else {
      setNameError(null);
    }
  };

  useEffect(() => {
    validateName(name);
  }, [name]);

  const validatePassword = password => {
    if (password.length === 0) {
      setPasswordError("Password field can't be blank");
    } else if (password.length < MIN_PASSWORD_LENGTH) {
      setPasswordError(
        `Password should have ${MIN_PASSWORD_LENGTH} characters at least`
      );
    } else {
      setPasswordError(null);
    }
  };

  useEffect(() => {
    validatePassword(password);
  }, [password]);

  const isValid =
    !!login &&
    !loginError &&
    !!name &&
    !nameError &&
    !!password &&
    !passwordError;

  const handleSignUp = async () => {
    if (signingIn) return;

    try {
      const response = await postSignUp(name, login, password);
      if (response?.error) {
        toast('error', response.error);
      } else {
        toast('success', 'The verification link has been sent to your email.');
        window.scrollTo(0, 0);
        history.push('/');
      }
    } catch (e) {
      if (e?.response) {
        toast('error', e?.response?.data?.error);
      }
    }

    setSigningIn(false);
  };

  const handleSignIn = () => {
    window.scrollTo(0, 0);
    history.push('/auth/sign-in');
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (showPassword !== undefined) {
      passwordRef.current.focus();
      passwordRef.current.selectionStart = passwordRef.current.value.length;
      passwordRef.current.selectionEnd = passwordRef.current.value.length;
    }
  }, [showPassword]);

  const handleMouseDownPassword = () => {
    event.preventDefault();
  };

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 0);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isRegister) {
      window.scrollTo(0, 0);
      history.push('/');
    }
    setLogin('');
    setNameError('');
    setPassword('');
    setLoginError(null);
    setNameError(null);
    setPasswordError(null);
  }, [isRegister]);

  return (
    <div className={styles.outerContainer}>
      <div className={styles.authContainer}>
        <Background />
        <Header border />
        <div className={cx(styles.inner, styles.inner600)}>
          <form className={styles.authForm}>
            <div className={styles.closeButton} onClick={onClose}>
              <img src={closeIcon} />
            </div>
            <div className={styles.title}>Sign Up</div>
            <div className={styles.inputGroup}>
              <div className={styles.inputWrapper}>
                <OutlinedInput
                  className={cx(styles.input, nameError && styles.hasError)}
                  placeholder="Name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
                {nameError && <div className={styles.error}>{nameError}</div>}
              </div>
            </div>
            <div className={styles.inputGroup}>
              <div className={styles.inputWrapper}>
                <OutlinedInput
                  className={cx(styles.input, loginError && styles.hasError)}
                  placeholder="Email"
                  value={login}
                  onChange={e => setLogin(e.target.value)}
                />
                {loginError && <div className={styles.error}>{loginError}</div>}
              </div>
            </div>
            <div className={styles.inputGroup}>
              <div className={styles.inputWrapper}>
                <OutlinedInput
                  className={cx(
                    styles.input,
                    styles.password,
                    passwordError && styles.hasError
                  )}
                  placeholder="Password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  inputRef={passwordRef}
                  inputProps={{
                    autoComplete: 'new-password',
                    form: {
                      autoComplete: 'off',
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <img src={IconCloseEye} alt={'Hide password'} />
                        ) : (
                          <img src={IconEye} alt={'Show password'} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {passwordError && (
                  <div className={styles.error}>{passwordError}</div>
                )}
              </div>
            </div>
            <div
              className={cx(styles.formLabelRowCenterredGray, styles.indented)}
            >
              In clicking Create Account you are agreeing to the{' '}
              <NavLink
                to="/terms-of-service"
                className={styles.termsOfServiceLink}
              >
                <span
                  className={styles.labelLink}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  Terms of Service
                </span>
              </NavLink>
            </div>
            <div className={styles.buttonsWrapper}>
              <div
                className={cx(styles.submitButton, !isValid && styles.disabled)}
                onClick={isValid ? handleSignUp : null}
              >
                {signingIn ? (
                  <ClipLoader color="#FFF" size={16} />
                ) : (
                  'Create Account'
                )}
              </div>
              <div className={styles.formLabelRow}>
                <div className={styles.label}>Already have an account?</div>
                <div className={styles.labelLink} onClick={handleSignIn}>
                  Log In
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default SignUp;
